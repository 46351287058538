/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import sequencePartialFragment from "./sequencePartialFragment";

export default gql`
  fragment sequenceFragment on sequence {
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    ...sequencePartialFragment
    plasmidInductionMethodPlasmids {
      id
      inductionMethod {
        id
        name
      }
    }
    referenceCdsFragmentId
    molecularWeight
    sequenceFragments {
      id
      cid
      index
      fragment
    }
  }
  ${sequencePartialFragment}
`;
