/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import sanitizeFilename from "sanitize-filename";
import streamSaver from "streamsaver";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";

streamSaver.mitm = "streamsaver/mitm.html";

export default function saveStreamResponse({ url, body, filename }) {
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getRequestHeaderKeys()
    },
    body: body && JSON.stringify(body)
  })
    .then(res => {
      if (res.status === 500) {
        window.toastr.error("Error getting stream response. Please retry.");
        return;
      }

      const finalFilename = `${sanitizeFilename(filename)}.zip`;
      const fileStream = streamSaver.createWriteStream(finalFilename);

      // more optimized
      if (res.body.pipeTo) {
        // like as we never did fileStream.getWriter()
        return res.body.pipeTo(fileStream);
      }

      const writer = fileStream.getWriter();
      const reader = res.body.getReader();
      const pump = () =>
        reader.read().then(({ value, done }) => {
          return done
            ? // close the stream so we stop writing
              writer.close()
            : // Write one chunk, then get the next one
              writer.write(value).then(pump);
        });

      // Start the reader
      pump();
    })
    .then(() => window.toastr.success("File downloaded"));
}
