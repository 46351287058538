/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isRangeWithinRange } from "@teselagen/range-utils";
import { updateEditor } from "@teselagen/ove";
import { sequenceToVeInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";

export const getEnclosingFeatures = (feature, sequence, options = {}) => {
  const { isRegionAnnotation, isPart } = options;
  const featureRange = {
    start: feature.start,
    end: feature.end
  };
  const features = isRegionAnnotation
    ? sequence.regionAnnotations
    : sequence.sequenceFeatures;
  return features.filter(_feature => {
    return (
      (!isPart ? _feature.id !== feature.id : true) &&
      isRangeWithinRange(
        featureRange,
        {
          start: _feature.start,
          end: _feature.end
        },
        sequence.size
      )
    );
  });
};

export const updateSequenceEditor = (veInputSequence, editorOpts = {}) => {
  if (!veInputSequence) return;
  if (!window.teGlobalStore) {
    console.error("No global redux store found");
  }
  updateEditor(
    window.teGlobalStore,
    editorOpts.editorName || "SequenceEditor",
    {
      sequenceData: veInputSequence,
      annotationVisibility: {
        cutsites: false,
        chromatogram: true,
        reverseSequence: !veInputSequence.isOligo
      },
      panelsShown: [
        [
          ...(veInputSequence.isProtein || !veInputSequence.circular
            ? []
            : [
                {
                  id: "circular",
                  name: "Plasmid",
                  active: !!veInputSequence.circular
                }
              ]),
          {
            id: "rail",
            name: "Linear Map",
            active: !veInputSequence.circular
          }
        ],
        [
          {
            id: "sequence",
            name: "Sequence Map",
            active: true
          },
          {
            id: "properties",
            name: "Properties"
          }
        ]
      ],
      ...editorOpts
    }
  );
};

export const updateSequenceFromProps = (sequences, options) => {
  const veInputSequence = sequenceToVeInput(
    sequences && sequences.length && sequences[0]
  );
  updateSequenceEditor(veInputSequence, options);
};

export const importSequenceFromBuildToDesign = async sequenceIds => {
  const sequenceTexts = [];
  // TODO: maybe there's a way we can include the CLI header
  // in the request interceptors. But getting it is an async function
  // so no sure what's the best way yet. Also it comes with an expiration date
  // so generating it here ensures its always valid.
  // NOTE: The problem though, is that this will invalidate any API Key the user
  // might have generated in Settings > API Key, forcing him to generate a new one.
  // which might not be so bad but stil..

  // Maybe we can retrieve when the app starts and store it in local storage
  // but idk.
  for (const sequenceId of sequenceIds) {
    const exportResponse = await window.tgApi({
      method: "GET",
      url: `/export/sequence/genbank/${sequenceId}`,
      responseType: "blob"
    });
    sequenceTexts.push(await exportResponse.data.text());
  }

  const newSequences = [];
  const dupSequences = [];

  for (const seq of sequenceTexts) {
    const importResponse = await window.tgApi({
      method: "POST",
      url: `/import/sequence`,
      data: {
        allowDuplicates: false,
        sequences: [seq],
        treatFastaAsCircular: true,
        treatAllSeqsAsLinear: false
      }
    });
    const {
      data: { createdSequences = [], duplicateSequences = [] }
    } = importResponse;
    if (createdSequences.length) {
      newSequences.push(createdSequences[0]);
    } else if (duplicateSequences.length) {
      dupSequences.push({ ...duplicateSequences[0], original: seq });
    }
  }

  return { newSequences, dupSequences };
};
