/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import path from "path";
import dirname from "es-dirname";

const __dirname = dirname();

// These shouldnt be needed by the server.
// But the initAppDatabase logic is embeded in the server files.
const dbConfigs = {
  datamodelCSVPath: "../datamodel-csv-export-lims",
  resolversPath: "../graphql-lims/resolvers",
  schemaPath: "../graphql-lims/schema",
  mockResolversPath: "../graphql-lims/mock-resolvers",
  dataLibPath: "../data-lib-lims",
  extendTableMap: "./extendTableMapLims",
  gqlSchemaPath: "../schema-lims.json"
};

let exampleDesignTasks = ",xlsx-to-csv,callback";

if (
  !process.env.FE_SHOW_EXAMPLE_MICROSERVICE_TASK &&
  !process.env.CI_DOCKER_COMPOSE
) {
  exampleDesignTasks = "";
} else {
  console.info(
    `[MS-EX] Adding example microservice tasks to design: ${exampleDesignTasks}`
  );
}

const instanceConfig = {
  //CLIENT PATH
  clientPath: "../../../client/",
  devPort: 4001,
  frontEndConfigPath: path.resolve(__dirname, "frontEndConfig.json"),
  enabledTaskServicesForQueueManager:
    "xlsx-to-csv,callback,j5,crickit-manager,vendor-scoring-and-pricing,vendor-vector,standard-alignment,next-gen-alignment,blast,partition-tool,ds-tools,ds-tools-gpu,homology-path",
  ...dbConfigs
};

export { instanceConfig };
