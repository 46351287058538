/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import sequenceFragment from "../../../../tg-iso-shared/src/fragments/sequenceFragment";
import strainRecordFragment, {
  growthConditionFragment,
  strainSelectionMethodFragment
} from "../../../../tg-iso-shared/src/fragments/strainRecordFragment";

const materialBasicInfoForRecordView = gql`
  fragment materialBasicInfoForRecordView on material {
    id
    name
    externalAvailability
    user {
      id
      username
    }
    materialTypeCode
    materialType {
      code
      description
      name
    }
    microbialMaterialDerivedStrainLine {
      id
      name
    }
    createdAt
    updatedAt
    vendorCatalogNumber
    lab {
      id
      name
    }
  }
`;

export default gql`
  fragment materialRecordFragment on material {
    ...materialBasicInfoForRecordView
    genome {
      id
      name
      genomeGenomicRegions {
        id
        genomicRegion {
          ...sequenceFragment
        }
      }
    }
    biosafetyLevelOverwrite {
      code
      name
    }
    targetOrganismClassOverwrite {
      id
      name
    }
    strainId
    strain {
      ...strainRecordFragment
    }
    microbialMaterialDerivedStrainLine {
      id
      name
    }
    polynucleotideMaterialSequence {
      id
      sequenceTypeCode
    }
    growthConditionOverwrite {
      ...growthConditionFragment
    }
    childrenMaterials {
      id
      childMaterial {
        id
        name
        materialTypeCode
      }
    }
    materialLineages {
      id
      parentMaterial {
        id
        name
        materialTypeCode
      }
    }
    cellCultureCellCulturePlasmids {
      id
      cellCultureId
      copyNumber
      polynucleotideMaterial {
        id
        name
        polynucleotideMaterialSequence {
          ...sequenceFragment
        }
      }
    }
    microbialMaterialMicrobialMaterialPlasmids {
      id
      microbialMaterialId
      polynucleotideMaterialId
      copyNumber
      polynucleotideMaterial {
        id
        name
        polynucleotideMaterialSequence {
          ...sequenceFragment
        }
      }
    }
    materialCompositions {
      id
    }
    microbialMaterialMicrobialMaterialSelectionMethods {
      id
      selectionMethod {
        ...strainSelectionMethodFragment
      }
    }
    microbialMaterialMicrobialMaterialInductionMethods {
      id
      inductionMethod {
        id
        name
      }
    }
    cellCultureCellCultureSelectionMethods {
      id
      selectionMethod {
        ...strainSelectionMethodFragment
      }
    }
    cellCultureCellCultureInductionMethods {
      id
      inductionMethod {
        id
        name
      }
    }
    polynucleotideMaterialSequence {
      ...sequenceFragment
      sequenceFpus {
        id
        functionalProteinUnit {
          id
          name
        }
      }
      sequenceCodingSequences {
        id
        codingDnaSequence {
          id
          name
          size
          aminoAcidSequence {
            id
            name
          }
        }
      }
    }
  }
  ${strainRecordFragment}
  ${sequenceFragment}
  ${materialBasicInfoForRecordView}
  ${growthConditionFragment}
  ${strainSelectionMethodFragment}
`;
