/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get } from "lodash";
import { Link } from "react-router-dom";
import React from "react";

export default function getIsLinkedCellRenderer(path, hashPath) {
  return {
    [path]: (value, record = {}) => {
      const hash = get(record, hashPath, "");
      if (!hash) {
        console.error(
          `There should be a hash but none was found at ${hashPath}: `,
          record
        );
      }
      if (value) {
        return <Link to={"/materials/" + value}>View Material</Link>;
      } else {
        return "Unlinked";
      }
    }
  };
}
