/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import GenericSelect from "../../../../src-shared/GenericSelect";
import SortableListField from "../../SortableListField";
import DisplayField from "../../DisplayField";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import { v4 as uuid } from "uuid";

const CreateProteinMaterialDialog = props => {
  // On mount we add to the form the uniqueId field, used to uniquely identify each amino acid sequence since there can be duplicates
  useEffect(() => {
    const { aminoAcidSequences, change } = props;

    if (aminoAcidSequences) {
      change("aminoAcidSequences", [
        ...aminoAcidSequences.map(aa => ({ ...aa, uniqueId: uuid() }))
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUpdate = () => {
    const { initialValues = {} } = props;
    return !!initialValues.id;
  };
  const onSubmit = async values => {
    const { refetch, hideModal, history, cb, isProteinMaterial } = props;
    try {
      const {
        id,
        name,
        proteinSubUnits: initialProteinSubUnits = [],
        aminoAcidSequences = []
      } = values;
      let route;
      if (isProteinMaterial) {
        route = "protein-materials";
      } else {
        route = "functional-protein-units";
      }
      const proteinSubUnits = [];
      let fpuMolecularWeight = 0,
        fpuExtinctionCoefficient = 0;
      aminoAcidSequences.forEach(aa => {
        const { molecularWeight, extinctionCoefficient } = aa;
        fpuMolecularWeight += molecularWeight || 0;
        fpuExtinctionCoefficient += extinctionCoefficient || 0;
        proteinSubUnits.push({
          index: proteinSubUnits.length,
          aminoAcidSequenceId: aa.id
        });
      });

      const functionalProteinUnit = {
        name,
        molecularWeight: fpuMolecularWeight,
        extinctionCoefficient: fpuExtinctionCoefficient,
        proteinSubUnits
      };
      if (isUpdate()) {
        functionalProteinUnit.id = id;
        const newProteinSubUnits = functionalProteinUnit.proteinSubUnits.map(
          s => ({
            ...s,
            functionalProteinUnitId: id
          })
        );
        delete functionalProteinUnit.proteinSubUnits;
        if (initialProteinSubUnits) {
          await safeDelete(
            "proteinSubUnit",
            initialProteinSubUnits.map(s => s.id)
          );
        }
        await safeUpsert("functionalProteinUnit", functionalProteinUnit);
        await safeUpsert("proteinSubUnit", newProteinSubUnits);
        await refetch();
        hideModal();
      } else {
        const [fpu] = await safeUpsert(
          "functionalProteinUnit",
          functionalProteinUnit
        );
        const proteinMaterial = {
          name,
          materialTypeCode: "PROTEIN",
          functionalProteinUnitId: fpu.id
        };
        const [material] = await safeUpsert("material", proteinMaterial);
        await refetch();
        hideModal();
        cb
          ? cb(material)
          : history.push(
              `/${route}/${isProteinMaterial ? material.id : fpu.id}`
            );
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating protein material");
    }
  };
  const {
    hideModal,
    submitting,
    handleSubmit,
    change,
    aminoAcidSequences = []
  } = props;

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
        <div style={{ display: "flex", alignItems: "center" }}>
          <GenericSelect
            {...{
              name: "aminoSequenceToAdd",
              asReactSelect: true,
              label: "Add Amino Acid Sequence",
              schema: [
                {
                  path: "name"
                }
              ],
              onFieldSubmit: val => {
                if (val) {
                  change("aminoAcidSequences", [
                    ...aminoAcidSequences,
                    { ...val, uniqueId: uuid() }
                  ]);
                }
              },
              fragment: [
                "aminoAcidSequence",
                "id name molecularWeight extinctionCoefficient"
              ]
            }}
          />
        </div>
        {!!aminoAcidSequences.length && <h6>Order of Amino Acid Sequences:</h6>}
        <SortableListField
          name="aminoAcidSequences"
          ListItemComp={ListItemComp}
          uniqueKey="uniqueId"
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

function ListItemComp({ field, index }) {
  return (
    <>
      <div style={{ marginLeft: 15 }}>{index + 1}.</div>
      <h6 style={{ marginBottom: 0, marginLeft: 5, flex: 1 }}>
        <DisplayField name={`${field}.name`} />
      </h6>
    </>
  );
}

export default compose(
  wrapDialog({
    title: "Create Protein Material"
  }),
  reduxForm({
    form: "createProteinDialog"
  }),
  tgFormValues("aminoAcidSequences")
)(CreateProteinMaterialDialog);
