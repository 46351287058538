/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { difference, isEmpty, flatMap, reduce } from "lodash";
import { cleanTagsValue, getTaggedItemModelKey } from "./utils";
import { isoContext } from "@teselagen/utils";
import queryBuilder from "tg-client-query-builder";

/**
 * Tags items with the tags provided
 */
export default async function createTaggedItems(
  {
    recordIdsToTagIds, //if this is passed in, we will use it to populate the selectedTags and the recordIds
    selectedTags = {},
    records: _records,
    recordIds: _recordIds,
    model: _model,
    initiallySelectedTagIds = []
  },
  ctx = isoContext
) {
  const { safeUpsert, deleteWithQuery } = ctx;
  selectedTags = cleanTagsValue(selectedTags);
  const tagIds = Object.keys(selectedTags);
  const removedTagIds = difference(initiallySelectedTagIds, tagIds);
  const recordIds =
    _recordIds ||
    (recordIdsToTagIds && Object.keys(recordIdsToTagIds)) ||
    _records.map(r => r.id);
  if (
    !recordIds.length ||
    (isEmpty(selectedTags) &&
      !removedTagIds.length &&
      isEmpty(recordIdsToTagIds))
  )
    return;
  const model = _model || _records[0].__typename;
  const taggedItemModelKey = getTaggedItemModelKey(model);
  const tagIdsToRemoveForRecordId = {};
  const taggedItems = flatMap(recordIds, recordId => {
    return reduce(
      (recordIdsToTagIds && recordIdsToTagIds[recordId]) || selectedTags,
      (acc, _tagOptionId, tagId) => {
        tagIdsToRemoveForRecordId[recordId] =
          tagIdsToRemoveForRecordId[recordId] || [];
        tagIdsToRemoveForRecordId[recordId].push(tagId);
        const tagOptionId =
          typeof _tagOptionId === "string" ? _tagOptionId : null;
        acc.push({
          tagId,
          [model + "Id"]: recordId,
          tagOptionId
        });
        return acc;
      },
      []
    );
  });

  if (removedTagIds.length || initiallySelectedTagIds.length) {
    await deleteWithQuery("taggedItem", {
      tagId: removedTagIds.concat(initiallySelectedTagIds),
      [taggedItemModelKey]: recordIds
    });
  }
  // just remove the old tags so we have a clean slate and no duplicates
  if (Object.keys(tagIdsToRemoveForRecordId).length) {
    // because we sometimes allow different tags for different records
    // we must have a complex filter to remove them for each
    const orFilters = [];
    Object.keys(tagIdsToRemoveForRecordId).forEach(recordId => {
      const tagIds = tagIdsToRemoveForRecordId[recordId];
      orFilters.push({
        [taggedItemModelKey]: recordId,
        tagId: tagIds
      });
    });
    const qb = new queryBuilder("taggedItem");
    // where any doesn't work. don't know why
    orFilters.forEach(orFilter => {
      qb.orWhereAll(orFilter);
    });
    const filter = qb.toJSON();
    await deleteWithQuery("taggedItem", filter);
  }

  await safeUpsert("taggedItem", taggedItems, {
    excludeResults: true
  });

  if (removedTagIds.length || taggedItems.length) {
    return true;
  }
}
