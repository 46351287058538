/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// import { designPartFragment } from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import handleInsertPart from "./handleInsertPart";
// import handleInsertPart from "./handleInsertPart";

export async function createPartsFromSeqsAndInsertThemIntoDesign({
  sequences,
  cardId,
  selectedCellPaths,
  createElements
}) {
  try {
    // first get existing parts on the sequence to avoid creating duplicates
    const seqsWithParts = await safeQuery(
      ["sequence", "id size name parts {id start end strand name}"],
      {
        variables: {
          filter: {
            id: sequences.map(seq => seq.id)
          }, // Returns an Array.
          pageSize: sequences.length
        }
      }
    );
    const allParts = [];
    const partUpserts = [];
    seqsWithParts.forEach(seq => {
      const parts = seq.parts;
      const end = (seq.size || 1) - 1;
      const found = parts.some(part => {
        if (
          part.start === 0 &&
          part.end === end &&
          part.strand === 1 &&
          part.name === seq.name
        ) {
          allParts.push(part);
          return true;
        }
        return false;
      });
      if (!found) {
        allParts.push(undefined); //push a placeholder for later
        partUpserts.push({
          name: seq.name,
          start: 0,
          end,
          sequenceId: seq.id,
          strand: 1
        });
      }
    });

    const newParts = await safeUpsert("part", partUpserts);
    const parts = allParts.map(p => {
      if (p) {
        return p;
      } else {
        return newParts.shift();
      }
    });
    handleInsertPart({
      createElements,
      cardId,
      selectedCellPaths,
      parts
    });
  } catch (e) {
    console.error(e);
    window.toastr.error("Error inserting sequence(s).");
  }
}
