/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { SESSION_VARIABLE_NAMES } from "./HasuraSessionVars.js";

const LAB_ROLE_MODEL = "labRole";
const LAB_MODEL = "lab";
const USER_LOGIN_MODEL = "userLogin";

const USER_PERMISSION = {
  userId: {
    _eq: SESSION_VARIABLE_NAMES.USER_ID
  }
};

const LAB_PERMISSION = {
  _or: [
    {
      labId: {
        _in: SESSION_VARIABLE_NAMES.LAB_IDS
      }
    },
    // NOTE: Currently we are allowing access to records in "Common" Lab
    // or those with labId=null.
    {
      labId: {
        _is_null: true
      }
    }
  ]
};

const PERMISSION_FILTERS = {
  LAB_MODEL,
  LAB_ROLE_MODEL,
  LAB_PERMISSION,
  USER_LOGIN_MODEL,
  USER_PERMISSION
};

export { PERMISSION_FILTERS };
