/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, groupBy, uniq, pick, flatMap, identity } from "lodash";
import shortid from "shortid";
import { isBrowser } from "browser-or-node";
import { addHashPropToSeqs, stripAssemblyGaps } from "./utils";
import { isoContext } from "@teselagen/utils";
import { adjustRangeToRotation } from "@teselagen/range-utils";
import upsertUniqueAliases from "./upsertUniqueAliases";
import addRecordsToActiveProject from "../utils/addRecordsToActiveProject";
import {
  getSequencesFromDesign,
  getSequencesFromOldMutation
} from "../utils/getSequencesFromDesign";
import queryBuilder from "tg-client-query-builder";
import gql from "graphql-tag";
import { getSequence } from "../utils/getSequence";

function getCircularMatchIndex(seq1, seq2) {
  const sequenceString1 = typeof seq1 === "string" ? seq1 : getSequence(seq1);
  if (typeof sequenceString1 !== "string") {
    throw new Error(
      `Could not find sequence string for sequence: ${JSON.stringify(seq1)}`
    );
  }

  // We need to double the sequence string of the sequence we are comparing against,
  // because if seq1 is just a rotated version of seq2, we need seq2 to repeat itself
  // because the 'indexOf' function needs to find the whole match in a "linear fashion"
  let sequenceString2 = typeof seq2 === "string" ? seq2 : getSequence(seq2);
  sequenceString2 += sequenceString2;

  if (typeof sequenceString2 !== "string") {
    throw new Error(
      `Could not find sequence string for sequence: ${JSON.stringify(seq2)}`
    );
  }

  const matchIndex = sequenceString2
    .toLowerCase()
    .indexOf(sequenceString1.toLowerCase());

  return matchIndex;
}

async function updateSequenceFeaturesAndParts(
  listOfSequences,
  { doNotCreateParts } = {},
  ctx = isoContext
) {
  const { safeUpsert } = ctx;

  const featuresToCreate = [];
  const featureMaybeUpdates = [];

  const partsToCreate = [];

  const alreadyCheckedFeatures = {};

  const compareFeaturesAndParts = (dbSeq, dupSeq) => {
    if (alreadyCheckedFeatures[dbSeq.id]) return;
    alreadyCheckedFeatures[dbSeq.id] = true;
    if (dupSeq.sequenceFeatures) {
      const groupedDbSeqFeatures = groupBy(
        dbSeq.sequenceFeatures,
        getFeatureKey
      );
      dupSeq.sequenceFeatures.forEach(f => {
        // if (f.type === "CDS") return; //tnr: why??
        let featureToCompare = f;
        if (dupSeq.rotatedBy) {
          featureToCompare = adjustRangeToRotation(
            featureToCompare,
            // make negative
            -dupSeq.rotatedBy,
            dupSeq.size
          );
        }
        const dbFeatures =
          groupedDbSeqFeatures[getFeatureKey(featureToCompare)];
        if (!dbFeatures) {
          featuresToCreate.push({
            ...featureToCompare,
            sequenceId: dbSeq.id
          });
        } else {
          const hasMatch = dbFeatures.some(dbFeat => {
            return (
              dbFeat.start === featureToCompare.start ||
              dbFeat.end === featureToCompare.end
            );
          });
          if (!hasMatch) {
            featureMaybeUpdates.push({
              id: dbFeatures[0].id,
              ...featureToCompare
            });
          }
        }
      });
    }

    if (dupSeq.parts) {
      const groupedDbSeqParts = groupBy(dbSeq.parts || [], getPartKey);
      dupSeq.parts.forEach(p => {
        let partToCompare = p;
        if (dupSeq.rotatedBy) {
          partToCompare = adjustRangeToRotation(
            partToCompare,
            // make negative
            -dupSeq.rotatedBy,
            dupSeq.size
          );
        }

        const dbPart = groupedDbSeqParts[getPartKey(partToCompare)];
        if (!dbPart) {
          partsToCreate.push({
            ...partToCompare,
            sequenceId: dbSeq.id
          });
        }
      });
    }
  };

  // this will add new features to existing sequences
  listOfSequences.forEach(sequence => {
    const dup = sequence.duplicateFound;
    if (dup && dup.id) {
      compareFeaturesAndParts(dup, sequence);
    }
  });

  if (!doNotCreateParts) {
    await safeUpsert("part", partsToCreate, {
      excludeResults: true
    });
  }
  return {
    updates: featureMaybeUpdates,
    creates: featuresToCreate
  };
}

const getNameAndId = seq => ({ id: seq.id || seq.hash, name: seq.name });

const getSequences = json => {
  let sequences = [];

  // input json design
  if (Array.isArray(json)) {
    if (Object.keys(pick(json[0], ["entity", "inputs"])).length === 2) {
      sequences = getSequencesFromOldMutation(json);
    } else {
      sequences = json;
    }
  } else if (json.design || json.sequence) {
    sequences = getSequencesFromDesign(json);
  }

  return sequences;
};

const seqFrag = gql`
  fragment seqFrag on sequence {
    id
    name
    hash
    isJ5Sequence
    isInLibrary
  }
`;

export function checkForDuplicateSequencesSimple(seqArray) {
  let results = [];
  let sequences = [];
  const duplicateInfo = {};

  const hashes = {};
  const hashToId = {};
  const nameToId = {};

  sequences = seqArray;

  if (sequences.length) {
    addHashPropToSeqs(sequences);
    if (!results.length) results = sequences;

    const idToResult = keyBy(results, ({ id, hash }) => id || hash);
    // Initialize the map.

    for (const item of results) {
      const { id, hash } = item;
      const idToMap = id || hash;
      duplicateInfo[idToMap] = {
        importSequence: [],
        importName: [],
        dbSequence: [],
        dbName: []
      };
    }
    // Check for duplicates within the results in O(n) time.
    for (const i in results) {
      const { id, name, hash } = results[i];
      hashes[hash] = true;
      if (!hashToId[hash]) hashToId[hash] = [];
      if (!nameToId[name]) nameToId[name] = [];
      hashToId[hash].push(id || hash);
      nameToId[name].push(id || hash);
    }
    for (const ids of Object.values(hashToId)) {
      if (ids.length > 1) {
        for (const id of ids) {
          duplicateInfo[id].importSequence.push(getNameAndId(idToResult[id]));
        }
      }
    }

    for (const ids of Object.values(nameToId)) {
      if (ids.length > 1) {
        for (const id of ids) {
          duplicateInfo[id].importName.push(getNameAndId(idToResult[id]));
        }
      }
    }
  }

  return { duplicateInfo, results, hashes, hashToId, nameToId };
}

export async function checkForDuplicateSequences(json, { safeQuery } = {}) {
  const { duplicateInfo, results, hashes, hashToId, nameToId } =
    checkForDuplicateSequencesSimple(getSequences(json));

  // Check for duplicates within the database.
  const qb = new queryBuilder("sequence");
  const potentialDuplicates = flatMap(
    await safeQuery(seqFrag, {
      isPlural: true,
      fetchPolicy: "network-only",
      variables: {
        pageSize: 1000000,
        filter: qb
          .orWhereAll({
            hash: Object.keys(hashes)
          })
          .orWhereAll({
            name: Object.keys(nameToId)
          })
          .toJSON()
      }
    }),
    s => {
      //filter the returned seqs to remove any that are hidden
      if (!s.isInLibrary) return [];
      return s;
    }
  );
  const idToDuplicate = keyBy(potentialDuplicates, "id");

  for (const i of potentialDuplicates) {
    const { id: dupId, name, hash } = i;

    // Don't check the newly created sequences.
    if (duplicateInfo[dupId]) continue;

    if (hashToId[hash]) {
      for (const id of hashToId[hash]) {
        duplicateInfo[id].dbSequence.push(i);
      }
    }

    if (nameToId[name]) {
      for (const id of nameToId[name]) {
        duplicateInfo[id].dbName.push(getNameAndId(idToDuplicate[dupId]));
      }
    }
  }

  return { duplicateInfo, results };
}

/**
 * Finds duplicate sequences of the same hash in the database
 * @param {sequences} array sequences must include the following properties:
 *  hash sequence
 *  .hash (all) .sequence
 * @param {options.isProtein} is an amino acid sequence
 * @param {options.fragment} fragment for query
 */

export async function checkDuplicateSequencesExtended(
  sequences,
  options = {},
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;
  const {
    isProtein,
    fragment,
    doNotCreateParts,
    doNotCreateAliases,
    isGenomicRegionUpload,
    skipAssemblyGapCheck,
    waitToUpsert
  } = options;
  // add cids if they don't already have them
  sequences.forEach(seq => {
    if (!seq.cid) {
      seq.cid = shortid();
    }
  });

  if (!skipAssemblyGapCheck) {
    await stripAssemblyGaps(sequences);
  }

  let fragToUse = `${fragment || ""} id name hash`;
  if (!isProtein) {
    fragToUse += ` sequenceFeatures { id name type start end strand } parts { id name start end strand } sequenceFragments { id index fragment }`;
  }
  fragToUse += ` aliases { id name } polynucleotideMaterialId`;

  const filter = {
    isInLibrary: true,
    hash: uniq(sequences.map(s => s.hash)).filter(identity), // removes nil hash values
    ...(isGenomicRegionUpload ? { sequenceTypeCode: "GENOMIC_REGION" } : {})
  };

  const seqMatches = sequences.length
    ? await safeQuery(
        [isProtein ? "aminoAcidSequence" : "sequence", fragToUse],
        {
          variables: {
            filter
          }
        }
      )
    : [];

  const seqMatchesKeyedByHash = keyBy(seqMatches, "hash");
  const inputSeqsKeyedByHash = {};

  sequences.forEach(seq => {
    //find duplicates to existing seqs in the db
    if (seqMatchesKeyedByHash[seq.hash]) {
      seq.duplicateFound = seqMatchesKeyedByHash[seq.hash];
    } else if (inputSeqsKeyedByHash[seq.hash]) {
      // find duplicates within the input seqs themselves
      inputSeqsKeyedByHash[seq.hash].cid =
        inputSeqsKeyedByHash[seq.hash].cid || shortid();
      seq.duplicateFound = inputSeqsKeyedByHash[seq.hash];
      seq.duplicateOfInputSeq = true;
    } else {
      inputSeqsKeyedByHash[seq.hash] = seq;
    }
  });

  const duplicateSequencesFound = seqMatches;

  const handleUpserts = async sequencesToFinalize => {
    const sequenceAliases = [];
    sequencesToFinalize.forEach(sequence => {
      const dup = sequence.duplicateFound;
      if (dup) {
        const matchIndex = getCircularMatchIndex(sequence, dup);
        if (matchIndex > -1) {
          Object.assign(sequence, { rotatedBy: matchIndex });
        }
        if (dup.id) {
          const aliasesForSeq = sequence.aliases || [];
          aliasesForSeq.push({ name: sequence.name });
          aliasesForSeq.forEach(alias => {
            if (
              alias.name &&
              alias.name !== dup.name &&
              !dup.aliases.some(a => a.name === alias.name)
            ) {
              sequenceAliases.push({
                name: alias.name,
                [isProtein ? "aminoAcidSequenceId" : "sequenceId"]: dup.id
              });
            }
          });
        } else if (dup.cid) {
          dup.aliases = dup.aliases || [];
          if (
            sequence.name &&
            dup.name !== sequence.name &&
            !dup.aliases.some(a => a.name === sequence.name)
          ) {
            dup.aliases.push({
              name: sequence.name
            });
          }
        }
      }
    });

    const { creates: featureCreates, updates: featureUpdates } =
      await updateSequenceFeaturesAndParts(
        sequencesToFinalize,
        { doNotCreateParts },
        ctx
      );

    if (featureUpdates.length || featureCreates.length) {
      const doUpserts = async () => {
        await safeUpsert("sequenceFeature", featureUpdates, {
          excludeResults: true
        });
        await safeUpsert("sequenceFeature", featureCreates, {
          excludeResults: true
        });
      };
      if (isBrowser) {
        const updateFeatures = await window.showConfirmationDialog({
          className: "preserve-newline",
          text: `Some of the sequence files you are about to import bring \
        in new features that will be added to sequences already in the system. \
        This will change the features on existing sequences.\
        \n\nIf you wish to\
         proceed and update features on existing sequences click "Yes". \
         \n\nIf you do not want to update existing sequences click "No".\
          \n\nIf you click "No", those sequences will not be updated and \
          the feature set on existing sequences will not change.`,
          confirmButtonText: "Yes",
          thirdButtonText: "No",
          cancelButtonText: "Cancel Import"
        });
        if (!updateFeatures) {
          throw new Error("Import Cancelled");
        }
        if (updateFeatures && updateFeatures !== "thirdButtonClicked") {
          await doUpserts();
        }
      } else {
        await doUpserts();
      }
    }

    !doNotCreateAliases && (await upsertUniqueAliases(sequenceAliases, ctx));

    // add the existing sequences (and materials) to the active project
    await addRecordsToActiveProject(
      {
        recordIds: duplicateSequencesFound.map(s => s.id),
        model: "sequence"
      },
      ctx
    );
    await addRecordsToActiveProject(
      {
        recordIds: duplicateSequencesFound
          .map(s => s.polynucleotideMaterialId)
          .filter(id => id),
        model: "material"
      },
      ctx
    );
  };

  if (!waitToUpsert) {
    await handleUpserts(sequences);
  }

  const duplicateInputSequences = sequences.filter(s => s.duplicateFound);
  return {
    uniqueInputSequences: sequences.filter(s => !s.duplicateFound), //these are the inputs that didn't have any duplicates
    duplicateInputSequences, //these are the inputs that do have duplicates
    duplicatesOfInputSequences: sequences.filter(s => s.duplicateOfInputSeq), //these are input sequences that are duplicates of other input sequences
    //these are the inputs with seqData.duplicateFound=dupSeqData||false
    // where dupSeqData is an already existing sequence from our DB based on the fragment
    allInputSequencesWithAttachedDuplicates: sequences,
    //this is the array of just the duplicate sequences
    duplicateSequencesFound,
    handleUpserts
  };
}

/**
 * Finds duplicate sequences of the same hash in the database
 * @param {sequencesOrSequenceHashes} hashes of sequences to check
 * @param {options.isProtein} is an amino acid sequence
 * @param {options.fragment} fragment for query
 */

export async function checkDuplicateSequences(
  sequencesOrSequenceHashes,
  options = {},
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const { isProtein, fragment = "id name hash" } = options;
  const sequenceHashes = sequencesOrSequenceHashes.reduce((acc, s) => {
    const hash = typeof s === "string" ? s : s.hash;
    if (!acc.includes(hash)) acc.push(hash);
    return acc;
  }, []);
  if (!sequenceHashes.length) return [];
  const model = isProtein ? "aminoAcidSequence" : "sequence";
  const existingSequences = await safeQuery([model, fragment], {
    variables: {
      filter: {
        hash: sequenceHashes
      }
    }
  });

  // add the existing sequences (and materials) to the active project
  await addRecordsToActiveProject(
    {
      recordIds: existingSequences.map(s => s.id),
      model
    },
    ctx
  );
  await addRecordsToActiveProject(
    {
      recordIds: existingSequences
        .map(s => s.polynucleotideMaterialId)
        .filter(id => id),
      model: "material"
    },
    ctx
  );

  return existingSequences;
}

export const getFeatureKey = f =>
  `${f.name}:${f.type || "misc_feature"}:${f.strand}`;

export const getPartKey = p =>
  `${(p.name || "").toLowerCase()}:${p.start}:${p.end}:${p.strand}`;
