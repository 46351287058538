/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import path from "path";
import { instanceConfig } from "@teselagen/app-configs";
import { getRootDirectory } from "@teselagen/utils";

// IMPORTANT:
// - __dirname is not available on es6 modules.
// - this file was moved from the /server/hasura, so we are going to determine the _dirname as the to the server folder
const __dirname = path.join(getRootDirectory(), "server", "hasura");

const tableMapPath = path.resolve(
  path.resolve(__dirname, "../src/database"),
  instanceConfig.dataLibPath,
  "db/table-map.json"
);

export { tableMapPath };
