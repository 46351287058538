/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import { SubmissionError } from "redux-form";
import {
  checkForDuplicateMicrobialMaterials,
  getTransformationMicrobialMaterial,
  filterDuplicateMicrobialMaterials
} from "../../../utils";
import { getAliquotMaterialList } from "../../../utils/plateUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

const onSubmit = async ({
  worklistName,
  reactionMapName,
  worklist,
  microbialMaterial,
  destinationPlates = [],
  destinationTubes = [],
  aliquotContainers = [],
  inputType,
  intermediateContainers,
  transferToSourcePlates,
  containerArrays,
  generateBarcodes,
  destinationPlateTypes
}) => {
  try {
    let reformattedIntermediateContainers;
    if (transferToSourcePlates) {
      reformattedIntermediateContainers = intermediateContainers.map(
        container => {
          return {
            ...container,
            aliquotContainers: container.aliquotContainers.map(ac => {
              const cleanedAc = { ...ac };
              delete cleanedAc.containerArray;
              return cleanedAc;
            })
          };
        }
      );
    }
    const platesToCreate = destinationPlates.map((plate, index) => {
      return {
        ...plate,
        aliquotContainers: generateContainerArray(
          plate.aliquotContainers,
          destinationPlateTypes[index].containerFormat,
          {
            aliquotContainerTypeCode:
              destinationPlateTypes[index].aliquotContainerTypeCode
          }
        )
      };
    });

    let materialsToUpsert = [];
    const inputMicrobialMaterial = {
      ...microbialMaterial
    };

    const reactionsWithInputHash = [];
    const plasmidToOutputMaterialMap = {};
    let reactionIndex = 1;
    const addReactionForAliquotContainer = aliquotContainer => {
      if (aliquotContainer.aliquot) {
        const sampleMaterials = getAliquotMaterialList(
          aliquotContainer.aliquot
        );
        sampleMaterials.forEach(dnaMaterial => {
          if (!plasmidToOutputMaterialMap[dnaMaterial.id]) {
            const outputMaterial = getTransformationMicrobialMaterial(
              dnaMaterial,
              inputMicrobialMaterial
            );
            plasmidToOutputMaterialMap[dnaMaterial.id] = outputMaterial;
            materialsToUpsert.push(outputMaterial);
            const reactionInputMaterials = [
              inputMicrobialMaterial,
              dnaMaterial
            ];
            reactionsWithInputHash.push({
              name: "reaction " + reactionIndex++,
              ...getReactionInputsAndOutputs({
                inputMaterials: reactionInputMaterials,
                outputMaterialId: `&${outputMaterial.cid}`
              })
            });
          }
        });
      }
    };

    if (inputType === "PLATE") {
      containerArrays.forEach(plate => {
        plate.aliquotContainers.forEach(addReactionForAliquotContainer);
      });
    } else {
      aliquotContainers.forEach(addReactionForAliquotContainer);
    }

    // check for duplicate materials
    if (materialsToUpsert.length) {
      const cidKeyedMaterialMap = keyBy(materialsToUpsert, m => `&${m.cid}`);
      const duplicateMap = await checkForDuplicateMicrobialMaterials(
        materialsToUpsert
      );
      materialsToUpsert = [];
      reactionsWithInputHash.forEach(reaction => {
        const outputMaterial =
          cidKeyedMaterialMap[reaction.reactionOutputs[0].outputMaterialId];
        const existingMaterial = duplicateMap.get(outputMaterial);
        if (existingMaterial) {
          reaction.reactionOutputs[0].outputMaterialId = existingMaterial.id;
        } else {
          materialsToUpsert.push(outputMaterial);
        }
      });
    }

    if (materialsToUpsert.length) {
      materialsToUpsert = await filterDuplicateMicrobialMaterials(
        materialsToUpsert,
        reactionsWithInputHash
      );
    }
    const reactionMapToCreate = {
      name: reactionMapName,
      reactionTypeCode: "CLONAL_TRANSFORMATION",
      reactions: reactionsWithInputHash
    };
    const worklistToUpsert = stripFields(worklist, ["__typename"]);
    worklistToUpsert.name = worklistName;
    worklistToUpsert.worklistTransfers = worklistToUpsert.worklistTransfers.map(
      transfer => {
        delete transfer.sourceAliquotContainer;
        delete transfer.destinationAliquotContainer;
        delete transfer.destinationPlateName;
        return transfer;
      }
    );
    await safeUpsert("material", materialsToUpsert);

    let createdContainerArrays;
    let createdTubes;
    if (inputType === "PLATE") {
      createdContainerArrays = await safeUpsert(
        "containerArray",
        platesToCreate
      );
    } else {
      createdTubes = await safeUpsert("aliquotContainer", destinationTubes);
    }

    if (generateBarcodes || inputType === "TUBE") {
      await addBarcodesToRecords(createdContainerArrays || createdTubes);
    }
    await safeUpsert("containerArray", reformattedIntermediateContainers);
    const [createdReactionMap] = await safeUpsert(
      "reactionMap",
      reactionMapToCreate
    );

    worklistToUpsert.worklistReactionMaps = [
      {
        reactionMapId: createdReactionMap.id
      }
    ];
    const [createdWorklist] = await safeUpsert("worklist", worklistToUpsert);

    window.toastr.success("Successfully created worklist");
    return {
      containerArrays: createdContainerArrays,
      aliquotContainers: createdTubes,
      reactionMap: createdReactionMap,
      worklist: createdWorklist
    };
  } catch (error) {
    console.error("error:", error);
    const message = error.message || "Error creating worklist.";
    throw new SubmissionError({
      _error: message
    });
  }
};

export { onSubmit };
