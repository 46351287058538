/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import withQuery from "../withQuery";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";
import { safeUpsert } from "../apolloMethods";

class CreatePublicationTargetDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop } = this.props;
    const { id: updatedId, baseUrl, authKey, authToken } = values;
    try {
      await safeUpsert(["publicationTarget", "id baseUrl authKey authToken"], {
        id: updatedId,
        baseUrl,
        authKey,
        authToken
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating genus.");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="baseUrl"
            label="Base URL"
            isRequired
            placeholder="Enter base URL..."
          />
          <InputField
            name="authKey"
            label="Auth Key"
            isRequired
            placeholder="Enter auth key..."
          />
          <InputField
            name="authToken"
            label="Auth Token"
            type="password"
            isRequired
            placeholder="Enter auth token..."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreatePublicationTargetWithDialog = compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Publication Target"
          : "Create Publication Target"
      };
    }
  }),
  reduxForm({
    form: "createPublicationTarget"
  }),
  withQuery(["publicationTarget", "id baseUrl authKey authToken"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(CreatePublicationTargetDialog);

export default CreatePublicationTargetWithDialog;
