/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get } from "lodash";
import {
  getMaterialPlasmid,
  getMaterialPlasmidSequence
} from "./materialUtils";

export const START_CODON = "atg";

export const isGenbankFile = file => {
  return file.name && ["gb", "gbk"].includes(file.name.split(".").pop());
};

export const getSequencesToUse = props => {
  function getSeqsFromProps() {
    const { aliquot, material, sample, strain, genome, isGenomicRegion } =
      props;
    let entityWithSequences;
    if (strain) {
      entityWithSequences = strain;
    } else if (genome) {
      entityWithSequences = genome;
    } else {
      entityWithSequences =
        material || get(aliquot, "sample.material") || get(sample, "material");
    }
    if (!entityWithSequences) return [];
    if (entityWithSequences.__typename === "strain") {
      if (isGenomicRegion) {
        return (
          entityWithSequences.genome &&
          entityWithSequences.genome.genomeGenomicRegions.map(
            ggr => ggr.genomicRegion
          )
        );
      } else {
        return entityWithSequences.strainPlasmids.map(
          strainPlasmid => strainPlasmid.plasmid
        );
      }
    } else if (entityWithSequences.__typename === "genome") {
      return entityWithSequences.genomeGenomicRegions.map(
        ggr => ggr.genomicRegion
      );
    } else if (entityWithSequences.materialTypeCode === "MICROBIAL") {
      if (isGenomicRegion) {
        if (entityWithSequences.genome) {
          return entityWithSequences.genome.genomeGenomicRegions.map(
            ggr => ggr.genomicRegion
          );
        } else if (get(entityWithSequences, "strain.genome")) {
          return entityWithSequences.strain.genome.genomeGenomicRegions.map(
            ggr => ggr.genomicRegion
          );
        }
      } else {
        return entityWithSequences.microbialMaterialMicrobialMaterialPlasmids.map(
          mmp => ({
            ...getMaterialPlasmid(mmp),
            polynucleotideMaterialSequence: getMaterialPlasmidSequence(mmp),
            microbialMaterialPlasmidId: mmp.id,
            copyNumber: mmp.copyNumber
          })
        );
      }
    } else if (entityWithSequences.materialTypeCode === "CELL_CULTURE") {
      return entityWithSequences.cellCultureCellCulturePlasmids.map(ccp => ({
        ...getMaterialPlasmid(ccp),
        polynucleotideMaterialSequence: getMaterialPlasmidSequence(ccp),
        cellCulturePlasmidId: ccp.id,
        copyNumber: ccp.copyNumber
      }));
    } else if (
      entityWithSequences.polynucleotideMaterialSequence &&
      !isGenomicRegion
    ) {
      return [entityWithSequences.polynucleotideMaterialSequence];
    }
  }
  return getSeqsFromProps() || [];
};

export function assemblyReportEntityFilter({
  j5Reports,
  entityFilter,
  qb,
  onMaterial
}) {
  const j5ReportIds = j5Reports.map(j5Report => j5Report.id);

  const anyFilters = [];

  const xtra = onMaterial ? "polynucleotideMaterialSequence." : "";

  const allFilters = {
    // pcr reactions
    [`${xtra}j5PcrReactionsPCRProductSequences.j5ReportId`]: j5ReportIds,
    // primary templates
    [`${xtra}j5PcrReactionsPrimaryTemplates.j5ReportId`]: j5ReportIds,
    // j5 input sequence
    [`${xtra}j5InputSequence.j5ReportId`]: j5ReportIds,
    // assembly pieces
    [`${xtra}j5AssemblyPiece.j5ReportId`]: j5ReportIds,
    // constructs
    [`${xtra}j5RunConstruct.j5ReportId`]: j5ReportIds,
    // oligos
    [`${xtra}j5Oligo.j5PcrReactionsForwardPrimers.j5ReportId`]: j5ReportIds,
    [`${xtra}j5Oligo.j5PcrReactionsReversePrimers.j5ReportId`]: j5ReportIds
  };
  Object.keys(allFilters).forEach(key => {
    if (entityFilter && !key.toLowerCase().includes(entityFilter.toLowerCase()))
      return;
    anyFilters.push({
      [key]: allFilters[key]
    });
  });
  qb.whereAny(...anyFilters);
}
