/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { parse, stringify } from "qs";
import { compose } from "redux";
import React from "react";
import { Editor, updateEditor } from "@teselagen/ove";
import { isEqual, throttle, omit, get } from "lodash";
import joinUrl from "url-join";
import {
  withDialog,
  showConfirmationDialog,
  DropdownButton,
  DialogFooter,
  BlueprintError,
  InputField
} from "@teselagen/ui";
import immer from "immer";

import store from "../redux/store";
import { OligoAddView, SequenceAddView } from "./AddView";
import { beforeAnnotationCreate } from "./beforeAnnotationCreate";
import { safeQuery, safeUpsert } from "../apolloMethods";

import "./style.css";
import { safeDelete } from "../apolloMethods";
import { hideDialog, showDialog } from "../GlobalDialog";
import withQuery from "../withQuery";
import { Link, withRouter } from "react-router-dom";
import { enzymeManageOverride, getEnzymeOverrides } from "../utils/oveUtils";
import { oveAutoAnnotateHandlers } from "../AutoAnnotate/AutoAnnotateMenu";
import { sequenceToVeInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";

import { getAdditionalCreateOpts } from "../components/Dialogs/CreatePcrPrimersForRegionDialog";
import { handleSequenceSave } from "../utils/handleSequenceSave";
import { openInNewTab } from "../utils/generalUtils";
import {
  AnchorButton,
  Button,
  Callout,
  Intent,
  Menu,
  MenuItem,
  Position,
  Tooltip
} from "@blueprintjs/core";
import sequenceRecordFragment from "../fragments/sequenceRecordFragment_build";
import recordViewEnhancer from "../recordViewEnhancer";
import { withProps } from "recompose";
import { ShowLinkedDesignsToPart } from "./ShowLinkedDesignsToPart";
import { onCreateNewFromSubsequence } from "./onCreateNewFromSubsequence";
import ExportSequencesDialog from "../ExportSequencesDialog";
import { userEnzymeFilterPrefsFrag } from "../RestrictionEnzymeManagementPanel/GlobalFiltersPanel";
import RegisterAnnotationDialog from "../dialogs/RegisterAnnotationDialog";
import { ReverseTranslationDialog } from "../ReverseTranslation";
import { getVeDigestInfo } from "../../../tg-iso-shared/utils/digestPartUtils";
import { isAdmin, isLabAdmin } from "../utils/generalUtils";
import {
  adjustBpsToReplaceOrInsert,
  getAminoAcidStringFromSequenceString,
  getReverseComplementSequenceString,
  getSequenceDataBetweenRange
} from "@teselagen/sequence-utils";
import { getMaxSeqLen } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import { showReduxConfirmationDialog } from "../utils/asPromiseDialog";
import { some } from "lodash";
import shortid from "shortid";
import sequenceStringToFragments from "../../../tg-iso-shared/src/sequence-import-utils/sequenceStringToFragments";
import { LibrarySidePanelContext } from "../AbstractLibrary/LibrarySidePanelWrapper";

class SequenceEditView extends React.Component {
  static contextType = LibrarySidePanelContext;

  state = {};
  getNewFullscreenLink = () => {
    const { location } = this.props;
    const hash = parse(location.hash.replace("#", ""));
    const newHash = {};
    if (
      this.shouldNotDefaultFullscreen()
        ? !hash.sequenceEditorFullscreen ||
          hash.sequenceEditorFullscreen === "false"
        : hash.sequenceEditorFullscreen === "false"
    ) {
      newHash.sequenceEditorFullscreen = true;
    } else if (!this.shouldNotDefaultFullscreen()) {
      newHash.sequenceEditorFullscreen = false;
    }
    return `${location.pathname}#${stringify(newHash)}`;
  };

  toggleSequenceFullscreen = () => {
    const { history } = this.props;
    history.push(this.getNewFullscreenLink());
  };

  openEditorInNewTab = () => {
    openInNewTab(this.getNewFullscreenLink());
  };

  updateVeWithItem = sequence => {
    const { readOnly, editorOpts, editorName } = this.props;
    const veInputSequence = sequenceToVeInput(sequence);
    window.__lastSavedSequenceData = veInputSequence;
    if (window.Cypress) {
      window.Cypress.__tgUpdateOveEditor = opts => {
        updateEditor(store, editorName || "SequenceEditor", opts);
      };
    }
    const editorSessionKey = `hasEditorAlreadyBeenOpened`;
    updateEditor(store, editorName || "SequenceEditor", {
      readOnly: !!readOnly, //important that this is passed as false. Undefined is defaulted to true by ove
      caretPosition: -1,
      sequenceData: veInputSequence,
      sequenceDataHistory: {}, //clear the sequenceDataHistory if there is any left over from a previous sequence
      annotationVisibility: sessionStorage.getItem(editorSessionKey)
        ? {}
        : {
            features: true,
            translations: true,
            parts: true,
            orfs: false,
            orfTranslations: false,
            axis: true,
            cutsites: false,
            chromatogram: true,
            primers: true,
            lineageLines: false,
            axisNumbers: false
          },
      panelsShown: [
        [
          ...(sequence.isProtein
            ? []
            : [
                {
                  id: "circular",
                  name: "Plasmid",
                  active: !!veInputSequence.circular
                }
              ]),
          {
            id: "rail",
            name: "Linear Map",
            active: !veInputSequence.circular
          }
        ],
        [
          {
            id: "sequence",
            name: "Sequence Map",
            active: true
          },
          {
            id: "properties",
            name: "Properties"
          }
        ]
      ],
      ...editorOpts
    });
    sessionStorage.setItem(editorSessionKey, true);
  };

  tryToSave = (evt, sequenceData) => {
    const { sequence } = this.props;
    return handleSequenceSave({
      id: sequence.id,
      sequenceData,
      isCurrentlyBeingEdited: true
    });
  };

  UNSAFE_componentWillMount() {
    const { sequence, user } = this.props;
    if (user?.preferredEnzymeFilter) {
      try {
        window.localStorage.setItem(
          "tgInitialCutsiteFilter",
          JSON.stringify(user.preferredEnzymeFilter)
        );
      } catch (error) {
        console.warn(`Issue setting default enzymes:`, error);
      }
    } else {
      window.localStorage.setItem(
        "tgInitialCutsiteFilter",
        JSON.stringify([{ value: "type2s", isSpecialGroup: true }])
      );
    }
    if (sequence) this.updateVeWithItem(sequence);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.sequence) return;
    if (
      !this.props.sequence ||
      nextProps.sequence.id !== this.props.sequence.id ||
      // NOTE: For digest parts, the 'handleSequenceSave' function
      // is re-querying the digestPart data, that query is triggering this function
      // which in turn calls the 'updateVeWithItem' function which is causing the part edition to revert.
      // Thus, we set it so we don't act on part updates.

      // IMO, this is a hacky solution, but it works for now.
      // What I think should happen is that the sequenceData in the state should be somehow in sync with the database
      // data at all times, which would prevent us from having to refetch the digestPart info in the first place,
      // not sure why that isn't currently the case... Something might not be properly hooked up.
      !isEqual(
        omit(nextProps.sequence, ["parts"]),
        omit(this.props.sequence, ["parts"])
      )
    ) {
      this.updateVeWithItem(nextProps.sequence);
    }
  }

  async componentDidMount() {
    const { editorName } = this.props;

    window.addEventListener("resize", this.updateDimensions);
    if (window.location.search) {
      const urlString = parse(window.location.search, {
        ignoreQueryPrefix: true
      });
      if (urlString.bpRange) {
        const [start, end] = urlString.bpRange.split("-");
        const overlapsSelf = urlString.overlapsSelf;
        updateEditor(store, editorName || "SequenceEditor", {
          selectionLayer: {
            overlapsSelf,
            start: Number(start),
            end: Number(end)
          }
        });
      }
    }
    await getEnzymeOverrides((...args) => {
      this.setState(...args);
    });
  }

  componentWillUnmount() {
    const { editorName } = this.props;
    window.removeEventListener("resize", this.updateDimensions);
    updateEditor(store, editorName || "SequenceEditor", {
      selectionLayer: {
        start: -1,
        end: -1
      }
    });
  }

  updateDimensions = throttle(() => {
    this.forceUpdate();
  }, 250);

  onDuplicate = createDup;
  onNew = () => {
    store.dispatch({
      type: "TG_SHOW_MODAL",
      name: "createNewSequenceFromRangeDialog", //you'll need to pass a unique dialogName prop to the compoennt
      props: {
        noTitle: true,
        dialogProps: {
          title: "Create New Sequence"
        },
        onCancel,
        onCreateFromRangeFinished: () => {
          // pass this handler so we don't get automatically navigated to the new sequence
        },
        initialValues: {
          name: `Untitled Sequence`,
          description: ""
        }
      } // pass props to the wrapped component here :)
    });
  };
  onDelete = async () => {
    const confirm = await showConfirmationDialog({
      text: `Are you sure you want to delete this sequence? You cannot undo this action.`,
      intent: "danger",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      canEscapeKeyCancel: true
    });
    if (confirm) {
      await safeDelete("sequence", this.props.sequence.id);
      this.props.history.push("/sequences");
    }
  };
  isOligo = () => {
    const { sequence } = this.props;
    const isOligo = sequence && sequence.sequenceTypeCode === "OLIGO";
    return isOligo;
  };
  shouldNotDefaultFullscreen = () => {
    return true;
  };
  render() {
    const {
      sequence,
      readOnly,
      tags,
      history,
      location,
      isProtein,
      noFullScreen,
      onPreviewModeFullscreenClose,
      editorName = "SequenceEditor",
      hoveredId,
      showLeftSlideOutDrawer,
      refetchSequence,
      model,
      ...additionalProps
    } = this.props;
    const hash = parse(location.hash.replace("#", ""));
    const previewModeFullscreen = this.shouldNotDefaultFullscreen()
      ? hash.sequenceEditorFullscreen === "true"
      : hash.sequenceEditorFullscreen !== "false";
    const previewModeButtonMenu = (
      <Menu>
        <MenuItem text="Open in New Tab" onClick={this.openEditorInNewTab} />
      </Menu>
    );
    const showExportDialog = () =>
      showDialog({
        ModalComponent: ExportSequencesDialog,
        modalProps: {
          isProtein,
          sequenceIds: [sequence.id]
        }
      });
    // show header and sidebar of app even when OVE is fullscreen
    const fullScreenOffsets = {
      xOffset: showLeftSlideOutDrawer ? 260 : 0,
      yOffset: 50
    };
    const disableSetReadOnly = !isAdmin() && !isLabAdmin();
    // let unlockedEditorProps;
    // if (!readOnly) {
    //   unlockedEditorProps = {};
    // } else {

    // }

    const beforeReadOnlyChange = async editLock => {
      await window.tgApi({
        method: "PUT",
        url: `locks/${model}/${sequence.id}`,
        data: { setLock: editLock }
      });
      window.toastr.success(
        `Successfully ${editLock ? "locked" : "unlocked"} sequence.`
      );
    };

    const menuOverride = {
      menuFilter:
        // Menu customization example
        menuDef => {
          const exportOptionIndex = menuDef[0].submenu.findIndex(
            i => i.text && i.text.includes("Export")
          );

          menuDef[0].submenu[exportOptionIndex] = {
            text: "Export",
            onClick: showExportDialog
          };
          return menuDef;
        }
    };

    const toolList = [
      !sequence
        ? "downloadTool"
        : { name: "downloadTool", onIconClick: showExportDialog }
    ].concat(
      isProtein
        ? [
            "undoTool",
            "redoTool",
            "cutsiteTool",
            "featureTool",
            "oligoTool",
            "orfTool",
            "editTool",
            "findTool"
          ]
        : [
            "undoTool",
            "redoTool",
            "cutsiteTool",
            "featureTool",
            "partTool",
            "oligoTool",
            "orfTool",
            ...(sequence
              ? [
                  {
                    name: "alignmentTool",
                    onIconClick: () => {
                      const { sequence } = this.props;
                      const pathAndSearch =
                        "/alignments/new?seqId=" + sequence.id;
                      window.open(
                        joinUrl(window.location.origin, "client", pathAndSearch)
                      );
                    }
                  }
                ]
              : []),
            "editTool",
            "findTool"
          ]
    );
    return (
      <div className="sequence-edit-container tg-flex">
        <CreateNewSequenceFromRangeDialog />
        <Editor
          className="sequence-editor"
          enzymeManageOverride={() => {
            enzymeManageOverride(history);
          }}
          bpLimit={getMaxSeqLen()}
          onHiddenEnzymeAdd={async e => {
            try {
              await safeUpsert("restrictionEnzyme", {
                name: e.name,
                recognitionLength: e.site.length,
                sequence: e.site,
                forwardSnipPosition: e.topSnipOffset,
                reverseSnipPosition: e.bottomSnipOffset,
                recognitionRegex: e.forwardRegex,
                reverseRecognitionRegex: e.reverseRegex,
                enzymeTypeCode: e.isType2S ? "TYPE_IIS" : "NOT_TYPE_IIS"
              });
              await getEnzymeOverrides((...args) => {
                this.setState(...args);
              });
              window.toastr.success(
                `Successfully added ${e.name} to your list of enzymes.`,
                {
                  link: `/settings/enzyme-management`,
                  linkText: "Manage Enzymes",
                  timeout: 20000
                }
              );
            } catch (e) {
              window.toastr.error(
                "Error Adding Hidden Enzyme - Please contact Teselagen for support"
              );
              console.error(`error adding hidden enzyme:`, e);
            }
          }}
          allowPrimerBasesToBeEdited
          allowPartsToOverlapSelf={
            !isProtein && window.frontEndConfig.enableOverlappingParts
          }
          onConfigureFeatureTypesClick={() => {
            openInNewTab("/settings/feature-types");
          }}
          additionalTopRightToolbarButtons={
            <>
              {readOnly && (
                <Tooltip
                  content={`Create an editable duplicate of this ${
                    this.isOligo() ? "Oligo" : "Sequence"
                  }`}
                >
                  <Button
                    onClick={() => {
                      //tnw - need to make sure this sequence is up to date with all changes..
                      this.onDuplicate(sequenceToVeInput(sequence));
                    }}
                    style={{
                      whiteSpace: "nowrap",
                      marginTop: 5,
                      marginRight: 5
                    }}
                    intent="warning"
                  >
                    Duplicate {this.isOligo() ? "Oligo" : "Sequence"}
                  </Button>
                </Tooltip>
              )}
              {sequence?.parentSequenceId && (
                <Tooltip content="Jump to the sequence this sequence was duplicated from">
                  <Link
                    key="viewParentSequence"
                    to={`/sequences/${sequence?.parentSequenceId}`}
                  >
                    <AnchorButton
                      style={{
                        whiteSpace: "nowrap",
                        marginTop: 5,
                        marginRight: 5
                      }}
                      intent="warning"
                    >
                      View Parent Sequence
                    </AnchorButton>
                  </Link>
                </Tooltip>
              )}

              {sequence?.parentSequenceSequences?.length === 1 && (
                <Tooltip content="">
                  <Link
                    key="viewChildSequence"
                    to={`/sequences/${sequence?.parentSequenceSequences[0].id}`}
                  >
                    <AnchorButton
                      style={{
                        whiteSpace: "nowrap",
                        marginTop: 5,
                        marginRight: 5
                      }}
                      intent="primary"
                    >
                      View Child Sequence
                    </AnchorButton>
                  </Link>
                </Tooltip>
              )}
              {sequence?.parentSequenceSequences?.length > 1 && (
                <Tooltip content="">
                  <DropdownButton
                    style={{
                      whiteSpace: "nowrap",
                      marginTop: 5,
                      marginRight: 5
                    }}
                    popoverProps={{ position: Position.BOTTOM_RIGHT }}
                    intent="primary"
                    menu={
                      <Menu>
                        {sequence?.parentSequenceSequences.map(
                          ({ id, name }) => {
                            return (
                              <MenuItem
                                key={id}
                                onClick={() => openInNewTab(`/sequences/${id}`)}
                                text={name}
                              ></MenuItem>
                            );
                          }
                        )}
                      </Menu>
                    }
                  >
                    View Child Sequence
                  </DropdownButton>
                </Tooltip>
              )}

              {sequence?.isInLibrary && (
                <Tooltip content="View in Library">
                  <Link
                    key="partSourceInLibrary"
                    to={`/sequences?filters=id__equalTo__${sequence?.id}`}
                  >
                    <AnchorButton
                      minimal
                      style={{
                        marginTop: 5,
                        marginRight: 5
                      }}
                      icon="git-repo"
                    ></AnchorButton>
                  </Link>
                </Tooltip>
              )}
            </>
          }
          rightClickOverrides={{
            selectionLayerRightClicked: addCodonOptimizeOption({
              editorName,
              isSelectionRightClick: true
            }),
            translationRightClicked: addCodonOptimizeOption({ editorName }),
            featureRightClicked: (items, { annotation }) =>
              // { selectionLayer, sequenceData }
              {
                // const seqDataForRange = getSequenceDataBetweenRange(
                //   sequenceData,
                //   selectionLayer
                // );
                return [
                  ...items,
                  {
                    text: "Register Feature",
                    onClick: () => {
                      showDialog({
                        ModalComponent: RegisterAnnotationDialog,
                        modalProps: {
                          annotationType: "feature",
                          tabId: "register-existing",
                          showLinkInToast: true,
                          annotationTableProps: {
                            additionalTableProps: {
                              selectAllByDefault: true,
                              withSelectAll: true,
                              safeQuery
                            },
                            tableParamOptions: {
                              initialValues: {
                                reduxFormQueryParams: {
                                  filters: [
                                    {
                                      selectedFilter: "equalTo",
                                      filterOn: "id",
                                      filterValue: annotation.id
                                    }
                                  ]
                                }
                              }
                            }
                          },
                          refetch: () => {}
                        }
                      });
                    }
                  }
                ];
              }
          }}
          allowMultipleFeatureDirections
          hoveredId={hoveredId}
          additionalEnzymes={this.state.additionalEnzymes || {}}
          enzymeGroupsOverride={this.state.enzymeGroupsOverride || {}}
          allPartTags={tags}
          readOnly={readOnly}
          // disableBpEditing={disableBpEditing}
          disableSetReadOnly={disableSetReadOnly}
          beforeReadOnlyChange={beforeReadOnlyChange}
          showMenuBar
          getAdditionalEditAnnotationComps={({
            annotationTypePlural,
            initialValues
            // ...rest
          }) => {
            if (annotationTypePlural === "parts") {
              return (
                <ShowLinkedDesignsToPart
                  partId={initialValues && initialValues.id}
                />
              );
            } else {
              return null;
            }
          }}
          getLinkedOligoLink={getLinkedOligoLink}
          getAdditionalCreateOpts={getAdditionalCreateOpts}
          hideSingleImport //hide the Import Sequence file menu option and disable dragging into the editor
          showAvailability // show the availability status
          {...oveAutoAnnotateHandlers}
          // PropertiesProps: { //tnr: might not need this
          //   propertiesList: [
          //     "general",
          //     "features",
          //     "parts",
          //     "primers",
          //     "translations",
          //     "cutsites",
          //     "orfs",
          //     "genbank"
          //   ]
          // },
          ToolBarProps={{
            //name the tools you want to see in the toolbar in the order you want to see them
            toolList
          }}
          {...{
            beforeAnnotationCreate: !isProtein && beforeAnnotationCreate,
            shouldAutosave: true,
            onCreateNewFromSubsequence: onCreateNewFromSubsequence,
            onDelete: !readOnly && this.onDelete,
            onDuplicate: this.onDuplicate,
            onNew: this.onNew,
            onSave: this.tryToSave
          }}
          editorName={editorName || "SequenceEditor"}
          doNotUseAbsolutePosition
          withPreviewMode={!noFullScreen}
          previewModeFullscreen={previewModeFullscreen}
          fullScreenOffsets={fullScreenOffsets}
          togglePreviewFullscreen={this.toggleSequenceFullscreen}
          previewModeButtonMenu={previewModeButtonMenu}
          onPreviewModeFullscreenClose={onPreviewModeFullscreenClose}
          // 'extraAnnotationProps' are any kind of additional props
          // that OVE needs  to draw annotations. For digestParts, these are
          // the enzyme and overhangs of digest parts.
          extraAnnotationProps={{
            part: getVeDigestInfo
          }}
          // add a custom menu, for custom export dialog
          {...menuOverride}
          model={model}
          {...additionalProps}
        />
      </div>
    );
  }
}

const TgSequenceEditor = compose(
  withQuery(
    [
      "tag",
      /* GraphQL */ `
        {
          id
          name
          color
          tagOptions {
            id
            name
            color
          }
        }
      `
    ],
    {
      isPlural: true,
      variables: {
        pageSize: 99999
      },
      inDialog: true
    }
  ),
  withQuery(userEnzymeFilterPrefsFrag, {
    skip: () => !localStorage.getItem("userId"),
    options: () => ({
      variables: { id: localStorage.getItem("userId") }
    }),
    showLoading: true,
    inDialog: true
  }),
  withProps(props => {
    // Usually j5 sequences are opened from the assembly report.
    // These j5Sequences are linked to j5 records such as assembly pieces,
    // which have their own name but are linked to a sequence record wth a different name.
    // So in those cases we'd like to display the name of the j5Record and not the name of
    // the actual sequence it is linked to in the database.
    // Hopefully, in the future we'll implement a sequence map approach, that might help with this.
    if (props.sequence && props.sequence?.isJ5Sequence) {
      const sequence = {
        ...props.sequence,
        name: props.history?.location?.state?.seqName || props.sequence.name
      };
      props = { ...props, sequence };
    }
    return props;
  }),
  withRouter
)(SequenceEditView);

const CreateNewSequenceFromRangeDialog = withDialog({
  title: "Create New Sequence From Range",
  dialogName: "createNewSequenceFromRangeDialog"
})(
  class _CreateNewSequenceFromRangeDialog extends React.Component {
    state = { hasFinished: false, seq: {} };
    render() {
      const { isOligo } = this.props;
      if (this.state.hasFinished) {
        return (
          <div style={{ padding: 20 }}>
            {" "}
            <h3>New {isOligo ? "Oligo" : "Sequence"} Created Successfully!</h3>
            Visit it here:{" "}
            <Link
              onClick={this.props.hideModal}
              to={`/${isOligo ? "oligos" : "sequences"}/${this.state.seq.id}`}
            >
              {this.state.seq.name}
            </Link>
          </div>
        );
      } else {
        const Comp = isOligo ? OligoAddView : SequenceAddView;
        return (
          <div style={{ padding: 20, width: "100%", display: "flex" }}>
            <Comp
              {...{
                style: { width: "100%" },
                ...this.props,
                noCard: true,
                onCreateFromRangeFinished: async (...args) => {
                  const res = await this.props.onCreateFromRangeFinished(
                    ...args
                  );
                  this.setState({ hasFinished: true, seq: args[0] });
                  return res;
                }
              }}
            />
          </div>
        );
      }
    }
  }
);

//by default the TgSequenceEditor is expecting a sequence prop to be passed in. Use TgSequenceEditorConnected if you want to have the the sequence come in from the route
export default TgSequenceEditor;
//we use the seqEdEnhancer in the DNASequenceRecordView as well so we'll export it here
export const seqEdEnhancer = compose(
  recordViewEnhancer(sequenceRecordFragment, {}),
  withProps(props => {
    return {
      ...props,
      disableBpEditing:
        (get(props.sequence, "sequenceJ5ItemViews[0].j5Report.id")
          ? "Editing the base pairs of this sequence is disabled because it is referenced from a DNA Assembly Report."
          : false) ||
        (get(props.sequence, "isJ5Sequence")
          ? "Editing the base pairs of this sequence is disabled because it is referenced from a DNA Assembly Report."
          : false) ||
        (get(props.sequence, "sequenceAllInventoryView.sampleCount") ||
        get(props.sequence, "sequenceAllInventoryView.containerCount")
          ? "Editing the base pairs of this sequence is disabled because it is linked to an aliquot in inventory."
          : false),
      readOnly:
        props.sequence?.childLockId || props.sequence?.lockId
          ? "This sequence is Read Only because it is currently being edited by another user."
          : props.readOnly
    };
  })
);

export { sequenceRecordFragment };

const getLinkedOligoLink = ({ initialValues, linkedOligo }) => {
  if (initialValues?.oligoWithBindingSiteId) {
    return (
      <Link to={`/oligos/${initialValues?.oligoWithBindingSiteId}`}>
        {linkedOligo ||
          initialValues.oligoWithBindingSiteName ||
          initialValues.name}
      </Link>
    );
  }
};

const onCancel = () =>
  store.dispatch({
    type: "TG_HIDE_MODAL",
    name: "createNewSequenceFromRangeDialog" //you'll need to pass a unique dialogName prop to the compoennt
  });

const addCodonOptimizeOption =
  ({ editorName, isSelectionRightClick }) =>
  (items, { annotation }, { sequenceData }) => {
    const fromProtein = sequenceData.isProtein;
    if (
      (fromProtein && isSelectionRightClick) ||
      (!fromProtein && !isSelectionRightClick)
    ) {
      const seqDataForRange = getSequenceDataBetweenRange(
        sequenceData,
        annotation
      );
      items.splice(
        fromProtein ? 1 : 2,
        0,
        ...[
          { divider: "" },
          {
            text: "Reverse Translate",
            onClick: () => {
              const seqStrToUse =
                fromProtein || annotation.forward
                  ? seqDataForRange.sequence
                  : getReverseComplementSequenceString(
                      seqDataForRange.sequence
                    );
              showDialog({
                ModalComponent: ReverseTranslationDialog,
                modalProps: {
                  dialogProps: {
                    title: fromProtein
                      ? "Create Reverse Translated DNA Sequence"
                      : ((annotation.isOrf
                          ? "Open Reading Frame"
                          : annotation.name) || "") + " Reverse Translation"
                  },
                  submitText: fromProtein ? "Create" : "Reverse Translate",
                  additionalHeaderEl: (
                    <Callout style={{ marginBottom: 10 }} intent="primary">
                      {fromProtein
                        ? `Create a new reverse translated DNA sequence from the selected region`
                        : `Update the underlying DNA for the Selected Translation`}
                    </Callout>
                  ),
                  aaSeqsToRevTrans: [
                    {
                      proteinSequence:
                        getAminoAcidStringFromSequenceString(seqStrToUse)
                    }
                  ],
                  onRevTransFinished: async (
                    [{ reverseTranslatedDna }],
                    { goBack, translationInfo }
                  ) => {
                    if (fromProtein) {
                      const seqCid = shortid();
                      const newName =
                        sequenceData.name + " Reverse Translation";
                      const des = `Derived from ${`amino acid ${sequenceData.name}`} using reverse-translator ${
                        translationInfo.name || ""
                      } ${translationInfo.description || ""}`;
                      const [seq] = await safeUpsert(["sequence", "id name"], {
                        cid: seqCid,
                        name: newName,
                        description: des,
                        sequenceTypeCode: "LINEAR_DNA",
                        sequenceFeatures: [
                          {
                            name: newName,
                            start: 0,
                            end: reverseTranslatedDna.length - 1,
                            strand: 1,
                            type: "CDS"
                          }
                        ],
                        sequenceFragments:
                          sequenceStringToFragments(reverseTranslatedDna)
                      });

                      return {
                        nextPage: (
                          <>
                            <div className="bp3-dialog-body">
                              Created 1 new sequence:
                              <div key={seq.id}>
                                <Link to={`/sequences/${seq.id}`}>
                                  {seq.name}
                                </Link>
                              </div>
                            </div>
                            <DialogFooter
                              noCancel
                              text="Finish"
                              onClick={() => {
                                hideDialog();
                              }}
                            ></DialogFooter>
                          </>
                        )
                      };
                    }
                    let noChangesToMake = true;
                    const hasExistingCdsFeat = !annotation.isOrf;
                    const updateBps = ({
                      newCdsFeat,
                      replaceExisting
                    } = {}) => {
                      const feats = newCdsFeat
                        ? replaceExisting
                          ? immer(sequenceData.features, feats => {
                              some(feats, feat => {
                                if (
                                  feat.name === annotation.name &&
                                  feat.start === annotation.start &&
                                  feat.end === annotation.end
                                ) {
                                  feat.name = newCdsFeat.name;
                                  return true;
                                }
                              });
                            })
                          : [
                              ...Object.values(sequenceData.features),
                              newCdsFeat
                            ]
                        : undefined;

                      try {
                        updateEditor(
                          store,
                          editorName || "SequenceEditor",
                          {
                            justPassingPartialSeqData: true,
                            sequenceData: {
                              ...(newCdsFeat && {
                                features: feats
                              }),
                              sequence: adjustBpsToReplaceOrInsert(
                                sequenceData.sequence,
                                annotation.forward
                                  ? reverseTranslatedDna
                                  : getReverseComplementSequenceString(
                                      reverseTranslatedDna
                                    ),
                                annotation
                              )
                            }
                          },
                          {
                            disregardUndo: false
                          }
                        );
                      } catch (e) {
                        console.error(`e:`, e);
                      }

                      window.toastr.success(
                        `Updated Underlying DNA ${
                          newCdsFeat ? "and Added New CDS Feature" : ""
                        }`
                      );
                      hideDialog();
                    };
                    return {
                      nextPage: (
                        <>
                          <div className="bp3-dialog-body">
                            This will make the following changes to the
                            underlying DNA of the chosen translation:
                            {!annotation.forward && (
                              <div style={{ fontSize: 10 }}>
                                ** Note the translation is in the reverse
                                direction **
                              </div>
                            )}
                            <div
                              style={{
                                marginTop: 10,
                                maxWidth: 400,
                                overflow: "auto"
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  fontFamily: "monospace"
                                }}
                              >
                                <div>Old:&nbsp;</div>
                                <div>{seqStrToUse.toUpperCase()}</div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  fontFamily: "monospace"
                                }}
                              >
                                <div>New:&nbsp;</div>
                                <div>
                                  {reverseTranslatedDna
                                    .split("")
                                    .map((v, i) => {
                                      if (
                                        v.toLowerCase() !==
                                        seqStrToUse[i].toLowerCase()
                                      ) {
                                        noChangesToMake = false;
                                        return (
                                          <span
                                            key={i}
                                            style={{
                                              background: "lightcoral"
                                            }}
                                          >
                                            {v.toUpperCase()}
                                          </span>
                                        );
                                      }
                                      return v.toUpperCase();
                                    })}
                                </div>
                              </div>
                            </div>
                            {noChangesToMake && (
                              <BlueprintError error="No Changes to Make"></BlueprintError>
                            )}
                          </div>
                          <DialogFooter
                            onBackClick={goBack}
                            hideModal={hideDialog}
                            disabled={noChangesToMake}
                            secondaryDisabled={noChangesToMake}
                            text="Update"
                            secondaryIntent={Intent.PRIMARY}
                            secondaryText="Update and Add New CDS Feature"
                            secondaryAction={async () => {
                              const res = await showReduxConfirmationDialog({
                                title: "Add New",
                                ...(hasExistingCdsFeat && {
                                  thirdButtonText: "Replace Existing",
                                  thirdButtonIntent: Intent.PRIMARY
                                }),
                                confirmButtonText: "Add New CDS Feature",
                                text: (
                                  <div>
                                    <InputField
                                      defaultValue={`${
                                        annotation.name
                                          ? annotation.name + " RevTrans -"
                                          : "RevTrans -"
                                      } ${translationInfo.name} ${
                                        translationInfo.description
                                      }`.trimEnd()}
                                      label="New CDS Name"
                                      isRequired
                                      name="name"
                                    ></InputField>
                                  </div>
                                )
                              });
                              if (!res) return;
                              const { name, thirdButtonClicked } = res;
                              updateBps({
                                replaceExisting: thirdButtonClicked,
                                newCdsFeat: {
                                  name,
                                  start: annotation.start,
                                  end: annotation.end,
                                  forward: annotation.forward,
                                  type: "CDS"
                                }
                              });
                            }}
                            onClick={() => {
                              updateBps();
                            }}
                          ></DialogFooter>
                        </>
                      )
                    };
                  }
                }
              });
            }
          },
          { divider: "" }
        ]
      );
    }
    return items;
  };

const createDup = seqData =>
  store.dispatch({
    type: "TG_SHOW_MODAL",
    name: "createNewSequenceFromRangeDialog", //you'll need to pass a unique dialogName prop to the compoennt
    props: {
      noTitle: true,
      dialogProps: {
        title: seqData.isOligo ? "Duplicate Oligo" : "Duplicate Sequence"
      },
      isOligo: seqData.isOligo,
      onCancel,
      parentSequenceId: seqData.id,
      onCreateFromRangeFinished: ({ id, ...rest }) => {
        return handleSequenceSave({
          id,
          doNotShowSeqSaveMsgs: true,
          sequenceData: { ...seqData, id, ...rest }
        });
        // safeQuery()
      },
      initialValues: {
        name: `${seqData.name}_copy`,
        circularSelect: seqData.circular ? "circular" : "linear",
        description: seqData.description
      }
    } // pass props to the wrapped component here :)
  });
