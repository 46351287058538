/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { pick, cloneDeep, keyBy, omitBy, reduce, noop } from "lodash";
import { readAsText } from "promise-file-reader";

import {
  createDesignMutationsFromCSV,
  swapDuplicatedSequencesInDesign
} from "./designUtils";
import fourModuleDevice from "../exampleDesignTemplates/fourModuleDevice";
import cellFree from "../exampleDesignTemplates/cellFree";
import genericModule from "../exampleDesignTemplates/genericModule";
import combinatorialGoldenGateWithPlaceholderGene from "../exampleDesignTemplates/combinatorialGoldenGateWithPlaceholderGene";
import combinatorialGibsonWithPlaceholderBackbone from "../exampleDesignTemplates/combinatorialGibsonWithPlaceholderBackbone";
import defaultDesignTemplate from "../components/EmptyDesignTemplate/defaultDesignTemplate";
import defaultDesign from "../components/EmptyDesign/defaultDesign";
import { safeQuery, safeUpsert } from "../../src-shared/apolloMethods";
import duplicatedSequenceInDesignFragment from "../graphql/fragments/duplicatedSequenceInDesignFragment";
import { hideDialog } from "../../src-shared/GlobalDialog";
import { isHdeDesignJson } from "../../../tg-iso-design/utils/isHdeDesignJson";
import createDesignMutationsFromCsvGenbank from "./createDesignMutationsFromCsvGenbank";
import performOldStyleMutations from "../../../tg-iso-design/designImport/performOldStyleMutations";
import {
  getValidAssemblyMethods,
  isDeprecatedHdeDesignJson,
  isOldHdeDesignJson,
  isPeonyDesignJson,
  isSimpleDesignJson,
  patchOldDesigns
} from "../../../tg-iso-design/designImport/importUtils";
import importDesignFromHdeJson from "../../../tg-iso-design/designImport/importDesignFromHdeJson";
import oldHdeJsonToMutationInput from "../../../tg-iso-design/designImport/oldHdeJsonToMutationInput";
import peonyJsonToMutationInput from "../../../tg-iso-design/designImport/peonyJsonToMutationInput";
import exampleDesignMap from "../../../tg-iso-design/exampleDesigns";
import {
  filterFilesInZip,
  isZipFile,
  isCsvFile,
  isExcelFile,
  parseCsvOrExcelFile,
  removeExt
} from "../../../tg-iso-shared/src/utils/fileUtils";
import importDesignSimpleJson from "../../../tg-iso-design/designImport/importDesignSimpleJson";
import importDivaXml from "../../../tg-iso-shared/utils/importDivaXml";
import { updateDesignToLatestVersion } from "../../../tg-iso-shared/src/utils/uploadDesign";
import appGlobals from "../../src-shared/appGlobals";
import { showStackedDialog } from "../../src-shared/StackedDialog";
import DuplicateSequenceDialog from "../components/Dialogs/DuplicateSequenceDialog";

const exampleTemplateMap = {
  "Generic Module": genericModule,
  "Four Module Device": fourModuleDevice,
  "Cell Free": cellFree,
  "Combinatorial Golden Gate (with placeholder gene)":
    combinatorialGoldenGateWithPlaceholderGene,
  "Combinatorial Gibson (with placeholder backbone)":
    combinatorialGibsonWithPlaceholderBackbone
};

const CANCELED_FLAG = "Canceled";

/**
 * Class to process importing designs and design templates.
 * Also checks duplicates from attached file or selected example design
 * @export
 * @class DesignImport
 */
export class DesignImport {
  constructor(history, isDesignTemplate, refetch) {
    this.history = history;
    this.isDesignTemplate = isDesignTemplate;
    this.refetch = refetch || noop;
  }

  processImport = async values => {
    const designType = this.isDesignTemplate
      ? "design-template"
      : "grand-design";
    const designRoute = this.isDesignTemplate ? "design-templates" : "designs";

    const overrides = {
      ...pick(values, ["name", "description", "layoutType"]),
      type: designType
    };
    const haveFiles = values.inputFiles && values.inputFiles.length;

    async function handleMutations({ mutations, numFiles }) {
      const { designId } = await performOldStyleMutations({
        mutations
      });
      if (numFiles === 1) {
        return appGlobals.history.push(`/${designRoute}/${designId}`);
      }
    }

    if (haveFiles) {
      for (const file of values.inputFiles) {
        let files = [file];
        if (isZipFile(file)) {
          files = await filterFilesInZip(file, [
            ".csv",
            ".json",
            ".xlsx",
            ".gb",
            ".xml"
          ]);
        }

        if (
          files.some(file => file.name.endsWith(".gb")) &&
          files.some(file => file.name.endsWith(".csv"))
        ) {
          //short circuit in the case we have a csv + genbank design
          const mutations = await createDesignMutationsFromCsvGenbank(files);
          return await handleMutations({
            mutations,
            numFiles: values.inputFiles.length
          });
        }

        for (const file of files) {
          let isCsv = isCsvFile(file);
          let isJson = file.name.endsWith(".json");
          const isXml = file.name.endsWith(".xml");
          const isXlsx = isExcelFile(file);
          let data;

          let filenames = [];
          filenames = [file.name];
          if (isXlsx || isCsv) {
            data = await parseCsvOrExcelFile(file);
            isCsv = !!data;
          } else {
            data = await readAsText(file.originFileObj || file);
            if (isJson) data = JSON.parse(data);
            if (isXml) {
              data = await importDivaXml(data, removeExt(file.name));
              isJson = true;
            }
          }

          let designData;

          if (this.isDesignTemplate) {
            if (isCsv) {
              designData = await createDesignMutationsFromCSV(data, file.name);
            } else {
              designData = this.processTemplateData(data);
            }
            if (!Array.isArray(designData)) {
              designData = [designData];
            }
          } else {
            let processedData;
            if (isCsv) {
              processedData = await createDesignMutationsFromCSV(
                data,
                file.name
              );
            } else {
              processedData = data;
            }

            if (Array.isArray(processedData)) {
              designData = processedData.map(pd => patchOldDesigns(pd));
            } else {
              designData = [patchOldDesigns(processedData)];
            }
          }

          const mutations = isCsv ? designData : null;
          const fileJson = isCsv ? null : designData;

          if (mutations) {
            updateDesignToLatestVersion(mutations, { isMutations: true });
            await handleMutations({
              mutations,
              numFiles: files.length
            });
          } else if (fileJson) {
            await getValidAssemblyMethods(fileJson, { safeQuery });
            const singleFile =
              fileJson.length === 1 &&
              values.inputFiles.length === 1 &&
              files.length === 1;
            for (const [index, jsonFile] of fileJson.entries()) {
              const filename = filenames[index] || "";
              if (isDeprecatedHdeDesignJson(jsonFile)) {
                return window.toastr.error(
                  `${filename}: Trying to import a deprecated json format.`
                );
              }

              let designId = null;
              if (isHdeDesignJson(jsonFile)) {
                updateDesignToLatestVersion(jsonFile);
                designId = await this.importFromDesignFile(
                  jsonFile,
                  overrides,
                  true
                );
              } else if (isSimpleDesignJson(jsonFile)) {
                designId = await this.importSimplifiedJsonDesign(jsonFile);
              } else {
                updateDesignToLatestVersion(jsonFile);
                designId = await this.importFromOldDesignFile(
                  jsonFile,
                  overrides
                );
              }

              if (designId === CANCELED_FLAG)
                return window.toastr.warning("Upload Canceled.");
              if (singleFile) {
                return designId
                  ? this.history.push(`/${designRoute}/${designId}`)
                  : window.toastr.error(
                      `${filename}: The chosen file doesn't have the correct JSON format`
                    );
              }
            }
          }
        }
      }
      await this.refetch();
      hideDialog();
    } else {
      // Create a new design
      const designId = await this.createNewDesign(values, overrides);
      if (designId === CANCELED_FLAG)
        return window.toastr.warning("Upload Canceled.");
      return designId
        ? this.history.push(`/${designRoute}/${designId}`)
        : window.toastr.error(
            `Error creating design${this.isDesignTemplate ? " template" : ""}.`
          );
    }
  };

  processTemplateData = json => {
    if (!isOldHdeDesignJson(json) && !isHdeDesignJson(json)) {
      window.toastr.error("File was not valid design template json.");
    } else {
      if (
        (isOldHdeDesignJson(json) && json.type === "grand-design") ||
        (isHdeDesignJson(json) &&
          Object.values(json.design)[0].type === "grand-design")
      ) {
        if (isOldHdeDesignJson(json)) {
          json.type = "design-template";
          json.sets.forEach(set => {
            if (set.id === json.rootCardId) set.name = "Template Head";
          });
        } else if (isHdeDesignJson(json)) {
          Object.values(json.design)[0].type = "design-template";
        }

        window.toastr.warning("Converting design file to template format");
      }
      return json;
    }
  };

  importFromDesignFile = async (file, overrides, checkDuplicates) => {
    const { duplicateInfo, designId, results } = await importDesignFromHdeJson(
      file,
      overrides,
      checkDuplicates
    );

    if (duplicateInfo) {
      const resp = await this.showDuplicatedSequences(
        file,
        results,
        duplicateInfo
      );

      if (resp) return resp;
    }

    return designId;
  };

  importSimplifiedJsonDesign = async file => {
    const { designId } = await importDesignSimpleJson({ json: file });
    return designId;
  };

  importFromOldDesignFile = async (file, overrides) => {
    let mutations;

    if (isOldHdeDesignJson(file))
      mutations = await oldHdeJsonToMutationInput(file, true);
    else if (isPeonyDesignJson(file))
      mutations = await peonyJsonToMutationInput(file);
    else return null;

    const { designId } = await performOldStyleMutations({
      mutations,
      designValuesOverride: overrides
    });

    return designId;
  };

  /**
   * Create design from template
   * @param {*} values
   * @param {*} overrides
   */
  createNewDesign = async (values, overrides) => {
    let desId = null;
    // Load design from template
    const exampleTemplate = this.isDesignTemplate
      ? values.exampleDesignTemplate
      : values.exampleDesign;
    const exTemplateMap = this.isDesignTemplate
      ? exampleTemplateMap
      : exampleDesignMap;

    if (exampleTemplate !== "-" && exampleTemplate) {
      const exampleDesignJson = cloneDeep(exTemplateMap[exampleTemplate]);
      const { duplicateInfo, designId, results } =
        await importDesignFromHdeJson(exampleDesignJson, overrides, true);

      desId = designId;

      if (duplicateInfo) {
        const resp = await this.showDuplicatedSequences(
          exampleDesignJson,
          results,
          duplicateInfo
        );

        if (resp) return resp;
      }
    } else {
      // Create the default design if no file or template is uploaded.
      const { designId } = await performOldStyleMutations({
        mutations: this.isDesignTemplate
          ? defaultDesignTemplate()
          : defaultDesign(),
        designValuesOverride: overrides
      });
      desId = designId;
    }

    return desId;
  };

  /**
   * Show dialog window with duplicated sequences
   * @param {*} file
   * @param {*} results
   * @param {*} duplicateInfo
   */
  showDuplicatedSequences = (file, results, duplicateInfo) =>
    new Promise((resolve, reject) => {
      duplicateInfo = omitBy(duplicateInfo, dupSeqsForHash => {
        // There are different types of duplicate events
        // Loop through all and sum up the total number of duplicates
        const dupCount = reduce(
          Object.values(dupSeqsForHash),
          (acc, val = []) => {
            return val.length + acc;
          },
          0
        );
        // if there are no duplicates then omit the record
        return dupCount < 1;
      });

      // no duplicates
      if (!Object.keys(duplicateInfo).length) return resolve(null);

      showStackedDialog({
        ModalComponent: DuplicateSequenceDialog,
        modalProps: {
          sequences: results,
          duplicateInfo,
          reject,
          shouldNotHideModal: false,
          // user cancel duplicates import
          resolve: () => resolve(CANCELED_FLAG),
          onSubmit: items =>
            this.prepareSwapOutDesign(file, items, duplicateInfo, resolve)
        }
      });
    });

  /**
   * Swap-out unselected sequences from design.
   * Delete unselected from sequence file,
   * Change sequenceId on parts, sequenceFeatures & sequenceFragments
   * @param {*} file
   * @param {*} items
   * @param {*} duplicatedInfo
   * @param {*} resolve
   */
  prepareSwapOutDesign = async (file, items, duplicatedInfo, resolve) => {
    const { unselected } = items;
    const dupInfo = { ...duplicatedInfo };
    const unselectedMap = keyBy(unselected, "hash");
    const dupDbSeq = {};

    for (const u in unselectedMap) {
      const seq = dupInfo[u].dbSequence;
      for (const dbSeq of seq) {
        if (!dupDbSeq[dbSeq.hash]) dupDbSeq[dbSeq.hash] = dbSeq;
      }
    }
    if (Object.keys(dupDbSeq).length) {
      const fullSeqs = await safeQuery(duplicatedSequenceInDesignFragment, {
        isPlural: true,
        variables: { filter: { id: Object.values(dupDbSeq).map(s => s.id) } }
      });
      await safeUpsert(
        "sequence",
        fullSeqs.map(s => {
          return {
            id: s.id,
            updatedAt: new Date()
          };
        }),
        {
          ignoreResults: true
        }
      );
      swapDuplicatedSequencesInDesign(file, keyBy(fullSeqs, "hash"));
    }

    const { designId } = await importDesignFromHdeJson(file, {}, false);
    // return design id
    return resolve(designId);
  };
}
