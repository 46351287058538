/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const localhost =
  process.env.HASURA_HOST || process.env.APP_PROXY_HOST || "localhost";
const hasuraPort = process.env.HASURA_PORT || 8080;

const HASURA_ENDPOINTS = () => ({
  hasuraEndpoints: {
    METADATA: "v1/metadata",
    GRAPHQL: "v1/graphql",
    REWRITTEN_GRAPHQL: "tg/v1/graphql", // This route is used to apply the gql rewriter middleware for tg formatted queries.
    // https://hasura.io/docs/latest/api-reference/index/#schema-api
    SCHEMA: "v2/query",
    CONFIG: "v1alpha1/config",
    VERSION: "v1/version",
    CONSOLE: "console",
    // https://hasura.io/docs/latest/graphql/core/api-reference/health/
    HEALTH: "/hasura/healthz",
    ACTIONS: "actions"
  },
  hasuraServerUrl:
    process.env.HASURA_SERVER_URL || `http://${localhost}:${hasuraPort}`,
  hasuraGraphQlAdminSecret: process.env.HASURA_GRAPHQL_ADMIN_SECRET || "admin",
  tgHasuraPath: "hasura",
  actions: { CURRENT_USER_ID: "/current-user-id" }
});

export { HASURA_ENDPOINTS };
