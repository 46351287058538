/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createNotification } from "../../src-shared/utils/createNotification";

export const uploadFiles = async files => {
  const formData = new FormData();
  files.forEach(f => formData.append("file[]", f));
  return await window.serverApi.request({
    url: "/uploadFiles",
    data: formData
  });
};

export const uploadFilesToS3 = async files => {
  const formData = new FormData();
  files.forEach(f => formData.append("file[]", f));
  return await window.serverApi.post("/uploadFiles", formData);
};

/**
 * Return the product of the values of an array.
 * @param {Array<number>} a
 */
export function prod(a) {
  return a.reduce((p, x) => p * x, 1);
}

export function upsertDesignApprovalNotification({
  userId,
  stageName,
  designName,
  commentId,
  extraMessage,
  designId,
  activeSubmissionId
}) {
  return createNotification({
    userId,
    message: `${window.localStorage.getItem(
      "username"
    )} requested approval for ${stageName} on design ${designName}. ${extraMessage}`,
    notificationIntent: "primary",
    notificationTypeCode: "DESIGN_APPROVAL_REQUESTED",
    link: `/designs/${designId}?panel=reviews&submissionId=${activeSubmissionId}&commentId=${commentId}`
  });
}

// console.time('a')
// let a = []
// for(let i=0;i<1000;i++) {
//   a.push(guid())
// }
// console.timeEnd('a')
// console.time('a')
// a = []
// for(let i=0;i<1000;i++) {
//   a.push(guid())
// }
// console.timeEnd('a')
// console.time('a')
// a = []
// for(let i=0;i<1000;i++) {
//   a.push(guid())
// }
// console.timeEnd('a')

// let _uuid = 0
// const uuid = () => '@' + (_uuid++)
// console.time('a')
// a = []
// for(let i=0;i<1000;i++) {
//   a.push(uuid())
// }
// console.timeEnd('a')
// console.time('a')
// a = []
// for(let i=0;i<1000;i++) {
//   a.push(uuid())
// }
// console.timeEnd('a')
// console.time('a')
// a = []
// for(let i=0;i<1000;i++) {
//   a.push(uuid())
// }
// console.timeEnd('a')
