/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { MenuItem } from "@blueprintjs/core";
import React from "react";
import { safeUpsert } from "../../../src-shared/apolloMethods";

function ExternalAvailabilityMenuItem({ records, skipOuter, refetch }) {
  const inner = (
    <React.Fragment>
      <MenuItem
        text="Mark as Available"
        onClick={async () => {
          try {
            const recordsToUpdate = records.map(r => ({
              id: r.id,
              externalAvailability: true
            }));
            await safeUpsert("material", recordsToUpdate);
            if (refetch) await refetch();
            window.toastr.success(
              "Successfully marked materials as externally available"
            );
          } catch (e) {
            console.error(e);
            window.toastr.error(
              "Error marking materials as externally available"
            );
          }
        }}
      />
      <MenuItem
        text="Mark as Unavailable"
        onClick={async () => {
          try {
            const recordsToUpdate = records.map(r => ({
              id: r.id,
              externalAvailability: false
            }));
            await safeUpsert("material", recordsToUpdate);
            if (refetch) await refetch();
            window.toastr.success(
              "Successfully marked materials as externally unavailable"
            );
          } catch (e) {
            console.error(e);
            window.toastr.error(
              "Error marking materials as externally unavailable"
            );
          }
        }}
      />
    </React.Fragment>
  );
  if (skipOuter) return inner;
  return <MenuItem text="External Availability">{inner}</MenuItem>;
}

export default ExternalAvailabilityMenuItem;
