/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function getRequestTracker(): {
  key: string;
  type: string;
  timestamp: number;
}[] {
  if (!window.localStorage.getItem("networkRequestTracker")) {
    window.localStorage.setItem("networkRequestTracker", JSON.stringify([]));
  }
  return JSON.parse(
    window.localStorage.getItem("networkRequestTracker") as string
  );
}

export function getRequestCount() {
  return getRequestTracker().length;
}

export function logTrackedRequests(...args: any[]) {
  if (window.frontEndConfig && window.frontEndConfig.logTrackedRequests) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

export function addPendingRequest(key: string, type: string) {
  const trackedRequests = getRequestTracker();
  const beforeLen = trackedRequests.length;
  trackedRequests.push({ key, type, timestamp: Date.now() });
  logTrackedRequests(
    `[REQUEST TRACKER] Adding pending request for ${type}, request count: ${beforeLen} -> ${trackedRequests.length}, for key: ${key}`
  );
  window.localStorage.setItem(
    "networkRequestTracker",
    JSON.stringify(trackedRequests)
  );
}

export function cancelPendingRequest(key: string, type: string) {
  let trackedRequests = getRequestTracker();
  const beforeLen = trackedRequests.length;

  trackedRequests = trackedRequests.filter(req => {
    if (req.key === key && req.type === type) {
      const elapsed = `${Date.now() - req.timestamp} ms`;
      logTrackedRequests(
        `[REQUEST TRACKER] Canceling pending request for ${type}, key: ${key}, elapsed time: ${elapsed}`
      );
      return false;
    }
    return true;
  });
  logTrackedRequests(
    `[REQUEST TRACKER] Cancelled pending request for ${type}, request count: ${beforeLen} -> ${trackedRequests.length}, for key: ${key}`
  );
  window.localStorage.setItem(
    "networkRequestTracker",
    JSON.stringify(trackedRequests)
  );
}

export function clearPendingRequests() {
  window.localStorage.setItem("networkRequestTracker", JSON.stringify([]));
}
