/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { Menu, MenuItem, Tooltip, Position, Divider } from "@blueprintjs/core";
import Promise, { mapSeries } from "bluebird";
import {
  createDynamicMenu,
  commandMenuEnhancer,
  InfoHelper,
  wrapDialog
} from "@teselagen/ui";
import createBin from "../../../utils/createBin";
import forcedAssemblyStrategies from "../../../../../tg-iso-design/constants/forcedAssemblyStrategies";
import { getInputCardById } from "../../../../src-shared/selectors/classicViewSelectors";
import {
  designPartFragment,
  // designPartFragment,
  designPartsetFragment
} from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import {
  getAllOfType,
  getEliminatedCombinationGroupsOfCard,
  getItemOfType
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import TooltipMessages from "../../../../src-shared/constants/tooltips";
import { get, keyBy } from "lodash";
import handleAssemblePartsInThisBinAsIs from "../ContextMenuHandlers/handleAssemblePartsInThisBinAsIs";
import handleInsertPart from "../ContextMenuHandlers/handleInsertPart";
import { hideDialog, showDialog } from "../../../../src-shared/GlobalDialog";
import handleInsertCompatiblePart from "../ContextMenuHandlers/handleInsertCompatiblePart";
import { sortBy } from "lodash";
import InsertSequenceFromExternalDbMenuItem from "../InsertSequenceFromExternalDbMenuItem";
import { NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS } from "../../../constants/misc";
import { openInNewTab } from "../../../../src-shared/utils/generalUtils";
import InventorySearchMenuItem from "../DesignElementCard/InventorySearch";
import InsertPartsFromSequenceDigestDialogContainer from "../../../containers/InsertPartsFromSequenceDigestDialogContainer";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { reduxForm } from "redux-form";
import { createPartsFromSeqsAndInsertThemIntoDesign } from "../ContextMenuHandlers/handleInsertSequenceFromExternalDbClick";
import pluralIfNeeded from "../../../../../tg-iso-shared/src/utils/pluralIfNeeded";
import { ReverseTranslationDialog } from "../../../../src-shared/ReverseTranslation";
import shortid from "shortid";
import sequenceStringToFragments from "../../../../../tg-iso-shared/src/sequence-import-utils/sequenceStringToFragments";

const checkConsecutive = selectedCellPaths => {
  const filterArr = selectedCellPaths.sort((a, b) => a - b);
  const indexes = [];
  for (let i = 1; i < filterArr.length; i++) {
    if (
      (filterArr[i - 1] + filterArr[i + 1]) / 2 === filterArr[i] &&
      Math.abs(filterArr[i] - filterArr[i - 1]) === 1
    ) {
      indexes.push(i - 1);
    }
  }
  if (indexes.length > 0) {
    return true;
  } else {
    return false;
  }
};

const GridContextMenu = ({
  alphabetizeParts,
  assemblyMethodOfCard,
  bins,
  changeFas,
  createElements,
  editorContext,
  eliminateCombinations,
  firstNextPartId,
  firstPreviousPartId,
  insertBin,
  insertRow,
  multi,
  nextArfPartId,
  previousArfPartId,
  reactionRestrictionEnzyme,
  removeBins,
  removeElements,
  removeEugeneRule,
  removeRows,
  selectedAssemblyMethod,
  selectedBin,
  selectedBinIds,
  selectedBinOverhangs,
  selectedCardId,
  selectedCellPaths,
  selectedCellsWithElements,
  selectedElement,
  selectedElementFas,
  selectedElementsWithAminoAcidPartIds,
  selectedInputCards,
  selectionHasAllDsfs,
  selectionHasAtLeastOneDsf,
  selectionHasAtLeastOneElement,
  selectionHasMultipleColumns,
  selectionHasMultipleDsfs,
  selectionHasMultipleElements,
  selectionHasMultipleNonDsfs,
  selectionHasMultipleRows,
  setDsf,
  setOfSelectedElement,
  state,
  updateBin
}) => {
  const handleDirectSynthesisFirewallClick = useCallback(
    dsf => () => {
      setDsf({ cardIds: selectedInputCards.map(c => c.id), dsf });
    },
    [selectedInputCards, setDsf]
  );

  const handleFasClick = useCallback(
    fas => () => {
      const cardIdToElementIdsMap = selectedCellPaths.reduce((acc, c) => {
        if (!c.elementId) return acc;
        const card = getInputCardById(state, c.binId);
        if (!acc[card.id]) acc[card.id] = [];
        acc[card.id].push(c.elementId);
        return acc;
      }, {});
      changeFas({ fas, cardIdToElementIdsMap });
    },
    [changeFas, selectedCellPaths, state]
  );

  const handleAddEugeneRule = useCallback(() => {
    showDialog({
      modalType: "ADD_EUGENE_RULE",
      modalProps: {
        cardId: getInputCardById(state, setOfSelectedElement.id).id
      }
    });
  }, [setOfSelectedElement?.id, state]);

  const handleRemoveEugeneRule = useCallback(async () => {
    const selectedElements = [];
    const selectedElementsMap = keyBy(selectedCellPaths, "elementId");
    selectedCellPaths.forEach(cell => {
      if (cell.elementId) {
        selectedElements.push(cell.elementId);
      }
    });

    const eugeneRuleElements = getAllOfType(state, "eugeneRuleElement");
    const selectedEugeneRuleMap = Object.values(eugeneRuleElements).reduce(
      (acc, eugRuleElement) => {
        if (!selectedElementsMap[eugRuleElement.elementId]) return acc;
        if (eugRuleElement.eugeneRule1Id) {
          acc[eugRuleElement.eugeneRule1Id] = true;
        }
        if (eugRuleElement.eugeneRule2Id) {
          acc[eugRuleElement.eugeneRule2Id] = true;
        }
        return acc;
      },
      {}
    );

    await Promise.mapSeries(Object.keys(selectedEugeneRuleMap), erId =>
      removeEugeneRule(erId)
    );
  }, [removeEugeneRule, selectedCellPaths, state]);

  const handleInsertPartSetClick = useCallback(() => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;
    showDialog({
      modalType: "PARTSET_LIBRARY",
      modalProps: {
        onSubmit: defaultAsyncWrap(async partsets => {
          const elementIdsToDelete = selectedCellPaths.reduce((acc, cp) => {
            if (cp.elementId) acc.push(cp.elementId);
            return acc;
          }, []);
          const fullPartsets = await safeQuery(designPartsetFragment, {
            isPlural: true,
            variables: { filter: { id: partsets.map(p => p.id) } }
          });
          createElements({
            binId,
            values: fullPartsets.map(partset => ({ partset })),
            elementIdsToDelete,
            cellIndex: selectedCellPath.index || 0
          });
        }, "Error inserting part set(s).")
      }
    });
  }, [createElements, selectedBin?.id, selectedCellPaths]);

  const handleInsertUnmappedElementClick = useCallback(() => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;

    showDialog({
      modalType: "ADD_UNMAPPED_ELEMENT",
      modalProps: {
        onSubmit: values => {
          createElements({
            binId,
            values: {
              name: values.name
            },
            elementIdsToDelete: selectedCellPaths.reduce((acc, cp) => {
              if (cp.elementId) acc.push(cp.elementId);
              return acc;
            }, []),
            cellIndex: selectedCellPath.index || 0
          });
        }
      }
    });
  }, [createElements, selectedBin?.id, selectedCellPaths]);

  const handleInsertEmptyPartClick = useCallback(() => {
    selectedCellPaths.forEach(selectedCellPath => {
      createElements({
        binId: selectedCellPath.binId || selectedBin.id,
        values: {
          name: "EMPTY",
          isEmpty: true
        },
        elementIdsToDelete: selectedCellPaths.reduce((acc, cp) => {
          if (cp.elementId) acc.push(cp.elementId);
          return acc;
        }, []),
        cellIndex: selectedCellPath.index || 0
      });
    });
  }, [createElements, selectedBin?.id, selectedCellPaths]);

  const handleInsertAminoAcidPartClick = useCallback(() => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;

    showDialog({
      modalType: "AA_PART_LIBRARY",
      modalProps: {
        onSubmit: aaParts => {
          createElements({
            binId,
            values: aaParts.map(aaPart => ({
              name: aaPart.name,
              aminoAcidPart: aaPart
            })),
            elementIdsToDelete: selectedCellPaths.reduce((acc, cp) => {
              if (cp.elementId) acc.push(cp.elementId);
              return acc;
            }, []),
            cellIndex: selectedCellPath.index || 0
          });
        }
      }
    });
  }, [createElements, selectedBin?.id, selectedCellPaths]);

  const fasSubmenu = useMemo(
    () => (
      <MenuItem
        key="fas"
        text="Forced Assembly Strategy"
        disabled={!selectionHasAtLeastOneElement}
      >
        {forcedAssemblyStrategies.map(fas => (
          <MenuItem
            key={fas}
            text={fas.replace(/_/g, " ")}
            onClick={handleFasClick(fas)}
          />
        ))}
      </MenuItem>
    ),
    [handleFasClick, selectionHasAtLeastOneElement]
  );

  const eugeneRulesSubmenu = useMemo(() => {
    if (!selectedElement) {
      return null;
    }

    return (
      <MenuItem
        key="eugeneRules"
        text="Eugene Rules"
        disabled={selectedElement.isEmpty}
      >
        <MenuItem key="addRule" text="Add Rule" onClick={handleAddEugeneRule} />
        <MenuItem
          key="removeRule"
          text="Remove Rule(s)"
          onClick={handleRemoveEugeneRule}
        />
      </MenuItem>
    );
  }, [handleAddEugeneRule, handleRemoveEugeneRule, selectedElement]);

  const handleInsertBasePairLiteralClick = useCallback(() => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;
    showDialog({
      modalType: "INSERT_BP_LITERAL",
      modalProps: {
        onSubmit: values => {
          createElements({
            binId,
            values: {
              name: values.name,
              bps: values.bps
            },
            elementIdsToDelete: selectedCellPaths.reduce((acc, cp) => {
              if (cp.elementId) acc.push(cp.elementId);
              return acc;
            }, []),
            cellIndex: selectedCellPath.index || 0
          });
        }
      }
    });
  }, [createElements, selectedBin?.id, selectedCellPaths]);

  /**
   * NOTE: looks like this function is almost duplicated with the one in
   * 'DesignElementCardContextMenu'. There a few differences that we need to look into.
   * For example, here the 'selectedCardId' is the Root Card of a non-hierarchical design,
   * And the actual cardId is obtained from 'selectedInputCards[0].id'.
   *
   * However, for hierarchical designs (which btw use the 'DesignElementCardContextMenu' component instead),
   * the cardId is obtained from 'selectedCardId', and the 'selectedInputCards' is an array of undefined!
   */
  const handleInsertPartClick = useCallback(async () => {
    const binIds = selectedBinIds;

    const arfPartData = await safeQuery(
      ["part", "id name bpsUpStream bpsDownStream bps5Prime bps3Prime"],
      {
        isPlural: true,
        variables: {
          filter: {
            id: [
              previousArfPartId,
              nextArfPartId,
              firstPreviousPartId,
              firstNextPartId
            ].filter(id => !!id)
          }
        }
      }
    );

    showDialog({
      modalType: "PART_LIBRARY",
      modalProps: {
        basePairLiteralOption: true,
        firstPreviousPart: arfPartData.find(
          data => data.id === firstPreviousPartId
        ),
        firstNextPart: arfPartData.find(data => data.id === firstNextPartId),
        previousArfPart: arfPartData.find(
          data => data.id === previousArfPartId
        ),
        nextArfPart: arfPartData.find(data => data.id === nextArfPartId),
        binIds,
        binId: binIds.length === 1 ? binIds[0] : undefined,
        cardId: selectedCardId,
        onSubmit: async (
          parts,
          { validateCompatibleParts, isBpl, adjacentBinHasArf }
        ) => {
          await handleInsertPart({
            parts,
            isBpl,
            selectedCellPaths,
            cardId: selectedInputCards[0].id,
            createElements
          });

          if (validateCompatibleParts && !adjacentBinHasArf) {
            // adjust the FRO and extraCPECBps of this bin and previous bin
            handleInsertCompatiblePart(
              state,
              selectedBinIds[0],
              selectedInputCards[0],
              updateBin
            );

            // apply FAS of Assembly Ready Fragment to the newly-created element
            const elements = await safeQuery(["element", "id name"], {
              variables: {
                filter: {
                  name: parts[0].name,
                  designId: Object.keys(state.design.design)[0]
                }
              }
            });
            changeFas({
              fas: "Assembly Ready Fragment",
              cardId: selectedInputCards[0].id,
              elementId: sortBy(elements, "id")[elements.length - 1].id
            });
          }
        }
      }
    });
  }, [
    changeFas,
    createElements,
    firstNextPartId,
    firstPreviousPartId,
    nextArfPartId,
    previousArfPartId,
    selectedBinIds,
    selectedCardId,
    selectedCellPaths,
    selectedInputCards,
    state,
    updateBin
  ]);

  /**
   * NOTE: looks like this function is almost duplicated with the one in
   * 'DesignElementCardContextMenu'. There a few differences that we need to look into.
   * For example, here the 'selectedCardId' is the Root Card of a non-hierarchical design,
   * And the actual cardId is obtained from 'selectedInputCards[0].id'.
   *
   * However, for hierarchical designs (which btw use the 'DesignElementCardContextMenu' component instead),
   * the cardId is obtained from 'selectedCardId', and the 'selectedInputCards' is an array of undefined!
   */
  const handleInsertSequenceClick = useCallback(async () => {
    showDialog({
      ModalComponent: InsertSequenceModal,
      modalProps: {
        onSubmit: async sequences => {
          await createPartsFromSeqsAndInsertThemIntoDesign({
            cardId: selectedInputCards[0].id,
            sequences,
            selectedCellPaths,
            createElements
          });
        }
      }
    });
  }, [createElements, selectedCellPaths, selectedInputCards]);

  const handleCreateConstructAnnotation = useCallback(
    annotationType => () => {
      showDialog({
        modalType: "CREATE_CONSTRUCT_ANNOTATION",
        modalProps: {
          cardId: selectedCardId,
          leftBoundaryBinId: selectedBinIds[0],
          rightBoundaryBinId: selectedBinIds[selectedBinIds.length - 1],
          bins,
          annotationType
        }
      });
    },
    [bins, selectedBinIds, selectedCardId]
  );

  const combinationsSubmenu = useMemo(() => {
    const hasEliminatedCombinations = !!getEliminatedCombinationGroupsOfCard(
      state,
      selectedCardId
    ).length;

    return (
      <MenuItem key="combinations" text="Combinations">
        <MenuItem
          key="viewEliminatedCombinations"
          text="View Eliminated Combinations"
          disabled={!hasEliminatedCombinations}
          onClick={() =>
            showDialog({ modalType: "VIEW_ELIMINATED_COMBINATIONS" })
          }
        />
        <MenuItem
          key="eliminateTheseCombinations"
          text="Eliminate These Combinations"
          disabled={!selectedCellsWithElements.length}
          onClick={eliminateCombinations}
        />
      </MenuItem>
    );
  }, [eliminateCombinations, selectedCardId, selectedCellsWithElements, state]);

  const insertMenuItems = useMemo(() => {
    const emptyPartMenuItem = (
      <MenuItem
        key="insertEmptyPart"
        text="Insert Empty Part"
        onClick={handleInsertEmptyPartClick}
      />
    );

    const insertPartsFromSeqDigestMenuItem = (
      <MenuItem
        key="insertPartFromSequenceDigest"
        text="Insert Part(s) from Sequence Digest"
        onClick={() => {
          showDialog({
            ModalComponent: InsertPartsFromSequenceDigestDialogContainer,
            modalProps: { selectedCellPaths, createElements, selectedBinIds }
          });
        }}
      />
    );

    const insertMenuItems = [];

    if (selectedCellPaths.length > 1) {
      insertMenuItems.push(
        <MenuItem
          key="insertPart"
          text="Insert Parts"
          onClick={handleInsertPartClick}
        />,
        <MenuItem
          key="insertSequences"
          text="Insert Sequences"
          onClick={handleInsertSequenceClick}
        />,
        emptyPartMenuItem,
        insertPartsFromSeqDigestMenuItem
      );
    }

    if (selectedCellPaths.length === 1)
      insertMenuItems.push(
        <MenuItem
          key="insertPart"
          text="Insert Part"
          onClick={handleInsertPartClick}
        />,
        <MenuItem
          key="insertSequences"
          text="Insert Sequence"
          onClick={handleInsertSequenceClick}
        />,
        <InsertSequenceFromExternalDbMenuItem
          multi={multi}
          selectedInputCards={selectedInputCards}
          selectedCellPaths={selectedCellPaths}
          createElements={createElements}
          key="insertSequenceFromExternalDb"
        />,
        <MenuItem
          key="insertBasePairLiteral"
          text="Insert Base Pairs"
          onClick={handleInsertBasePairLiteralClick}
        />,
        <MenuItem
          key="insertPartset"
          text="Insert Partset"
          onClick={handleInsertPartSetClick}
        />,
        <MenuItem
          key="insertUnmappedPart"
          text="Insert Unmapped Part"
          onClick={handleInsertUnmappedElementClick}
        />,
        <MenuItem
          key="insertAminoAcidPart"
          text="Insert Amino Acid Part"
          onClick={handleInsertAminoAcidPartClick}
        />,
        insertPartsFromSeqDigestMenuItem,
        emptyPartMenuItem,
        <Divider />
      );

    if (
      selectedCellPaths.length > 1 &&
      checkConsecutive(selectedCellPaths.map(value => value.index))
    ) {
      insertMenuItems.push(
        <MenuItem
          key="insertRowAboveAll"
          text="Insert Row Above every item selected"
          onClick={() => insertRow({ isAbove: true })}
        />,
        <MenuItem
          key="insertRowBellowAll"
          text="Insert Row below every item selected"
          onClick={() => insertRow({ isAbove: false })}
        />
      );
    } else {
      insertMenuItems.push(
        <MenuItem
          key="insertRowAbove"
          text="Insert Row Above"
          disabled={!selectedCellPaths.length}
          onClick={() => insertRow({ isAbove: true })}
        />,
        <MenuItem
          key="insertRowBelow"
          text="Insert Row Below"
          disabled={!selectedCellPaths.length}
          onClick={() => insertRow({ isAbove: false })}
        />
      );
    }

    insertMenuItems.push(
      <MenuItem
        key="insertColumnLeft"
        text="Insert Bin Left"
        onClick={() =>
          createBin({ selectedCardId, selectedBinIds, insertBin }, true)
        }
      />,
      <MenuItem
        key="insertColumnRight"
        text="Insert Bin Right"
        onClick={() =>
          createBin({ selectedCardId, selectedBinIds, insertBin }, false)
        }
      />,
      <MenuItem
        key="insertDsf"
        disabled={selectionHasAllDsfs}
        text={`Insert Direct Synthesis Firewall${
          selectionHasMultipleNonDsfs ? "s" : ""
        }`}
        onClick={handleDirectSynthesisFirewallClick(true)}
      />
    );
    return insertMenuItems;
  }, [
    createElements,
    handleDirectSynthesisFirewallClick,
    handleInsertAminoAcidPartClick,
    handleInsertBasePairLiteralClick,
    handleInsertEmptyPartClick,
    handleInsertPartClick,
    handleInsertPartSetClick,
    handleInsertSequenceClick,
    handleInsertUnmappedElementClick,
    insertBin,
    insertRow,
    multi,
    selectedBinIds,
    selectedCardId,
    selectedCellPaths,
    selectedInputCards,
    selectionHasAllDsfs,
    selectionHasMultipleNonDsfs
  ]);

  const removeMenuItems = useMemo(
    () => [
      <MenuItem
        key="removeElements"
        disabled={!selectionHasAtLeastOneElement}
        text={selectionHasMultipleElements ? "Remove Parts" : "Remove Part"}
        onClick={() => {
          removeElements(
            selectedCellPaths.filter(c => c.elementId).map(c => c.elementId)
          );
        }}
      />,
      <MenuItem
        key="removeRows"
        text={selectionHasMultipleRows ? "Remove Rows" : "Remove Row"}
        disabled={!selectedCellPaths.length}
        onClick={removeRows}
      />,
      <MenuItem
        key="removeColumns"
        text={selectionHasMultipleColumns ? "Remove Bins" : "Remove Bin"}
        onClick={() => {
          removeBins({
            cardId: selectedCardId,
            binIds: selectedBinIds
          });
        }}
      />,
      <MenuItem
        key="removeDsf"
        disabled={!selectionHasAtLeastOneDsf}
        text={`Remove Direct Synthesis Firewall${
          selectionHasMultipleDsfs ? "s" : ""
        }`}
        onClick={handleDirectSynthesisFirewallClick(false)}
      />
    ],
    [
      handleDirectSynthesisFirewallClick,
      removeBins,
      removeElements,
      removeRows,
      selectedBinIds,
      selectedCardId,
      selectedCellPaths,
      selectionHasAtLeastOneDsf,
      selectionHasAtLeastOneElement,
      selectionHasMultipleColumns,
      selectionHasMultipleDsfs,
      selectionHasMultipleElements,
      selectionHasMultipleRows
    ]
  );

  const miscMenuItems = useMemo(() => {
    const miscMenuItems = [
      <MenuItem
        key="alphabetizeElements"
        text="Alphabetize Parts In Bin"
        onClick={alphabetizeParts}
      />
    ];

    if (selectedAssemblyMethod.name === "Gibson/SLIC/CPEC") {
      miscMenuItems.push(
        <MenuItem
          key="assemblePartsInThisBinAsIs"
          text={
            <Tooltip
              content={
                <span>{TooltipMessages.assemblePartsInThisBinAsIs}</span>
              }
              position={Position.RIGHT}
            >
              Assemble Parts in this Bin As-Is
            </Tooltip>
          }
          onClick={() => {
            handleAssemblePartsInThisBinAsIs(
              state,
              selectedBinIds,
              selectedInputCards,
              updateBin
            );
          }}
        />
      );
    }
    if (selectedElementsWithAminoAcidPartIds.length) {
      miscMenuItems.push(
        <MenuItem
          key="reverseTranslateSelectedAa"
          text={`Reverse Translate Selected AA ${pluralIfNeeded(
            "Part",
            selectedElementsWithAminoAcidPartIds
          )}`}
          onClick={async () => {
            //preprocessing to get the AA sequence that will be rev-translated
            const fullAAParts = await safeQuery(
              [
                "aminoAcidPart",
                "id name start end aminoAcidSequence { id proteinSequence }"
              ],
              {
                isPlural: true,
                variables: {
                  filter: {
                    id: selectedElementsWithAminoAcidPartIds.map(
                      el => el.aminoAcidPartId
                    )
                  }
                }
              }
            );

            const aaSeqsToRevTrans = fullAAParts.map(aaPart => {
              return {
                aminoAcidSequence: aaPart,
                aaPart,
                proteinSequence: aaPart.aminoAcidSequence.proteinSequence
                  .slice(aaPart.start / 3, (aaPart.end + 1) / 3)
                  .toUpperCase()
              };
            });

            //pass the aa seqs to the rev translation dialog
            showDialog({
              ModalComponent: ReverseTranslationDialog,
              modalProps: {
                dialogProps: {
                  title: "Reverse Translate AA Parts"
                },
                numToRevTrans: selectedElementsWithAminoAcidPartIds.length,
                //handle the result of reverse-translation
                onRevTransFinished: async (
                  translatedSeqs,
                  { translationInfo }
                ) => {
                  await mapSeries(
                    translatedSeqs,
                    async ({ reverseTranslatedDna, aaPart }) => {
                      const seqCid = shortid();
                      const des = `Derived from amino acid part '${aaPart.name}' using reverse-translator ${translationInfo.name} ${translationInfo.description}`;

                      await safeUpsert("sequence", {
                        cid: seqCid,
                        name: aaPart.name,
                        description: des,
                        sequenceTypeCode: "LINEAR_DNA",
                        sequenceFragments: sequenceStringToFragments(
                          reverseTranslatedDna
                        )
                      });

                      const upsertedPartData = await safeUpsert("part", {
                        sequenceId: "&" + seqCid,
                        name: aaPart.name,
                        notes: { Origin: [des] },
                        strand: 1,
                        start: 0,
                        end: reverseTranslatedDna.length - 1
                      });

                      const dnaPart = upsertedPartData[0];

                      const fullPart = await safeQuery(designPartFragment, {
                        variables: { filter: { id: dnaPart.id } }
                      });

                      const aaEls = selectedElementsWithAminoAcidPartIds.filter(
                        aae => aae.aminoAcidPartId === aaPart.id
                      );

                      aaEls.forEach(aaEl => {
                        createElements({
                          binId: aaEl.binId,
                          values: [
                            {
                              name: fullPart[0].name,
                              part: fullPart[0],
                              notes: des
                            }
                          ],
                          cellIndex: aaEl.index
                        });
                      });
                    }
                  );
                },
                aaSeqsToRevTrans
              }
            });
          }}
        />
      );
    }
    return miscMenuItems;
  }, [
    alphabetizeParts,
    createElements,
    selectedAssemblyMethod?.name,
    selectedBinIds,
    selectedElementsWithAminoAcidPartIds,
    selectedInputCards,
    state,
    updateBin
  ]);

  const menuItems = useMemo(() => {
    const menuItems = [
      <MenuItem key="insert" text="Insert" disabled={!insertMenuItems.length}>
        {insertMenuItems}
      </MenuItem>,
      // TODO migrate all items to use commands like this
      ...createDynamicMenu(
        ["cutPart", "copyPart", "pastePart"],
        [
          commandMenuEnhancer(editorContext.commands, {
            forceIconAlignment: false
          })
        ]
      ),
      ...miscMenuItems,
      <MenuItem key="remove" text="Remove" disabled={!removeMenuItems.length}>
        {removeMenuItems}
      </MenuItem>,
      combinationsSubmenu,
      fasSubmenu,
      eugeneRulesSubmenu,
      // TODO: Inventory search enabled for single element selection only atm.
      ...(selectedCellPaths.length === 1
        ? [
            InventorySearchMenuItem({
              selectionHasAtLeastOneElement,
              selectedElement,
              selectedElementFas,
              selectedBinOverhangs,
              selectedCellPaths,
              selectedInputCards,
              createElements,
              changeFas,
              reactionRestrictionEnzyme
            })
          ]
        : []),
      ...(selectionHasAtLeastOneElement
        ? [
            <MenuItem
              key="viewParts"
              icon="applications"
              text={
                <div style={{ display: "flex" }}>
                  {`View Part${
                    selectionHasMultipleElements ? "s" : ""
                  } in New Tab${selectionHasMultipleElements ? "s" : ""}`}
                  {selectionHasMultipleElements ? (
                    <InfoHelper
                      content="If you only see a single tab being created, you may see a warning in the address bar that pop-ups were blocked."
                      style={{ marginLeft: 10 }}
                    ></InfoHelper>
                  ) : null}
                </div>
              }
              onClick={() => {
                selectedCellPaths
                  .filter(c => c.elementId)
                  .map(c => {
                    const element = getItemOfType(
                      state,
                      "element",
                      c.elementId
                    );
                    const url = `parts/${element.partId}`;
                    return openInNewTab(url);
                  });
              }}
            />
          ]
        : [])
    ];

    if (
      selectedBinIds.length > 1 &&
      !NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS.includes(
        get(assemblyMethodOfCard, "name")
      )
    ) {
      menuItems.push(
        <MenuItem
          key="createConstructAnnotation"
          text="Create Construct Annotation"
        >
          <MenuItem
            key="createConstructPart"
            text="Part"
            onClick={handleCreateConstructAnnotation("part")}
          />
          <MenuItem
            key="createConstructFeature"
            text="Feature"
            onClick={handleCreateConstructAnnotation("feature")}
          />
        </MenuItem>
      );
    }

    if (
      selectedBinIds.length === 1 &&
      (window.frontEndConfig.atLanzatech || window.Cypress)
    ) {
      menuItems.push(
        <MenuItem
          key="sendBinToCrickit"
          text="Send Bin to Crickit"
          onClick={() => {
            showDialog({
              modalType: "SEND_TO_CRICKIT",
              modalProps: {
                binIds: selectedBinIds
              }
            });
          }}
        />
      );
    }
    if (
      (window.frontEndConfig.atLanzatech || window.Cypress) &&
      selectedCellsWithElements.length === 1
    ) {
      menuItems.push(
        <MenuItem
          key="sendPartsToCrickIT"
          text="Send Part to Crickit"
          onClick={async () => {
            showDialog({
              modalType: "SEND_TO_CRICKIT",
              modalProps: {
                selectedElement
              }
            });
          }}
        />
      );
    }
    return menuItems;
  }, [
    assemblyMethodOfCard,
    changeFas,
    combinationsSubmenu,
    createElements,
    editorContext?.commands,
    eugeneRulesSubmenu,
    fasSubmenu,
    handleCreateConstructAnnotation,
    insertMenuItems,
    miscMenuItems,
    reactionRestrictionEnzyme,
    removeMenuItems,
    selectedBinIds,
    selectedBinOverhangs,
    selectedCellPaths,
    selectedCellsWithElements?.length,
    selectedElement,
    selectedElementFas,
    selectedInputCards,
    selectionHasAtLeastOneElement,
    selectionHasMultipleElements,
    state
  ]);

  return <Menu>{menuItems}</Menu>;
};

export default GridContextMenu;

const InsertSequenceModal = reduxForm({ form: "insertSeqDialog" })(
  wrapDialog({ title: "Insert Sequence as Part" })(({ onSubmit }) => {
    return (
      <div>
        <GenericSelect
          name="seqToInsert"
          noDialog
          schema={[
            {
              path: "name"
            },
            {
              path: "size",
              type: "number"
            }
          ]}
          isMultiSelect
          fragment={["sequence", "id name size"]}
          onFieldSubmit={async seqs => {
            onSubmit(seqs);
            hideDialog();
          }}
        />
      </div>
    );
  })
);
