/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import {
  Tag,
  Button,
  Popover,
  Menu,
  MenuItem,
  Position
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Link } from "react-router-dom";
import { camelCase, get } from "lodash";
import { safeUpsert, safeQuery } from "../apolloMethods";
import modelNameToLink from "../utils/modelNameToLink";
import libraryEnhancer from "../libraryEnhancer";
import AbstractLibrary from "../AbstractLibrary";
import microserviceTaskLibraryBuildFragment from "../graphql/fragments/microserviceTaskLibraryBuildFragment";
import withQuery from "../withQuery";
import { compose } from "recompose";
import SharedMicroserviceTaskRecordView from "../SharedMicroserviceTaskRecordView";
import { isObject } from "lodash";

function onDoubleClick(record, row, history) {
  history.push(modelNameToLink(record.__typename, record.id));
}

class MicroserviceTaskLibrary extends Component {
  componentDidUpdate(oldProps) {
    this.handleRefetchOnUpdate(oldProps);
  }

  handleRefetchOnUpdate(oldProps) {
    const { latestUpdate: oldLatest } = oldProps;
    const { latestUpdate, refetch } = this.props;
    if (latestUpdate && latestUpdate !== oldLatest) {
      refetch();
    }
  }

  additionalContextMenu = selectedRecords => {
    const menuItems = [];
    if (
      selectedRecords.length === 1 &&
      !selectedRecords[0].status.includes("completed")
    ) {
      const record = selectedRecords[0];
      menuItems.push(
        <MenuItem
          key="cancel"
          icon="stop"
          onClick={async () => {
            const task = await safeQuery(["microserviceQueue", `id status`], {
              variables: {
                filter: {
                  id: record.id
                }
              }
            });
            if (!task[0].status.includes("completed")) {
              await safeUpsert("microserviceQueue", {
                id: record.id,
                status: "cancel"
              });
            }
            await this.props.refetch();
          }}
          text="Cancel Task"
        />
      );
    }
    return menuItems;
  };

  startExampleTask = async serviceName => {
    const { refetch } = this.props;
    const { data } = await window.serverApi.post("/executeExampleTask", {
      serviceName
    });

    if (data.success) {
      window.toastr.success("Submitted example task");
      refetch();
    } else {
      console.error("Error submitting example task", data);
      window.toastr.error("Error submitting example task");
    }
  };

  render() {
    const showExample = window.frontEndConfig.showExampleMicroserviceTask;
    return (
      <AbstractLibrary
        {...this.props}
        model="microserviceQueue"
        libraryName="microserviceTask"
        libraryTitle="Microservice Tasks"
        isLibraryTable
        noDelete
        noNewItem
        withExport
        RecordViewInspector={SharedMicroserviceTaskRecordView}
        onDoubleClick={onDoubleClick}
        generateExtraContextMenuItems={this.additionalContextMenu}
        additionalButtons={
          showExample && (
            <Popover
              content={
                <Menu>
                  <MenuItem
                    text="Start Xlsx to CSV Example"
                    onClick={() => this.startExampleTask("xlsx-to-csv")}
                    key="task1"
                  />
                  <MenuItem
                    key="task2"
                    onClick={() => this.startExampleTask("callback")}
                    text="Start Callback Example"
                  />
                  <MenuItem
                    key="task3"
                    onClick={() => this.startExampleTask("crickit-manager")}
                    text="Start Crickit Example"
                  />
                  <MenuItem
                    key="task4"
                    onClick={() => this.startExampleTask("homology-path")}
                    text="Start Homology Path Example"
                  />
                </Menu>
              }
              position={Position.BOTTOM}
            >
              <Button text="Example Tasks" rightIcon={IconNames.CARET_DOWN} />
            </Popover>
          )
        }
      />
    );
  }
}

const schema = {
  model: "microserviceQueue",
  fields: [
    {
      path: "input",
      type: "string",
      displayName: "Name",
      render(input, record) {
        if (input) {
          if (input.name) {
            return input.name;
          } else if (input.design_name) {
            // j5
            return input.design_name;
          } else if (input.crickitInput) {
            // crickit
            return input.crickitInput?.seqName;
          } else if (input.options && input.options.name) {
            // alignment
            return input.options.name;
          } else if (input.jobName) {
            // seq qc report
            const { microserviceIoFiles } = record;
            const fastqFileNames = [];
            microserviceIoFiles.forEach(f => {
              if (f?.destinationPath?.includes(".fastq")) {
                fastqFileNames.push(f.destinationPath);
              }
            });
            return fastqFileNames.join(", ");
          } else if (Array.isArray(input.sequences)) {
            // bioshop's vendor-scoring-and-pricing
            return input.sequences.map(seq => seq.name).join();
          } else if (input.config && input.config.vendor) {
            // bioshop's vendor-vector
            return input.config.vendor;
          } else if (
            input.homologyPathInput?.optional_parameters?.design_name
          ) {
            // homology path
            return input.homologyPathInput.optional_parameters.design_name;
          } else {
            return "";
          }
        }
      }
    },
    {
      path: "service",
      type: "string",
      displayName: "Service",
      render(service) {
        if (service) {
          return service.replace(/-/g, " ");
        }
      }
    },
    {
      path: "status",
      type: "string",
      displayName: "Status",
      render(status, record) {
        const { service, result } = record;
        if (
          service === "crickit-manager" &&
          isObject(result) &&
          result?.data?.status === "Failed"
        ) {
          status = "completed-failed";
        }
        const statusMap = {
          "in-progress": {
            description: "In progress",
            intent: "primary"
          },
          "completed-successfully": {
            description: "Completed",
            intent: "success"
          },
          "completed-cancelled": {
            description: "Canceled",
            intent: "warning"
          },
          "completed-failed": {
            description: "Failed",
            intent: "danger"
          }
        };
        // if status is not recognized
        if (!statusMap[status]) {
          return (
            <Tag style={{ marginRight: "5px" }} intent="primary">
              {status}
            </Tag>
          );
        } else {
          return (
            <Tag
              style={{ marginRight: "5px" }}
              intent={statusMap[status].intent}
            >
              {statusMap[status].description}
            </Tag>
          );
        }
      }
    }
  ]
};

const ioItemOutput = {
  path: "ioItem",
  type: "string",
  displayName: "Output",
  render(ioItem) {
    let item;
    if (ioItem) {
      if (ioItem.dataItem) {
        item = ioItem.dataItem[camelCase(ioItem.dataItem.dataItemTypeCode)];
      }
    }
    if (item) {
      return (
        <Link to={modelNameToLink(item.__typename, item.id)}>{item.name}</Link>
      );
    }
  }
};
schema.fields.push(ioItemOutput);

export default compose(
  libraryEnhancer({
    schema,
    noAddedBy: true,
    fragment: microserviceTaskLibraryBuildFragment
  }),
  withQuery(["microserviceQueue", "id updatedAt"], {
    isPlural: true,
    options: {
      pollInterval: 10000,
      variables: {
        pageSize: 1,
        sort: ["-updatedAt"]
      }
    },
    props: ({ microserviceQueues = [] }) => {
      const [latest] = microserviceQueues;
      return {
        latestUpdate: get(latest, "updatedAt")
      };
    }
  })
)(MicroserviceTaskLibrary);
