/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const getSequence = (
  sequence,
  { fragmentsKey = "sequenceFragments", upperCase } = {}
) => {
  if (sequence.noSequence) return undefined;
  let toRet;
  if (sequence.sequence) {
    toRet = sequence.sequence;
  } else if (sequence.fullSequenceRaw) {
    toRet = sequence.fullSequenceRaw;
  } else if (sequence.proteinSequence) {
    toRet = sequence.proteinSequence;
  } else {
    // Might need to address gaps in sequence segment starts in the future
    const fragments =
      sequence.sequenceFragments ||
      sequence.aminoAcidSequenceFragments ||
      sequence[fragmentsKey];
    if (fragments) {
      toRet = [...fragments]
        .sort((frag1, frag2) => frag1.index - frag2.index)
        .map(frag => {
          return frag.fragment;
        })
        .join("");
    } else if (sequence.fullSequence) {
      return sequence.fullSequence; //tnr: this will always be lower case so we prioritize fullSequenceRaw and using sequenceFragments over it
    } else {
      return undefined;
    }
  }
  if (upperCase) {
    return toRet.toUpperCase();
  }
  return toRet;
};
