/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import { forEach, mapKeys } from "lodash";
import { computeSequenceHash } from "../sequence-import-utils/utils";
import { checkForDuplicateSequences } from "../sequence-import-utils/checkDuplicateSequences";
import { deepObjectMapTransform } from "./deepObjectMapTransform";
import groupedTopoSort from "./handleSaveDesign/groupedTopoSort";
import executeDesignMutation from "./executeDesignMutation";
import { v4 as uuid } from "uuid";
import { getDesign } from "../../../tg-iso-design/selectors/designStateSelectors";

const getTopologicalDesign = (designJson, SIMPLE_REFERENCES_TO_TYPE) => {
  for (const designType of Object.keys(designJson)) {
    for (const item of Object.values(designJson[designType])) {
      designJson[designType][item.id] = {
        ...item,
        __typename: designType
      };
    }
  }

  if (designJson.j5OutputNamingTemplate) {
    let combOfAssemblyPiecesTemplateId = null;
    for (const j5OutputNamingTemplate of Object.values(
      designJson.j5OutputNamingTemplate
    )) {
      if (j5OutputNamingTemplate.outputTarget === "combOfAssemblyPieces") {
        combOfAssemblyPiecesTemplateId = j5OutputNamingTemplate.id;
      }
    }

    if (combOfAssemblyPiecesTemplateId) {
      delete designJson.j5OutputNamingTemplate[combOfAssemblyPiecesTemplateId];
      const reactionJ5OutputNamingTemplateIds = [];
      Object.entries(designJson.reactionJ5OutputNamingTemplate).forEach(
        ([id, val]) => {
          if (val.j5OutputNamingTemplateId === combOfAssemblyPiecesTemplateId) {
            reactionJ5OutputNamingTemplateIds.push(id);
          }
        }
      );
      reactionJ5OutputNamingTemplateIds.forEach(id => {
        delete designJson.reactionJ5OutputNamingTemplate[id];
      });
    }
  }

  // replace old references to RIBOSOME_ENTRY_SITE to RBS
  let oldRbsIconCid = null;
  for (const [cidOrId, icon] of Object.entries(designJson.icon || {})) {
    if (icon.name === "RIBOSOME_ENTRY_SITE") {
      oldRbsIconCid = cidOrId;
      delete designJson.icon[cidOrId];
    }
  }

  for (const bin of Object.values(designJson.bin)) {
    if (bin.iconId === "&" + oldRbsIconCid) {
      bin.iconId = "&RBS";
    }
  }

  const order = groupedTopoSort(
    designJson,
    designJson,
    SIMPLE_REFERENCES_TO_TYPE
  );

  return order;
};

const handleDesignUpserts = async (designs, topologicalDesign, safeUpsert) => {
  const newDesigns = await safeUpsert(
    ["design", "id cid name"],
    designs.map(d => ({
      ...d,
      // CIDs are not used for reference anymore, but there's still the unique constraint on the column.
      cid: d.id,
      __typename: undefined
    }))
  );

  const designIds = newDesigns.map(d => d.id);

  await Promise.mapSeries(topologicalDesign, createMap =>
    // Using mapSeries instead of map appears to prevent deadlock detected errors. It might hurt performance
    // to some degree though.
    Promise.mapSeries(Object.entries(createMap), async ([model, values]) => {
      await executeDesignMutation(model, values, safeUpsert);
    })
  );

  return designIds;
};

const addHashPropToSeqs = order => {
  let sequences = [];
  let sequenceFragments = [];
  for (const o of order) {
    if (o.sequence) sequences = o.sequence;
    if (o.sequenceFragment) sequenceFragments = o.sequenceFragment;
  }

  if (!sequences.length && !sequenceFragments.length) return;

  const orderedFragments = mapKeys(
    sequenceFragments,
    ({ index, sequenceId }) => `${sequenceId}:${index}}`
  );

  const mapSeq = {};
  for (const index in orderedFragments) {
    const id = orderedFragments[index].sequenceId.replace("&", "");
    if (!mapSeq[id]) mapSeq[id] = "";
    mapSeq[id] += orderedFragments[index].fragment;
  }

  for (const seq of sequences) {
    const seqBps = mapSeq[seq.id];
    if (seqBps) {
      seq.hash = computeSequenceHash(seqBps, seq.sequenceTypeCode);
      seq.size = seqBps.length;
    }
  }

  return sequences;
};

function getParsedDesign(designJson) {
  let parsedDesign = JSON.parse(JSON.stringify(designJson));
  forEach(parsedDesign.sequence, s => {
    s.sequenceTypeCode =
      s.sequenceTypeCode || (s.circular ? "CIRCULAR_DNA" : "LINEAR_DNA");
  });
  const booleanFields = [
    "direction",
    "circular",
    "isRoot",
    "isLocked",
    "isPhantom",
    "apLeftDominant",
    "apRightDominant",
    "suppressPrimerAnnotations",
    "suppressPurePrimers",
    "materiallyAvailable"
  ];
  // This will update the part keys from old design jsons
  parsedDesign = deepObjectMapTransform(parsedDesign, (key, value) => {
    if (booleanFields.includes(key)) {
      return [key, !!value];
    } else if (key === "genbankStartBp") {
      return ["start", value];
    } else if (key === "endBp") {
      return ["end", value];
    } else if (key === "revComp") {
      return ["strand", value ? -1 : 1];
    } else if (key === "directionForward") {
      return;
    } else if (key === "labId") {
      return;
    }
    return [key, value];
  });

  if (parsedDesign.taggedItem) {
    Object.values(parsedDesign.taggedItem).forEach(val => {
      if (val.tagOption) {
        const tagOptionId = uuid();
        val.tagOptionId = tagOptionId;
        parsedDesign.tagOption[tagOptionId] = {
          ...val.tagOption,
          id: tagOptionId
        };
        parsedDesign.tagOption[tagOptionId].tagId = val.tagId;
        delete val.tagOption;
      }
    });
  }

  return parsedDesign;
}

export async function uploadDesign(
  { designJson, SIMPLE_REFERENCES_TO_TYPE, checkForDuplicates },
  apolloMethods
) {
  const parsedDesign = getParsedDesign(designJson);
  let dupsDbInfo = {};
  let dups = [];
  updateDesignToLatestVersion({ design: parsedDesign });

  if (checkForDuplicates) {
    const { duplicateInfo, results } = await checkForDuplicateSequences(
      parsedDesign,
      apolloMethods
    );
    dupsDbInfo = duplicateInfo;
    dups = results;
  }

  const response = {
    designIds: null,
    duplicateInfo: {},
    results: []
  };

  const dup = [];
  // if exists info then add it to response
  if (Object.keys(dupsDbInfo).length > 0) {
    Object.assign(response, { duplicateInfo: dupsDbInfo, results: dups });

    // check if exists duplicated data from db
    for (const k in dupsDbInfo) {
      for (const i in dupsDbInfo[k]) {
        dup.push(dupsDbInfo[k][i]);
      }
    }
  }

  // if there's no duplicated then upsert current design
  if (!dup.some(a => a.length)) {
    const parsedFullDesign = getParsedDesign(designJson);
    const designs = Object.values(parsedFullDesign.design);
    delete parsedFullDesign.design;
    const order = getTopologicalDesign(
      parsedFullDesign,
      SIMPLE_REFERENCES_TO_TYPE
    );
    addHashPropToSeqs(order);
    const designIds = await handleDesignUpserts(designs, order, (...args) =>
      apolloMethods.safeUpsert(
        ...args, // Now that we are using client generated IDs for the primary ID field, the upsert apollo method
        // needs the forceCreate flat to explicitly tell it that this is not an Update but a Create.
        { forceCreate: true }
      )
    );
    Object.assign(response, {
      designIds
    });
  }
  return response;
}

export const updateDesignToLatestVersion = (
  designState,
  { isMutations } = {}
) => {
  //tnw flip reuseOligos to doNotReuseOligos
  flipReuseOligos(isMutations ? designState.design : getDesign(designState));
  function flipReuseOligos(d = {}) {
    if (d.reuseOligos === false) {
      d.doNotReuseOligos = true;
    }
    delete d.reuseOligos;
  }
};
