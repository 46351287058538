/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { graphql } from "@apollo/client/react/hoc";
import { get, camelCase, upperFirst, isEmpty, keyBy } from "lodash-es";
import { compose, withHandlers, branch } from "recompose";
import fragmentToQuery from "./utils/fragmentToQuery";
import logGraphql from "./utils/logGraphql";

/**
 * withQuery
 * @param {gql fragment} fragment supply a fragment as the first argument
 * @param {options} options
 * @typedef {object} options
 * @property {boolean} isPlural Are we searching for 1 thing or many?
 * @property {string} queryName What the props come back on ( by default = modelName + 'Query')
 * @property {boolean} asFunction If true, this gives you back a function you can call directly instead of a HOC
 * @property {string} idAs By default single record queries occur on an id. But, if the record doesn't have an id field, and instead has a 'code', you can set idAs: 'code'
 * @property {boolean} getIdFromParams Grab the id variable off the match.params object being passed in!
 * @property {boolean || string} showLoading Show a loading spinner over the whole component while the data is loading
 * @property {boolean} showError Default=true show an error message toastr if the an error occurs while loading the data
 * @return {props}: {xxxxQuery, data }
 */

const convertInputToOutput = input => {
  const orderBy = [];

  input.forEach(item => {
    const orderItem = {};
    let direction = "asc";

    if (item.startsWith("-")) {
      direction = "desc";
      item = item.substring(1);
    }

    orderItem[item] = direction;
    orderBy.push(orderItem);
  });

  return orderBy;
};

export default function withQuery(inputFragment, options = {}) {
  const {
    asFunction,
    LoadingComp,
    nameOverride,
    client,
    variables,
    props,
    queryName,
    getIdFromParams,
    showError = true,
    showLoading,
    inDialog,
    options: queryOptions,
    withLoadingHoc,
    context,
    useHasura,
    ...rest
  } = options;
  if (useHasura && !options.isSingular && options.isPlural !== false)
    options.isPlural = true;
  const { gqlQuery, modelName, nameToUse, queryNameToUse, fragment, isPlural } =
    fragmentToQuery(inputFragment, options);

  const tableParamHandlers = {
    selectTableRecords: props => (ids, keepOldEntities) => {
      const {
        tableParams: { entities, selectedEntities, changeFormValue }
      } = props;
      setTimeout(function () {
        const key = get(entities, "[0].code") ? "code" : "id";
        const entitiesById = keyBy(entities, key);
        const newIdMap = {
          ...(keepOldEntities && selectedEntities)
        };
        ids.forEach(id => {
          const entity = entitiesById[id];
          if (!entity) return;
          newIdMap[id] = {
            entity
          };
        });
        changeFormValue("reduxFormSelectedEntityIdMap", newIdMap);
      });
    }
  };

  return compose(
    graphql(gqlQuery, {
      //default options
      options: props => {
        let variables = getVariables(props, queryOptions, {
          ...options,
          queryNameToUse
        });
        const {
          additionalFilter,
          fetchPolicy,
          pollInterval,
          notifyOnNetworkStatusChange
        } = props;
        let extraOptions = queryOptions || {};
        if (typeof queryOptions === "function") {
          extraOptions = queryOptions(props) || {};
        }

        const { variables: extraOptionVariables, ...otherExtraOptions } =
          extraOptions;

        if (useHasura) {
          const { pageSize, pageNumber, sort } = variables;
          const hasuraVariables = {
            where: additionalFilter,
            ...(pageSize && { limit: pageSize }),
            ...(pageNumber && {
              offset: pageSize * Math.max(pageNumber - 1, 0)
            }),
            ...(sort && { order_by: convertInputToOutput(sort) })
          };
          variables = hasuraVariables;
        } else if (
          get(variables, "filter.entity") &&
          get(variables, "filter.__objectType") === "query" &&
          get(variables, "filter.entity") !== modelName
        ) {
          console.error("filter model does not match fragment model!");
        }

        const context = otherExtraOptions?.context || {};

        return {
          ...(!isEmpty(variables) && { variables }),
          fetchPolicy: fetchPolicy || "network-only",
          nextFetchPolicy: "cache-first",
          ssr: false,
          pollInterval,
          notifyOnNetworkStatusChange,
          // This will refetch queries whose data has been messed up by other cache updates. https://github.com/apollographql/react-apollo/pull/2003
          partialRefetch: true,
          ...otherExtraOptions,
          context: {
            ...context,
            hasura: { gqlRewriter: !useHasura }
          }
        };
      },
      props: (...args) => {
        const { data, ownProps } = args[0];

        const { tableParams } = ownProps;
        const format = useHasura ? ".nodes" : ".results";
        const results = get(data, nameToUse + (isPlural ? format : ""));
        const totalResults = isPlural
          ? get(data, nameToUse + ".totalResults", 0)
          : results && 1;
        const newData = {
          ...data,
          totalResults,
          //adding these for consistency with withItemsQuery
          entities: results,
          entityCount: totalResults,
          ["error" + upperFirst(nameToUse)]: data.error,
          ["loading" + upperFirst(nameToUse)]: data.loading
        };

        const variables = getVariables(ownProps, queryOptions, {
          ...options,
          queryNameToUse
        });

        // apollo does not clear data immediately when variables are changed, stale data is passed down. we are manually
        // clearing it here so that our loading helper will work properly
        if (
          variables.id &&
          get(newData, "entities.id") &&
          get(newData, "entities.id") !== variables.id
        ) {
          newData.loading = true;
          newData.entities = undefined;
          newData.entityCount = undefined;
        }

        if (!newData.loading) {
          logGraphql(modelName, results, variables);
        }

        let newTableParams;
        if (tableParams && !tableParams.entities && !tableParams.isLoading) {
          const entities = results;

          newTableParams = {
            ...tableParams,
            isLoading: newData.loading,
            entities,
            entityCount: totalResults,
            onRefresh: newData.refetch,
            variables,
            fragment
          };
        }

        const propsToReturn = {
          ...(newTableParams && { tableParams: newTableParams }),
          data: newData,
          [queryNameToUse]: newData,
          [nameToUse]: results,
          [nameToUse + "Error"]: newData.error,
          [nameToUse + "Loading"]: newData.loading,
          [nameToUse + "Count"]: totalResults,
          [camelCase("refetch_" + nameToUse)]: newData.refetch,
          fragment,
          gqlQuery
        };

        const [dataArgs, ...otherArgs] = args;
        return {
          ...propsToReturn,
          ...(props &&
            props(
              {
                ...dataArgs,
                ...propsToReturn
              },
              ...otherArgs
            ))
        };
      },
      ...rest //overwrite defaults here
    }),
    branch(props => props.tableParams, withHandlers(tableParamHandlers)),
    branch(
      () => !!withLoadingHoc,
      withLoadingHoc &&
        withLoadingHoc({ showError, showLoading, queryNameToUse, inDialog })
    )
  );
}

function getVariables(ownProps, queryOptions, options) {
  const {
    variables: propVariables,
    getIdFromParams: _getIdFromParamsFromProps,
    recordIdOverride
  } = ownProps;
  const {
    getIdFromParams: _getIdFromParams2,
    queryNameToUse,
    variables
  } = options;
  let id;
  const getIdFromParams = _getIdFromParamsFromProps || _getIdFromParams2;
  if (getIdFromParams && !recordIdOverride) {
    id = get(ownProps, "match.params.id");
    if (!id) {
      console.error(
        "There needs to be an id passed here to ",
        queryNameToUse,
        "but none was found"
      );
      debugger; // eslint-disable-line
      // to prevent crash
      id = -1;
    }
  } else if (recordIdOverride) {
    id = recordIdOverride;
  }

  let extraOptions = queryOptions || {};
  if (typeof queryOptions === "function") {
    extraOptions = queryOptions(ownProps) || {};
  }

  const { variables: extraOptionVariables } = extraOptions;
  const variablesToUse = {
    ...((getIdFromParams || recordIdOverride) && { id }),
    ...variables,
    ...propVariables,
    ...(extraOptionVariables && extraOptionVariables)
  };

  return variablesToUse;
}
