/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const getDbConnectionString = dbConnectionParams => {
  const { user, password, port = 5432, host, database } = dbConnectionParams;
  // NOTE: Hasura's container will run alongisde the app itself in the same pod
  // so it uses localhost to reach the database.

  if (process.env.HASURA_REMOTE) {
    // The remote server uses unix sockets
    return `postgresql://${user}:${password}@/${database}?host=/cloudsql/${process.env.HASURA_DB_HOST}`;
  }

  return `postgresql://${user}:${password}@${host}:${port}/${database}`;
};

export { getDbConnectionString };
