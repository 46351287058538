/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent, ButtonGroup } from "@blueprintjs/core";
import { CollapsibleCard, showConfirmationDialog } from "@teselagen/ui";

import TgSequenceEditor from "../../../../../src-shared/TgSequenceEditor";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { safeUpsert } from "../../../../../src-shared/apolloMethods";

class DnaVeCard extends Component {
  state = {
    removingSequence: false
  };

  removeSequenceHelper = async passedSequence => {
    const { sequences } = this.props;
    const sequence = passedSequence || sequences[0];
    if (!sequence) {
      window.toastr.error("Error removing sequence.");
      console.error("Sequence not found. shouldn't be here");
      return false;
    }

    await safeUpsert("sequence", {
      id: sequence.id,
      polynucleotideMaterialId: null
    });
  };

  removeSequence = async () => {
    const { material, afterMaterialUpdate } = this.props;
    const remove = await showConfirmationDialog({
      text: "Are you sure you would like to unlink this sequence?"
    });
    if (!remove) return;
    this.setState({
      removingSequence: true
    });
    try {
      if (material.materialTypeCode === "DNA") {
        await this.removeSequenceHelper();
      } else if (material.materialTypeCode === "MICROBIAL") {
        window.toastr.error("Error deleting sequence");
        return console.error("not implemented");
      }
      await afterMaterialUpdate();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error removing sequence");
    }
    this.setState({
      removingSequence: false
    });
  };

  showUpload = () => {
    const { strain, material, sequences, afterMaterialUpdate } = this.props;

    showDialog({
      modalType: "UPLOAD_MATERIAL_SEQUENCE",
      modalProps: {
        strain,
        isMicrobialMaterial: false,
        material,
        unlinkOldSequence: sequences[0] ? this.removeSequenceHelper : undefined,
        refetch: afterMaterialUpdate
      }
    });
  };

  render() {
    const { removingSequence } = this.state;
    const { hasSequences, readOnly } = this.props;

    return (
      <CollapsibleCard
        title="Sequence Map"
        openTitleElements={
          !readOnly && (
            <ButtonGroup minimal>
              {!!hasSequences && (
                <Button
                  text="Remove"
                  intent={Intent.DANGER}
                  loading={removingSequence}
                  onClick={this.removeSequence}
                />
              )}
              <Button
                text={hasSequences ? "Change" : "Upload"}
                intent={Intent.PRIMARY}
                onClick={this.showUpload}
              />
            </ButtonGroup>
          )
        }
      >
        {hasSequences ? (
          <TgSequenceEditor {...this.props} />
        ) : (
          <div style={{ padding: 20 }}>
            <i>There is no sequence associated with this material.</i>
          </div>
        )}
      </CollapsibleCard>
    );
  }
}

export default DnaVeCard;
