/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import getFragmentNameFromFragment from "./getFragmentNameFromFragment";
import getModelNameFromFragment from "./getModelNameFromFragment";

function trimFragment(fragment) {
  if (fragment.trim()[0] === "{") {
    // remove brackets so that we can place additional fragment inside brackets
    fragment = fragment.trim();
    fragment = fragment.slice(1, fragment.length - 1);
  }
  return fragment;
}

export function combineGqlFragments(fragments) {
  const fragmentArray = [];
  let fullFragment = "";
  let model;
  fragments.forEach(fragment => {
    if (!model) {
      model = getModelNameFromFragment(fragments[0]);
    }
    if (typeof fragment === "string") {
      fullFragment += `\n${fragment}`;
    } else if (Array.isArray(fragment)) {
      const stringfragment = trimFragment(fragment[1]);
      fullFragment += `\n${stringfragment}`;
    } else {
      fragmentArray.push(fragment);
    }
  });

  return generateStaticGqlFragment(model, fullFragment, fragmentArray);
}

export function generateStaticGqlFragment(model, fields = "", fragments = []) {
  const fragString = Array.isArray(fragments)
    ? fragments.map(f => f.loc.source.body).join("\n")
    : fragments;
  let joinedFields = Array.isArray(fields) ? fields.join("\n") : fields;
  const hash = simpleHashCode(model + joinedFields + (fragString || ""));

  if (Array.isArray(fragments)) {
    if (joinedFields.trim()[0] === "{") {
      // remove brackets so that we can place fragment names inside of them
      joinedFields = joinedFields.trim();
      joinedFields = joinedFields.slice(1, joinedFields.length - 1);
    }
    fragments.forEach(fragment => {
      const fragmentName = getFragmentNameFromFragment(fragment);
      const modelForFrag = getModelNameFromFragment(fragment);
      if (modelForFrag === model) {
        joinedFields += `\n...${fragmentName}`;
      } else {
        // make sure that you added the spread to the fragment manually
      }
    });
  }
  if (joinedFields.trim()[0] !== "{") {
    joinedFields = `{\n${joinedFields}\n}`;
  }
  return gql`
  fragment __${model}FragmentGenerated${hash} on ${model}
  ${joinedFields}
  ${fragString}
  `;
}

export function simpleHashCode(string) {
  let hash = 0;
  if (string.length === 0) {
    return "" + hash;
  }
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  if (hash < 0) hash = -hash;
  return "" + hash;
}

export function pathToNestedString(path, fields) {
  const split = path.split(".");
  const addChild = arr => {
    const model = arr[0];
    if (!arr.length) {
      return fields;
    }
    return `${model} { id ${addChild(arr.slice(1))} }`;
  };
  return addChild(split);
}
