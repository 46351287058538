/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import { Loading } from "@teselagen/ui";
import React from "react";
import { DocumentNode, useQuery } from "@apollo/client";
import client from "./apolloClient";
import { fragmentToQuery } from "@teselagen/apollo-methods";
import type { QueryOptions } from "./queryOptions";

const useTgQuery = (
  inputFragment: [string, string] | DocumentNode,
  opts: QueryOptions = {}
) => {
  const isSingle = opts.variables && (opts.variables.id || opts.variables.code);
  opts.isPlural = opts.isPlural || !isSingle;
  if (opts.useHasura) opts.isPlural = true;
  const { gqlQuery, nameToUse, isPlural } = fragmentToQuery(
    inputFragment,
    opts
  );

  const res = useQuery(gqlQuery, {
    client,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    ...opts
  });

  const path =
    nameToUse + (isPlural ? (opts.useHasura ? ".nodes" : ".results") : "");
  const results = get(res.data, path);

  return {
    ...res,
    entities: results,
    entity: results,
    [nameToUse]: results
  };
};
useTgQuery.checkErrAndLoad = ({
  loading,
  error
}: {
  loading: boolean;
  error: Error;
}) => loading || error;
useTgQuery.handleErrAndLoad = ({
  loading,
  error,
  inDialog
}: {
  loading: boolean;
  error: Error;
  inDialog: boolean;
}) => {
  if (loading) return <Loading bounce inDialog={inDialog} />;
  if (error) {
    const stringErr = JSON.stringify(error);
    window.toastr.error(stringErr, { timeout: 30000 });
  }
  return null;
};

export default useTgQuery;
