/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { HotkeysDialog } from "@teselagen/ui";
import AddDesignRuleDialog from "./components/Dialogs/AddDesignRuleDialog";
import AddRegexDesignRuleDialog from "./components/Dialogs/AddRegexDesignRuleDialog";
import AddUnmappedElementDialog from "./components/Dialogs/AddUnmappedElementDialog";
import AminoAcidPartLibraryDialogContainer from "./containers/AminoAcidPartLibraryDialogContainer";
import CreatePartsFromFeaturesDialog from "./components/Dialogs/CreatePartsFromFeaturesDialog";
import DeleteFeaturesFromSequenceDialog from "./components/Dialogs/DeleteFeaturesFromSequenceDialog";
import ChooseFeatureTypeDialog from "./components/Dialogs/ChooseFeatureTypeDialog";
import CodonMapDialog from "./components/Dialogs/CodonMapDialog";
import CreateConstructAnnotationDialog from "./components/Dialogs/CreateConstructAnnotationDialog";
import AddTagDesignRuleDialog from "./components/Dialogs/AddTagDesignRuleDialog";
import EditElementIndexInBin from "./components/Dialogs/EditElementIndexInBin";
import DuplicateDesignDialog from "./components/Dialogs/DuplicateDesignDialog";
import DuplicateSchematicDialog from "./components/Dialogs/DuplicateSchematicDialog";
import DuplicateAASequenceDialog from "./components/Dialogs/DuplicateAASequenceDialog";
import DuplicatePartsFoundDialog from "./components/Dialogs/DuplicatePartsFoundDialog";
import DesignRuleViolationsDialog from "./components/Dialogs/DesignRuleViolationsDialog";
import GeneratePartsFromFeaturesDialog from "./components/Dialogs/GeneratePartsFromFeaturesDialog";
import GlossaryDialog from "./components/Dialogs/GlossaryDialog";
import PartOrSequenceDeleteDialog from "./components/Dialogs/PartOrSequenceDeleteDialog";
import PartsetLibraryDialog from "./components/Dialogs/PartsetLibraryDialog";
import ParallelPartDialog from "./components/Dialogs/ParallelPartDialog";
import SpecifyBinOverhangDialog from "./components/Dialogs/SpecifyBinOverhangDialog";
import ViewEliminatedCombinationsDialog from "./components/Dialogs/ViewEliminatedCombinationsDialog";
import UpdatePartSetDialog from "./components/Dialogs/UpdatePartSetDialog";
import AssignReactionOverhangsDialog from "./components/Dialogs/AssignReactionOverhangsDialog";
import editPartsetDialog from "./components/HierarchicalDesign/DesignInspector/ElementPanel/editPartsetDialog";
import SchematicEditNameDialog from "./components/Dialogs/SchematicEditNameDialog";
import SendToCrickitDialog from "./components/Dialogs/SendToCrickitDialog";
import GenericRenameDialog from "./components/Dialogs/GenericRenameDialog";
import InsertSequenceDialogContainer from "./containers/InsertSequenceDialogContainer";
import InsertAssemblyPieceDialogContainer from "./containers/InsertAssemblyPieceDialogContainer";
import BlastFeedbackContainer from "./containers/BlastFeedbackContainer";
import ApplyDesignTemplateDialogContainer from "./containers/ApplyDesignTemplateDialogContainer";
import AddEugeneRuleDialogContainer from "./containers/AddEugeneRuleDialogContainer";
import AddOrEditReviewStepDialog from "./components/Dialogs/AddOrEditReviewStepDialog";
import ApplyTagsDialog from "./components/Dialogs/ApplyTagsDialog";
import SubmitDesignForReviewDialog from "./components/Dialogs/SubmitDesignForReviewDialog";
import AddDesignReviewersDialog from "./components/Dialogs/AddDesignReviewersDialog";
import J5LogsDialog from "../src-shared/J5LogsDialog";
import DesignRuleSetLibraryDialog from "./components/Dialogs/DesignRuleSetLibraryDialog";
import CreateDesignRuleSetDialog from "./components/Dialogs/CreateDesignRuleSetDialog";
import CreateSchematicDialog from "./components/Dialogs/CreateSchematicDialog";
import UploadIconDialog from "./components/Dialogs/UploadIconDialog";
import InsertBasePairLiteralDialog from "./components/Dialogs/InsertBasePairLiteralDialog";
import ExportToExternalDbDialog from "../src-shared/ExternalIntegrations/ExportToExternalDbDialog";
import PartLibraryDialog from "./components/Dialogs/PartLibraryDialog";
import EditImportCollectionDialog from "../src-shared/components/Dialogs/EditImportCollectionDialog";

const modalComponents = {
  AddOrEditReviewStepDialog,
  AddDesignReviewersDialog,
  SubmitDesignForReviewDialog,
  ADD_EUGENE_RULE: AddEugeneRuleDialogContainer,
  ADD_DESIGN_RULE: AddDesignRuleDialog,
  ADD_REGEX_DESIGN_RULE: AddRegexDesignRuleDialog,
  ADD_TAG_DESIGN_RULE: AddTagDesignRuleDialog,
  ADD_UNMAPPED_ELEMENT: AddUnmappedElementDialog,
  AA_PART_LIBRARY: AminoAcidPartLibraryDialogContainer,
  APPLY_DESIGN_TEMPLATE: ApplyDesignTemplateDialogContainer,
  APPLY_TAGS: ApplyTagsDialog,
  ASSIGN_REACTION_OVERHANGS: AssignReactionOverhangsDialog,
  BLAST_FEEDBACK: BlastFeedbackContainer,
  CHOOSE_FEATURE_TYPE: ChooseFeatureTypeDialog,
  CREATE_CONSTRUCT_ANNOTATION: CreateConstructAnnotationDialog,
  CREATE_RULE_SET: CreateDesignRuleSetDialog,
  CREATE_PARTS_FROM_FEATURES: CreatePartsFromFeaturesDialog,
  DESIGN_RULE_SET_LIBRARY: DesignRuleSetLibraryDialog,
  DELETE_FEATURES_FROM_SEQUENCES: DeleteFeaturesFromSequenceDialog,
  DESIGN_RULE_VIOLATIONS: DesignRuleViolationsDialog,
  DUPLICATE_DESIGN: DuplicateDesignDialog,
  DUPLICATE_SCHEMATIC: DuplicateSchematicDialog,
  DUPLICATE_AA_SEQUENCE: DuplicateAASequenceDialog,
  DUPLICATE_PARTS_FOUND: DuplicatePartsFoundDialog,
  EDIT_ELEMENT_INDEX_IN_BIN: EditElementIndexInBin,
  EDIT_PARTSETPART: editPartsetDialog,
  ExportToExternalDbDialog,
  EditImportCollectionDialog,
  GENERATE_PARTS_FROM_FEATURES: GeneratePartsFromFeaturesDialog,
  GENERIC_RENAME: GenericRenameDialog,
  GLOSSARY: GlossaryDialog,
  INSERT_BP_LITERAL: InsertBasePairLiteralDialog,
  INSERT_ASSEMBLY_PIECE: InsertAssemblyPieceDialogContainer,
  INSERT_SEQUENCE: InsertSequenceDialogContainer,
  J5_LOGS: J5LogsDialog,
  PARTSET_LIBRARY: PartsetLibraryDialog,
  PART_LIBRARY: PartLibraryDialog,
  PART_OR_SEQUENCE_DELETE: PartOrSequenceDeleteDialog,
  PARALLEL_PART: ParallelPartDialog,
  CODON_MAP: CodonMapDialog,
  SCHEMATIC: CreateSchematicDialog,
  SEND_TO_CRICKIT: SendToCrickitDialog,
  SPECIFY_BIN_OVERHANG: SpecifyBinOverhangDialog,
  UPLOAD_ICON: UploadIconDialog,
  UPDATE_PARTSET: UpdatePartSetDialog,
  VIEW_ELIMINATED_COMBINATIONS: ViewEliminatedCombinationsDialog,
  SCHEMATIC_EDIT_NAME: SchematicEditNameDialog,
  HotkeysDialog
};

export default modalComponents;
