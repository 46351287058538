/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Link } from "react-router-dom";
import modelNameToLink from "./utils/modelNameToLink";

export const libraryNameToHelpInfo = {
  labProtocol: {},
  guideRnaRun: {},
  aliquot: {
    text: "A division of a sample or another aliquot. The first aliquot of a defined sample is a sample-aliquot. Any further aliquots of that sample (made by dividing the sample-aliquot) is a replicate-aliquot. Aliquots are held within plates/containers and they have physical information such as volume, concentration, and mass.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  sample: {
    text: "The first physical instance of a material in our system, samples make the connection between various types of materials and thier physical presence in the lab as an aliquot.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5520562-teselagen-sample-management-hierarchy"
  },
  dnaSequence: {
    text: "DNA Sequences are a type of nucleic acid sequence that is made up of the nucleotides adenine (A), cytosine (C), guanine (G), and thymine (T).",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2316682-importing-dna-sequences"
  },
  rnaSequence: {
    text: "RNA Sequences are a type of nucleic acid sequence that is made up of the nucleotides adenine (A), cytosine (C), guanine (G), and uracil (U).",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776364-working-with-dna-sequences"
  },
  aminoAcidSequence: {
    text: "Amino Acid Sequences are a type of sequence that is made up of the amino acids that make up a protein.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/455209-amino-acid-sequences"
  },
  gasComposition: {
    text: "A characteristic of growth conditions, the gas composition is the makeup of gases in a mixture or environment.",
    helpDocLink: ""
  },
  genus: {
    text: "A taxonomic rank of a strain or cell line.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5578944-creating-a-strain-archive-part-i"
  },
  inductionMethod: {
    text: "The method used to induce a specific response or behavior in a strain or plasmid.",
    helpDocLink: ""
  },
  reagent: {
    text: "A substance or compound used in a reaction.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321349-reagents-reagent-lots-additives"
  },
  "Cas Enzyme": {
    text: "CRISPR-associated protein that plays a key role in the CRISPR-Cas system.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4336790-crispr-guide-rna-designer"
  },
  cellCulture: {
    text: "A type of material, a cell culture is the definition of a specific culture, connecting cell line data to physical samples and aliquots. Not exclusively microbial.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321351-sequences-materials-samples-aliquots?q=cell+line"
  },
  cellLine: {
    text: "A defined population of cells, not exclusively microbial.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5578944-creating-a-strain-archive-part-i"
  },
  codingDNASequence: {
    text: "A DNA sequence or part of a DNA sequence that codes for a amino acid sequence or protein.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776364-working-with-dna-sequences"
  },
  plate: {
    text: "A type of container with NxM matrix of wells or NxM rack of tubes..",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  container: {
    text: "A receptacle or vessel used to hold or store different items from inventory.",
    helpDocLink: "https://docs.teselagen.com/en/articles/4328776-containers"
  },
  dnaMaterial: {
    text: "A registered piece of DNA (immutable) connecting sequence data to physical samples and aliquots. DNA Materials can be used in reactions whereas sequences cannot.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321351-sequences-materials-samples-aliquots"
  },
  equipment: {
    text: "Tools or devices used for a specific purpose or activity.",
    helpDocLink: "https://docs.teselagen.com/en/articles/4328775-equipment"
  },
  genomicMaterial: {
    text: "The definition of a specific genomic material, connecting a genome to physical samples and aliquots.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321351-sequences-materials-samples-aliquots"
  },
  genomicRegion: {
    text: "A specific region of interest on a genome.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5520562-teselagen-sample-management-hierarchy"
  },
  guideRNASequence: {
    text: "A short RNA sequence that guides the CRISPR-Cas system to a specific target.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4336790-crispr-guide-rna-designer"
  },
  job: {
    text: "Jobs are higher order tasks consisting of one or more workflows used to manage workloads from a user request.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  location: {
    text: "It can be a Building, Lab,  Floor, Room, etc. It is at a site and can be nested. You can access it from the menu: Inventory > Locations, and create a new Location, export an existent (as CSV file) or see the following details of the existing ones: “Name”, “Type”, “Path”, “Site”, “Added By”, “Created”, “Modified”.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  reagentLot: {
    text: "A batch or quantity of a specific reagent.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321349-reagents-reagent-lots-additives"
  },
  microbialMaterial: {
    text: "A definition of a specific microbial entity, linking strain information to physical samples and aliquots.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321351-sequences-materials-samples-aliquots"
  },
  oligo: {
    text: "A short single strand of DNA or RNA that serves as a starting point for many biological processes.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776364-working-with-dna-sequences"
  },
  plateMap: {
    text: "A virtual representation of a plate, used to dictate distribution of samples during liquid handling operations.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  proteinMaterial: {
    text: "A definition of a specific protein, linking amino acid sequence data to physical samples and aliquots.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321351-sequences-materials-samples-aliquots"
  },
  reaction: {
    text: "An N to 1 mapping of particular materials into a new material.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  regionAnnotation: {
    text: "An annotation associated with a specific region of an Amino Acid sequence.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776364-working-with-dna-sequences"
  },
  report: {
    text: "A user-defined set of data describing a specific plate and its contents.",
    helpDocLink: "https://docs.teselagen.com/en/articles/5690925-reports"
  },
  rnaMaterial: {
    text: "A registered (immutable) RNA molecule connecting an RNA sequence to physical samples and aliquots. RNA Materials can be used in reactions whereas RNA sequences cannot.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4321351-sequences-materials-samples-aliquots"
  },
  DNASequencingQualityControlReport: {
    text: "A report generated by sequencing QC, generated by aligning sequencing data against reference sequences.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4330350-sequencing-quality-control-tool"
  },
  tube: {
    text: "Tubes are used in the lab to contain single aliquots.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  orders: {
    text: "A list of sequences or other inventory from a vendor, output by the Teselagen's Bioshop.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3764246-teselagen-s-bioshop"
  },
  workflowRun: {
    text: "A sequence of tasks or steps that are performed to achieve a specific outcome.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3750692-workflow-definitions?q=workflow+run"
  },
  targetOrganismClass: {
    text: "An organism class targeted by a specific strain. Note: This is a user-specified grouping, not a phylogenic term",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5578944-creating-a-strain-archive-part-i"
  },
  "placement-queue": {
    text: "Lists of tubes or plates to be placed into containers, freezers or other locations.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4328783-placement-strategies"
  },
  placementStrategy: {
    text: "Rules dictating how to place tubes and plates into containers, freezers, or other locations.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4328783-placement-strategies"
  },
  selectionMethod: {
    text: "A process in which cells of a specific trait will survive growth conditions.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5578944-creating-a-strain-archive-part-i"
  },
  vendor: {
    text: "You can add additional DNA vendors here if needed. You can purchase DNA from these vendors through the Bioshop.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3764246-teselagen-s-bioshop"
  },
  customJ5Parameter: {
    text: (
      <span>
        Assembly Parameter Presets can be applied to{" "}
        <Link to={modelNameToLink("design")}>Designs</Link> or{" "}
        <Link to={modelNameToLink("designTemplate")}>Design Templates</Link>.
      </span>
    ),
    helpDocLink: ""
  },
  designTemplate: {
    text: "A template that can be used to capture portions of designs that are reused from design to design, and can be applied within the design editor to quickly build out a design in an automated fashion.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  featureType: {
    text: "By default we provide you with the set of standard genbank feature types. Any additional types you add here will show up as options in the sequence editor.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5196411-creating-feature-groups"
  },
  extendedProperty: {
    text: "Extended properties are a great way to add a new column of data to most any table. For example, you may have particular data that you wish to capture for every DNA sequence in your database. It could be a name, a number, or perhaps a URL. Note that data upload templates often include examples of additional columns that can be uploaded as extended properties.",
    helpDocLink: "https://docs.teselagen.com/en/articles/9358553-user-settings"
  },
  labGroup: {
    text: `Lab groups act as siloed distinct "instances" of the platform for work that must be isolated for security or intellectual property reasons. Normally most companies start with a single Lab Group and add distinct Labs only as the need arises. One common example might be different academic labs within a single department. Another might be different groups within a large company that must work on distinctly different projects for customers that require data isolation and security.`,
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3705461-what-is-a-lab-in-teselagen-and-what-is-it-for"
  },
  serviceAccount: {
    text: "Service accounts are a special kind of account used by an application rather than a person. Applications use service accounts to perform authorized automated tasks and can only be configured by an Admin account.",
    helpDocLink: ""
  },
  project: {
    text: `Projects are sharing spaces where users can group data for sharing. Users can create spaces for a number of reasons, to sequester their own work into a sandbox where they can work privately, or invite others to work collaboratively. You can also create projects that are shared with everyone and become a common space or curated registry. Note if you share something that is linked to an entity within your private project, the private entity can be accessed through the linked entity. For example if you create a design in a private project, and then share a part within the design, the private design will show up in the "Linked Designs" of that part and can be accessed through it.`,
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5507271-intro-to-projects"
  },
  microserviceTask: {
    text: "A task or job performed by a microservice.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4336188-microservices-for-custom-algorithms"
  },
  tag: {
    text: "Tags are a flexible way to organize and categorize your data. They can be filtered to quickly find all data with a particular tag. Tags can also have sub-tags, so for example you might have a fluorescent protein tag with sub-tags for different types of fluorescent proteins like GFP, YFP, CFP, RFP.",
    helpDocLink: "https://docs.teselagen.com/en/articles/9358553-user-settings"
  },
  assay: {
    text: "A particular test within an experiment to measure a particular behavior or characteristic of one or many assay subjects. Each assay is associated with a single Experiment.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3722940-glossary-test-module"
  },
  importCollection: {
    text: "An import collection is a grouping of entities that were uploaded together.",
    helpDocLink: ""
  },
  subject: {
    text: "A subject corresponds to a measurable entity of experimentation or experimental unit.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3722940-glossary-test-module"
  },
  sequenceFeature: {
    text: "An annotation on a region of a nucleic acid sequence that has a specific function or structure.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  "user-request": {
    text: "A user request is generated to keep track of a bulk of work that a user has to do for one of their clients.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  workflowRun: {
    text: "The execution of a workflow that consists of a sequence of tasks or steps.",
    helpDocLink: ""
  },
  workflowDefinition: {
    text: `Here you can define a "Workflow" or a series of tools that can be executed via a Workflow Run.`,
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3750692-workflow-definitions"
  },
  workQueueItem: {
    text: "A list of workflow tasks assigned to lab members.",
    helpDocLink: ""
  },
  design: {
    text: "Designs are where you can put pieces of your existing DNA sequences together (in a combinatorial fashion if you like) to create new DNA sequences. Try creating an example design from the New Design menu",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2629281-creating-and-importing-designs"
  },
  schematic: {
    text: "An abstract representation of a design that can be used to create a generic snapshot of a design or to kick off the design process at a higher level (they can be converted to designs).",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  ruleSet: {
    text: "Constraints that can be added to bins in a design editor that will throw warnings when their specifications aren’t met.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  dnaPart: {
    text: "A special type of annotation on a DNA sequence that can be inserted into a design for assembly.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  partset: {
    text: "A grouping of parts that can be inserted into a design (one cell represents many parts).",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  rnaPart: {
    text: "A special type of annotation on an RNA sequence that can be inserted into a design for assembly.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  aminoAcidPart: {
    text: "A special type of annotation on an amino acid sequence that can be inserted into a design for assembly.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  genomicReferenceSequence: {
    text: "A reference sequence that is used as a template for designing DNA sequences.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776364-working-with-dna-sequences"
  },
  genome: {
    text: "The complete set of genetic material in an organism.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5578944-creating-a-strain-archive-part-i"
  },
  functionalProteinUnit: {
    text: "One or more amino acid sequences grouped together to perform a specific function.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776368-working-with-amino-acid-sequences"
  },
  strain: {
    text: "A microbial strain is a genetic variant or culture within a microbial species.",
    helpDocLink: "https://docs.teselagen.com/en/articles/457724-strains"
  },
  j5Report: {
    text: "A report generated by running a Design. It contains instructions for combining input parts into assembled constructs.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3679978-glossary-design-module"
  },
  crisprDesign: {
    text: "A specific design of gRNA for CRISPR experiments.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4336790-crispr-guide-rna-designer"
  },
  worklist: {
    text: "A set of automated instructions for liquid handling machines.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3770651-worklists"
  },
  protocol: {
    text: "a predefined written procedural method in the design and implementation of experiments.",
    helpDocLink: "https://docs.teselagen.com/en/articles/457723-protocols"
  },
  "reaction-map": {
    text: "A list of reactions in N to 1 mappings of particular materials into a new material.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2674923-create-reaction-map-tool"
  },
  "reaction-design": {
    text: "An ordered collection of reaction maps used to plan a series of reactions across an entire workflow.",
    helpDocLink: ""
  },
  dataTable: {
    text: "A Data Table stores a table of Data Rows which each points store a reference to an inventory item or j5 item.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  "data-set": {
    text: "A collection of Data Tables, files, dna assembly reports and other data entities.",
    helpDocLink: "https://docs.teselagen.com/en/articles/3764239-glossary"
  },
  workflowCollection: {
    text: "A collection of all entities output or associated with a specific workflow run.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/3750692-workflow-definitions"
  },
  alignment: {
    text: "An arrangement of sequences used to identify regions of similarity.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/4330350-sequencing-quality-control-tool"
  },
  dataGrid: {
    text: "A set of assay data created by parsing data files with the Parse Experiment Data tool",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/6392796-data-import-pipeline"
  },
  rnaType: {
    text: "You can create new RNA types here if needed.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/2776364-working-with-dna-sequences"
  },
  restrictionEnzyme: {
    text: "A type of protein that cuts DNA at restriction sites to create DNA fragments.",
    helpDocLink: "https://docs.teselagen.com/en/articles/9358553-user-settings"
  },
  enzymeGroup: {
    text: "Manage your Enzyme Groups here. Add or import more enzymes from the previous panel.",
    helpDocLink: "https://docs.teselagen.com/en/articles/9358553-user-settings"
  },
  "Registered feature": {
    text: `Register your "canonical" features here. Add them to Groups (in the next panel) to use them for Auto-Annotation.`,
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5196411-creating-feature-groups"
  },
  "Feature Group": {
    text: "Manage your Feature Groups here. Add or import more features from the previous panel.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5196411-creating-feature-groups"
  },
  registeredAnnotation: {
    text: `Register your "canonical" parts here. Add them to Groups (in the next panel) to use them for Auto-Annotation.`,
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5196411-creating-feature-groups"
  },
  registeredAnnotationGroup: {
    text: "Manage your Part Groups here. Add or import more parts from the previous panel.",
    helpDocLink:
      "https://docs.teselagen.com/en/articles/5196411-creating-feature-groups"
  }
};

("data-file");
("data-map");
("data-set");

("gasComposition");
("genus");
("inductionMethod");
("reagent");
("Cas Enzyme");
("cellCulture");
("cellLine");
("codingDNASequence");
("plate");
("container");

("dnaMaterial");
("equipment");
("genomicMaterial");
("genomicRegion");
("guideRNASequence");
("job");
("location");
("reagentLot");
("microbialMaterial");
("oligo");
("plateMap");
("proteinMaterial");
("reaction-design");
("reaction-map");
("regionAnnotation");
("report");
("rnaMaterial");
("DNASequencingQualityControlReport");
("tube");
("user-request");
("orders");
("workflowDefinition");
("targetOrganismClass");
("placement-queue");
("selectionMethod");
("vendor");
("Parameter Preset");
("designTemplate");
("featureType");
("extendedProperty");
("labGroup");
("sequenceFeature");
("serviceAccount");
("project");
("microserviceTask");
("tag");
("user");
("assay");
("importJobs");
("subject");
