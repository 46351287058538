/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import downloadjsFile from "downloadjs";

export function download(data, fileName, type) {
  if (window.Cypress && !window.Cypress.allowFileDownload) {
    window.Cypress.cypressTestFile = data;
    window.toastr.success(
      "mock file downloaded, use catchDownload to assert on it"
    );
  } else {
    downloadjsFile(data, fileName, type);
  }
}
