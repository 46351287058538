/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { every, keyBy, get, identity, uniq, groupBy } from "lodash";
import {
  DataTable,
  RadioGroupField,
  withSelectedEntities,
  withSelectTableRecords
} from "@teselagen/ui";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { mapSeries } from "bluebird";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import {
  pcrPlanningJ5RunConstruct,
  pcrPlanningJ5InputSequence,
  pcrPlanningJ5DirectSynthesis,
  pcrPlanningJ5AssemblyPiece,
  pcrPlanningJ5OligoSynthesis,
  pcrPlanningJ5PcrReaction,
  pcrPlanningAssemblyPieceFragment
} from "../../../../graphql/fragments/pcrPlanningj5ReportFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import LinkAllJ5MaterialsButton from "../../../LinkAllJ5MaterialsButton";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import {
  dateModifiedColumn,
  j5ReportAssemblyHierarchicalColumns
} from "../../../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { getLinkDialogProps } from "../../../../../src-shared/SharedJ5ReportRecordView/utils";
import { isSequenceInInventory } from "../utils";
import { addTagFilterToQuery } from "../../../../../src-shared/utils/tagUtils";
import { InventoryCheckIcon } from "../../../../../src-shared/components/InventoryCheckIcon";
import { pcrPlanningSourcePlateMapGroupFragment } from "../fragments";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import UploadJ5Report from "../../../Library/J5Reports/UploadJ5Report";
import { cloneDeep } from "@apollo/client/utilities";

// refactor j5 report fragment split up like worklists
const schema = {
  model: "j5Report",
  fields: [
    "name",
    "assemblyType",
    "assemblyMethod",
    ...j5ReportAssemblyHierarchicalColumns,
    dateModifiedColumn
  ]
};

const j5ReportMinimalFragment = [
  "j5Report",
  /* GraphQL */ `
    {
      id
      name
      assemblyMethod
      assemblyType
      createdAt
      dateRan
      updatedAt
      j5FileId
      isHierarchical
      outputCardName
      assemblyBatchId
      idFromOriginalAssemblyBatch
      treePosition
    }
  `
];

async function getFullReports(partialReports, queryOptions = {}) {
  return await mapSeries(partialReports, async partial => {
    const options = {
      variables: {
        filter: {
          j5ReportId: partial.id
        }
      },
      ...queryOptions
    };
    let j5RunConstructs = await safeQuery(pcrPlanningJ5RunConstruct, options);
    const assemblyPieceIds = j5RunConstructs.reduce((acc, construct) => {
      construct.j5ConstructAssemblyPieces.forEach(ap => {
        if (ap.assemblyPieceId) acc.push(ap.assemblyPieceId);
      });
      return acc;
    }, []);
    if (assemblyPieceIds.length) {
      const assemblyPieces = await safeQuery(pcrPlanningAssemblyPieceFragment, {
        variables: {
          filter: {
            id: assemblyPieceIds
          }
        },
        ...queryOptions
      });
      const keyedAssemblyPieces = keyBy(assemblyPieces, "id");
      j5RunConstructs = j5RunConstructs.map(construct => {
        return {
          ...construct,
          j5ConstructAssemblyPieces: construct.j5ConstructAssemblyPieces.map(
            j5AP => {
              return {
                ...j5AP,
                assemblyPiece: keyedAssemblyPieces[j5AP.assemblyPieceId]
              };
            }
          )
        };
      });
    }

    // these are needed to check if report is fully linked to materials
    const j5InputSequences = await safeQuery(
      pcrPlanningJ5InputSequence,
      options
    );
    const j5DirectSyntheses = await safeQuery(
      pcrPlanningJ5DirectSynthesis,
      options
    );
    const j5AssemblyPieces = await safeQuery(
      pcrPlanningJ5AssemblyPiece,
      options
    );
    const j5OligoSyntheses = await safeQuery(
      pcrPlanningJ5OligoSynthesis,
      options
    );
    let j5PcrReactions = await safeQuery(pcrPlanningJ5PcrReaction, options);

    //     pcrProductSequence
    // primaryTemplate
    // forwardPrimer
    // reversePrimer

    let allSequenceIds = [];
    j5PcrReactions.forEach(pcrReaction => {
      const sequenceIds = [
        pcrReaction.pcrProductSequence?.id,
        pcrReaction.primaryTemplate?.id,
        pcrReaction.forwardPrimer?.sequence?.id,
        pcrReaction.reversePrimer?.sequence?.id
      ].filter(identity);
      allSequenceIds = allSequenceIds.concat(sequenceIds);
    });
    allSequenceIds = uniq(allSequenceIds);

    if (allSequenceIds.length) {
      const aliquotContainersWithSequence = await safeQuery(
        [
          "aliquotContainer",
          /* GraphQL */ `
            {
              id
              aliquot {
                id
                sample {
                  id
                  material {
                    id
                    polynucleotideMaterialSequence {
                      id
                    }
                  }
                }
              }
            }
          `
        ],
        {
          variables: {
            filter: {
              "aliquot.sample.material.polynucleotideMaterialSequence.id": allSequenceIds
            }
          }
        }
      );

      const groupedAcs = groupBy(
        aliquotContainersWithSequence,
        "aliquot.sample.material.polynucleotideMaterialSequence.id"
      );

      j5PcrReactions = j5PcrReactions.map(j5PcrReaction => {
        const newR = cloneDeep(j5PcrReaction);
        const seqPaths = [
          "pcrProductSequence",
          "primaryTemplate",
          "forwardPrimer.sequence",
          "reversePrimer.sequence"
        ];
        seqPaths.forEach(seqPath => {
          const seq = get(newR, seqPath);
          if (seq) {
            seq.containerCount = (groupedAcs[seq.id] || []).length;
          }
        });
        return newR;
      });
    }
    return {
      ...partial,
      j5RunConstructs,
      j5InputSequences,
      j5DirectSyntheses,
      j5AssemblyPieces,
      j5OligoSyntheses,
      j5PcrReactions
    };
  });
}

const additionalFilterForReports = (props, qb, currentParams) => {
  qb.whereAll({
    "j5PcrReactions.id": qb.notNull()
  });
  addTagFilterToQuery(currentParams.tags, qb);
};

class J5PCRSelection extends Component {
  state = {
    loadingFullReports: false
  };

  async componentDidUpdate() {
    const {
      loadedOptions,
      j5Reports = [],
      partialJ5Reports = [],
      selectTableRecords,
      stepFormProps: { change }
    } = this.props;

    // reset
    if (!partialJ5Reports.length && j5Reports.length) {
      change("j5Reports", []);
      loadedOptions.lastLoadedReportIds = [];
    }

    const newIds = partialJ5Reports.map(w => w.id);
    const wasAlreadyLoaded = newIds.every(id =>
      loadedOptions.lastLoadedReportIds.includes(id)
    );

    if (!wasAlreadyLoaded && partialJ5Reports.length) {
      loadedOptions.lastLoadedReportIds = newIds;
      this.setState({
        loadingFullReports: true
      });

      try {
        const fullReports = await getFullReports(partialJ5Reports);
        const pcrReactions = this.getPcrReactions(fullReports);
        selectTableRecords(pcrReactions);
        change("j5Reports", fullReports);
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error loading full reports.");
        change("j5Reports", []);
      }

      this.setState({
        loadingFullReports: false
      });
    }
  }

  clearReports = () => {
    const { stepFormProps, loadedOptions } = this.props;
    stepFormProps.change("j5Reports", []);
    loadedOptions.lastLoadedReportIds = [];
  };
  saveSelectedPcrReactions = allPcrReactions => {
    const {
      j5PcrReactionsSelectedEntities = [],
      stepFormProps: { change },
      nextStep
    } = this.props;

    if (this.isPlateMapSource()) {
      change("selectedPcrReactions", allPcrReactions);
    } else {
      change("sourcePlateMapGroup", null);
      change("selectedPcrReactions", j5PcrReactionsSelectedEntities);
    }
    nextStep();
  };
  renderRemoveReportButton = (v, r) => {
    const {
      stepFormProps: { change },
      j5Reports = [],
      partialJ5Reports = [],
      loadedOptions
    } = this.props;
    const removeAction = () => {
      const newReports = j5Reports.filter(j5Report => j5Report.id !== r.id);
      change("j5Reports", newReports);
      loadedOptions.lastLoadedReportIds = newReports.map(r => r.id);
      change(
        "partialJ5Reports",
        partialJ5Reports.filter(j5Report => j5Report.id !== r.id)
      );
    };
    return (
      <Button icon="trash" intent="danger" minimal onClick={removeAction} />
    );
  };

  getPcrReactions = j5Reports => {
    const pcrReactions = [];
    j5Reports.forEach(j5Report => {
      j5Report.j5PcrReactions.length > 0 &&
        j5Report.j5PcrReactions.forEach(pcr => pcrReactions.push(pcr));
    });
    return pcrReactions;
  };

  fetchUploadedReport = async j5ReportIds => {
    const {
      stepFormProps: { change }
    } = this.props;
    const j5Reports = await safeQuery(j5ReportMinimalFragment, {
      variables: {
        filter: {
          id: j5ReportIds
        }
      }
    });
    change("partialJ5Reports", j5Reports);
  };

  renderUploadJ5ReportButton() {
    return (
      <div style={{ marginLeft: 10 }}>
        <Button
          text="Upload DNA Assembly Report"
          onClick={() => {
            showDialog({
              ModalComponent: UploadJ5Report,
              modalProps: {
                uploadCompleted: this.fetchUploadedReport
              }
            });
          }}
        />
      </div>
    );
  }

  isPlateMapSource() {
    return this.props.sourceType === "plateMapGroup";
  }

  render() {
    const { loadingFullReports } = this.state;
    const {
      toolSchema,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      stepFormProps: { change },
      show,
      Footer,
      footerProps,
      j5Reports = [],
      j5PcrReactionsSelectedEntities = [],
      partialJ5Reports = [],
      sourcePlateMapGroup,
      handleSubmit
    } = this.props;

    let pcrReactions, nextDisabled;
    if (this.isPlateMapSource()) {
      if (sourcePlateMapGroup) {
        pcrReactions = [];
        sourcePlateMapGroup.plateMaps.forEach(pm => {
          pm.plateMapItems.forEach(pmi => {
            const reaction = get(pmi, "j5Item.j5PcrReaction");
            // validation will ensure that they all have reactions
            if (reaction) pcrReactions.push(reaction);
          });
        });
      }
      nextDisabled =
        !sourcePlateMapGroup || !pcrReactions || !pcrReactions.length;
    } else {
      const hasFullyLinkedReports =
        !!j5Reports.length &&
        !isLoadingMap.j5Reports &&
        j5Reports.every(report => {
          const linkDialogProps = getLinkDialogProps(report);
          const allLinked = every(linkDialogProps, "allLinked");
          return allLinked;
        });
      if (hasFullyLinkedReports) {
        pcrReactions = this.getPcrReactions(j5Reports);
      }
      nextDisabled =
        !hasFullyLinkedReports || !j5PcrReactionsSelectedEntities.length;
    }
    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select PCR Reactions"
            helper="Select one or more DNA Assembly Reports or a Plate Map of PCR Reactions.
            If the relevant sequences have not been linked to
            materials, link them from the table below."
            width="100%"
          />
          <RadioGroupField
            name="sourceType"
            options={[
              { label: "DNA Assembly Report", value: "j5Report" },
              { label: "Plate Map", value: "plateMapGroup" }
            ]}
            defaultValue="j5Report"
          />
          {this.isPlateMapSource() ? (
            <div>
              <GenericSelect
                {...{
                  name: "sourcePlateMapGroup",
                  schema: ["name"],
                  fragment: ["plateMapGroup", "id name"],
                  additionalDataFragment: pcrPlanningSourcePlateMapGroupFragment,
                  tableParamOptions: {
                    additionalFilter: {
                      "plateMaps.type": "j5PcrReaction"
                    }
                  },
                  buttonProps: {
                    disabled: isDisabledMap.plateMapGroup,
                    loading: isLoadingMap.plateMapGroup
                  }
                }}
              />
              {sourcePlateMapGroup && <h6>{sourcePlateMapGroup.name}</h6>}
            </div>
          ) : (
            <React.Fragment>
              <div className="tg-flex align-center tg-no-form-group-margin">
                <div>
                  <GenericSelect
                    {...{
                      name: "partialJ5Reports",
                      nameOverride: "DNA Assembly Report",
                      schema,
                      isMultiSelect: true,
                      fragment: j5ReportMinimalFragment,
                      dialogProps: {
                        style: {
                          width: 650
                        }
                      },
                      dialogInfoMessage: (
                        <Callout intent="primary">
                          Note: Assembly Reports that do NOT have PCR reactions
                          will not show up here.
                        </Callout>
                      ),
                      tableParamOptions: {
                        additionalFilter: additionalFilterForReports
                      },
                      onClear: this.clearReports,
                      buttonProps: {
                        loading: isLoadingMap.j5Reports,
                        disabled: isDisabledMap.j5Reports
                      }
                    }}
                  />
                </div>
                {!partialJ5Reports.length && this.renderUploadJ5ReportButton()}
              </div>
              {(!!j5Reports.length ||
                isLoadingMap.j5Reports ||
                loadingFullReports) && (
                <DataTable
                  {...{
                    formName: toolSchema.code,
                    destroyOnUnmount: false,
                    keepDirtyOnReinitialize: true,
                    enableReinitialize: true,
                    updateUnregisteredFields: true,
                    noSelect: true,
                    isSimple: true,
                    isLoading: isLoadingMap.j5Reports || loadingFullReports,
                    entities: j5Reports,
                    schema: [
                      "name",
                      "assemblyMethod",
                      "assemblyType",
                      {
                        path: "Link Materials",
                        sortDisabled: true,
                        resizable: false,
                        width: 150,
                        render: (v, r) =>
                          renderLinkMaterials(v, r, {
                            show,
                            j5Reports,
                            change,
                            toolSchema
                          })
                      },
                      {
                        type: "action",
                        width: 80,
                        render: this.renderRemoveReportButton
                      }
                    ]
                  }}
                />
              )}
            </React.Fragment>
          )}
        </div>
        {pcrReactions && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="PCR Reactions"
              helper="Below is a list of PCR Reactions associated with the reports selected above. Select which reactions you would like to perform."
              width="100%"
            />
            <DataTable
              schema={[
                {
                  displayName: "PCR Name",
                  path: "name",
                  type: "string"
                },
                {
                  displayName: "Product Sequence",
                  path: "pcrProductSequence",
                  type: "string",
                  getClipboardData: getItemNameForTooltip,
                  render: nameWithInventoryIndicator
                },
                {
                  displayName: "Assembly Piece",
                  path: "pcrProductSequence.j5AssemblyPiece.name",
                  type: "string"
                },
                {
                  displayName: "Primary Template",
                  path: "primaryTemplate",
                  type: "string",
                  getClipboardData: getItemNameForTooltip,
                  render: nameWithInventoryIndicator
                },
                {
                  displayName: "Forward Primer",
                  path: "forwardPrimer",
                  type: "string",
                  getClipboardData: getItemNameForTooltip,
                  render: nameWithInventoryIndicator
                },
                {
                  displayName: "Reverse Primer",
                  path: "reversePrimer",
                  type: "string",
                  getClipboardData: getItemNameForTooltip,
                  render: nameWithInventoryIndicator
                },
                {
                  displayName: "Mean Tm (°C)",
                  path: "oligoMeanTm",
                  type: "string"
                },
                {
                  displayName: "Delta Tm (°C)",
                  path: "oligoDeltaTm",
                  type: "string"
                }
              ]}
              formName="j5PcrReactions"
              maxHeight={400}
              entities={pcrReactions}
              keepDirtyOnReinitialize
              isInfinite
              doNotShowEmptyRows
              withCheckboxes={!this.isPlateMapSource()} // if plate map group source then they cannot deselect
              noPadding
              destroyOnUnmount={false}
            />
          </div>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.PRIMARY}
              onClick={handleSubmit(() =>
                this.saveSelectedPcrReactions(pcrReactions)
              )}
              disabled={nextDisabled}
            >
              Next
            </Button>
          }
        />
      </div>
    );
  }
}

export default compose(
  withSelectTableRecords("j5PcrReactions"),
  stepFormValues(
    "partialJ5Reports",
    "j5Reports",
    "sourceType",
    "sourcePlateMapGroup"
  ),
  withSelectedEntities("j5PcrReactions")
)(J5PCRSelection);

const renderLinkMaterials = (v, record, { j5Reports, change }) => {
  const linkDialogProps = getLinkDialogProps(record);
  const allLinked = every(linkDialogProps, "allLinked");
  if (allLinked) {
    return "Fully Linked";
  }

  const afterLinking = async () => {
    const [fullReport] = await getFullReports([record], {
      options: {
        fetchPolicy: "cache-only"
      }
    });
    const indexOfReport = j5Reports.findIndex(r => r.id === record.id);
    change("j5Reports", [
      ...j5Reports.slice(0, indexOfReport),
      fullReport,
      ...j5Reports.slice(indexOfReport + 1)
    ]);
  };

  return (
    !!j5Reports.length && (
      <LinkAllJ5MaterialsButton
        j5Report={record}
        text="Link to Materials"
        afterlinking={afterLinking}
      />
    )
  );
};

function getItemNameForTooltip(v) {
  return v.name;
}

function nameWithInventoryIndicator(v) {
  const value = v || {};
  const sequence = value.sequence || v;
  const inInventory = isSequenceInInventory(sequence);
  return (
    <span>
      <InventoryCheckIcon inInventory={inInventory} />
      {value.name}
    </span>
  );
}
