/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import path from "path";
import dirname from "es-dirname";

/*
  * This function is used to get the root directory of the project.
  * It is necesssary because there are lot of dependencies between between
  different places in the project and it is hard to keep track of them.
  Then, using the path from the root folder is the cleanest way to do it.
  * Once everything goes into platform/packages and there are no files
  created on the run or there are created inside another package, this
  function will not be needed.
  * For this function to work, the app dirname() must be inside the root
  directory of the project.
  * Because of using linked packages, the dirname changes. It happens
  because a symlink is created inside the node_modules folder.
*/

const getWorkingDirectory = path => {
  for (const dir of [
    "app-proxy",
    "platform",
    "server",
    "tg-iso-design",
    "tg-iso-lims",
    "tg-iso-shared"
  ]) {
    if (path.includes(dir)) {
      return dir;
    }
  }
};

// gets the root directory absolute path of the project using ESM
const getRootDirectory = () => {
  let __dirname = dirname().split(path.sep);
  let workingDirectory = getWorkingDirectory(__dirname);

  while (workingDirectory && __dirname.length > 0) {
    const rootIndex = __dirname.indexOf(workingDirectory);
    __dirname = __dirname.slice(0, rootIndex);
    workingDirectory = getWorkingDirectory(__dirname);
  }
  return path.sep + __dirname.join(path.sep);
};

export { getRootDirectory };
