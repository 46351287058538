/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem, MenuDivider, Tooltip, Position } from "@blueprintjs/core";
import { keyBy, difference } from "lodash";
import sIfPlural from "../../utils/sIfPlural";
import { safeUpsert, safeQuery } from "../../apolloMethods";
import { showDialog } from "../../GlobalDialog";
import { AutoAnnotateMenu } from "../../AutoAnnotate/AutoAnnotateMenu";
import appGlobals from "../../appGlobals";

import defaultAsyncWrap from "../../utils/defaultAsyncWrap";
import TooltipMessages from "../../constants/tooltips";
import { findSequencesWithDuplicateSequenceParts } from "../../utils/findSequencesWithDuplicateSequenceParts";
import AutoAnnotateOligosDialog from "../../components/Dialogs/AutoAnnotateOligosDialog";
import FeatureLibraryDialog from "../../components/Dialogs/FeatureLibraryDialog";
import ExportSequencesDialog from "../../ExportSequencesDialog";
import { pushHelper } from "../../utils/pushHelper";
import RegisterAnnotationDialog from "../../dialogs/RegisterAnnotationDialog";
import GeneratePartsFromDigest from "../../../src-design/components/Dialogs/GeneratePartsFromDigest";
import DuplicateSequenceContextMenuDialog from "../../../src-design/components/Dialogs/DuplicateSequenceContextMenuDialog";
import UpdateSequenceDialog from "../../components/Dialogs/UpdateSequenceDialog";

export const contextMenu = ({
  selectedRecords,
  refetch: refetchSequences,
  ...rest
}) => {
  const { selectTableRecords, isAdmin, isPowerUser, currentUser } = rest;

  const sequenceIds = selectedRecords.map(r => {
    return r.id;
  });

  const additionalMenuItems =
    isAdmin || isPowerUser
      ? [
          <MenuItem
            key="generatePartsFromFeatures"
            text={
              <Tooltip
                content={
                  <span>{TooltipMessages.GeneratePartsFromFeatures}</span>
                }
                position={Position.TOP}
              >
                Generate Parts from Features
              </Tooltip>
            }
            onClick={defaultAsyncWrap(async () => {
              if (
                !selectedRecords.reduce(
                  (acc, r) => acc + r.sequenceFeatures.length,
                  0
                )
              ) {
                return window.toastr.warning(
                  `The selected sequence${sIfPlural(selectedRecords)}
                  contain${sIfPlural(selectedRecords)} no features.`
                );
              }
              const sequenceId = selectedRecords[0].id;
              const features = await safeQuery(
                ["sequenceFeature", "id name start end strand type"],
                {
                  variables: {
                    filter: {
                      sequenceId
                    }
                  }
                }
              );

              showDialog({
                modalType: "GENERATE_PARTS_FROM_FEATURES",
                modalProps: {
                  title: "Generate Parts from Features",
                  features,
                  refetchSequences,
                  onSubmit: async (selectedFeatures, cutOutCodon) => {
                    const { data } = await window.serverApi.request({
                      method: "POST",
                      url: "/generateParts",
                      data: {
                        sequenceId,
                        generators: selectedFeatures.map(feat => {
                          return {
                            start: feat.start,
                            stop: feat.end,
                            increment: 3,
                            useInverse: true,
                            cutOutCodon
                          };
                        })
                      }
                    });

                    if (data.success) {
                      window.toastr.info("Parts generated successfully");
                    } else {
                      window.toastr.error(
                        data.msg
                          ? data.msg
                          : "Error generating parts from features"
                      );
                    }
                  }
                }
              });
            }, "Error generating parts from features.")}
          />
        ]
      : [];

  return [
    <MenuItem
      key="updateSequence"
      icon="edit"
      text="Update Sequence"
      onClick={() =>
        showDialog({
          ModalComponent: UpdateSequenceDialog,
          modalProps: {
            refetch: refetchSequences,
            selectedRecords,
            fromLibrary: true,
            dialogProps: {
              title: "Update Sequences"
            }
          }
        })
      }
    />,

    ...(selectedRecords.length === 1
      ? [
          <MenuItem
            key="duplicate"
            text="Duplicate"
            icon="duplicate"
            onClick={() => {
              showDialog({
                ModalComponent: DuplicateSequenceContextMenuDialog,
                modalProps: {
                  refetch: refetchSequences,
                  selectedRecords,
                  currentUser
                }
              });
            }}
          />
        ]
      : []),

    <MenuDivider key="md1" />,
    <MenuItem
      key="createPartsFromSequences"
      text={
        <Tooltip
          content={<span>{TooltipMessages.CreatePartFromSequence}</span>}
          position={Position.TOP}
        >{`Create Part${sIfPlural(
          selectedRecords
        )} from Sequence${sIfPlural(selectedRecords)}`}</Tooltip>
      }
      onClick={defaultAsyncWrap(
        async () => {
          const validRecords = selectedRecords.filter(r => r.size > 0);
          const invalidRecords = difference(selectedRecords, validRecords);
          invalidRecords.forEach(seq =>
            window.toastr.warning(
              `Cannot create part on sequence ${
                seq.name
              } because its length is ${seq.size === 0 ? 0 : "not defined"}.`
            )
          );

          const {
            sequencesWithoutDuplicatedParts,
            sequencesWithDuplicatedParts
          } = await findSequencesWithDuplicateSequenceParts(validRecords);

          const idToValidRecord = keyBy(validRecords, "id");

          const seqToPart = seq => {
            const { name, size } = idToValidRecord[seq.id];
            return {
              start: 0,
              end: size - 1,
              name,
              strand: 1,
              sequenceId: seq.id
            };
          };

          if (sequencesWithoutDuplicatedParts.length) {
            await safeUpsert(
              "part",
              sequencesWithoutDuplicatedParts.map(seqToPart)
            );
            await refetchSequences();
            window.toastr.success(
              `${sequencesWithoutDuplicatedParts.length} part${sIfPlural(
                sequencesWithoutDuplicatedParts
              )} created.`
            );
          }

          if (sequencesWithDuplicatedParts.length) {
            showDialog({
              modalType: "DUPLICATE_PARTS_FOUND",
              modalProps: {
                valuesToUpsert: sequencesWithDuplicatedParts.map(seqToPart),
                refetch: refetchSequences,
                idToSequence: idToValidRecord
              }
            });
          }
        },
        `Error creating part${sIfPlural(selectedRecords)} from sequence${sIfPlural(selectedRecords)}`
      )}
    />,
    <MenuItem
      key="createPartsFromFeatures"
      text={
        <Tooltip
          content={<span>{TooltipMessages.CreatePartsFromFeatures}</span>}
          position={Position.TOP}
        >
          Create Parts from Features
        </Tooltip>
      }
      onClick={defaultAsyncWrap(async () => {
        if (
          !selectedRecords.reduce(
            (acc, r) => acc + r.sequenceFeatures.length,
            0
          )
        ) {
          return window.toastr.warning(`The selected sequence${sIfPlural(
            selectedRecords
          )}
        contain${selectedRecords.length > 1 ? "" : "s"} no features.`);
        }
        showDialog({
          modalType: "CREATE_PARTS_FROM_FEATURES",
          modalProps: {
            sequenceIds: selectedRecords.map(r => r.id),
            onRefresh: refetchSequences
          }
        });
      }, "Error creating parts from features.")}
    />,
    <MenuItem
      key="generatePartsFromDigest"
      text={
        <Tooltip
          content={<span>{TooltipMessages.GeneratePartsFromDigest}</span>}
          position={Position.TOP}
        >
          Generate Parts from Digest
        </Tooltip>
      }
      onClick={defaultAsyncWrap(async () => {
        if (!selectedRecords.reduce((acc, r) => acc + r.size, 0)) {
          // need to change based on what warning should be
          return window.toastr.warning(`The selected sequence${sIfPlural(
            selectedRecords
          )}
      contain${selectedRecords.length > 1 ? "" : "s"} no base pairs.`);
        }
        showDialog({
          ModalComponent: GeneratePartsFromDigest,
          modalProps: {
            refetch: refetchSequences,
            sequenceIds: selectedRecords.map(r => r.id)
          }
        });
      }, "Error generating parts from digest.")}
    />,
    <MenuItem
      key="createFeaturesFromSequences"
      text={
        <Tooltip
          content={<span>{TooltipMessages.CreateFeatureFromSequence}</span>}
          position={Position.TOP}
        >{`Create Feature${sIfPlural(
          selectedRecords
        )} from Sequence${sIfPlural(selectedRecords)}`}</Tooltip>
      }
      onClick={defaultAsyncWrap(async () => {
        const validRecords = selectedRecords.filter(r => r.size > 0);
        const invalidRecords = difference(selectedRecords, validRecords);
        invalidRecords.forEach(seq =>
          window.toastr.warning(
            `Cannot create feature on sequence ${
              seq.name
            } because its length is ${seq.size === 0 ? 0 : "not defined"}.`
          )
        );

        if (validRecords.length) {
          showDialog({
            modalType: "CHOOSE_FEATURE_TYPE",
            modalProps: {
              onSubmit: defaultAsyncWrap(async type => {
                await safeUpsert(
                  "sequenceFeature",
                  validRecords.map(s => ({
                    name: s.name,
                    type,
                    strand: 1,
                    start: 0,
                    end: s.size - 1,
                    sequenceId: s.id
                  }))
                );

                await refetchSequences();
                selectTableRecords(validRecords.map(r => r.id));

                window.toastr.success(
                  `${validRecords.length} feature${sIfPlural(
                    validRecords
                  )} created.`
                );
              }, "Error creating features from sequences.")
            }
          });
        }
      }, "Error creating features from sequences.")}
    />,
    <MenuItem
      key="registerFeaturesFromSequences"
      text={
        "Register Features"
        // <Tooltip
        //   content={<span>{TooltipMessages.DeleteFeatureFromSequence}</span>}
        //   position={Position.TOP}
        // >
        // </Tooltip>
      }
      onClick={() => {
        showDialog({
          ModalComponent: RegisterAnnotationDialog,
          modalProps: {
            annotationType: "feature",
            tabId: "register-existing",
            buttonId: "Sequences",
            showLinkInToast: true,
            sequenceTableProps: {
              additionalTableProps: {
                selectAllByDefault: true,
                withSelectAll: true,
                safeQuery
              },
              tableParamOptions: {
                initialValues: {
                  reduxFormQueryParams: {
                    filters: [
                      {
                        selectedFilter: "inList", //camel case
                        filterOn: "id", //camel case display name
                        filterValue: selectedRecords
                          .map(({ id }) => id)
                          .join(",")
                      }
                    ]
                  }
                }
              }
            },
            annotationTableProps: {
              additionalTableProps: {
                selectAllByDefault: true,
                withSelectAll: true,
                safeQuery
              },
              tableParamOptions: {
                initialValues: {
                  reduxFormQueryParams: {
                    filters: [
                      {
                        selectedFilter: "inList", //camel case
                        filterOn: "sequenceId", //camel case display name
                        filterValue: selectedRecords
                          .map(({ id }) => id)
                          .join(",")
                      }
                    ]
                  }
                }
              }
            },
            refetch: () => {}
          }
        });
      }}
    />,
    <MenuItem
      key="deleteFeaturesFromSequences"
      text={
        <Tooltip
          content={<span>{TooltipMessages.DeleteFeatureFromSequence}</span>}
          position={Position.TOP}
        >
          Delete Features
        </Tooltip>
      }
      onClick={defaultAsyncWrap(async () => {
        if (
          !selectedRecords.reduce(
            (acc, r) => acc + r.sequenceFeatures.length,
            0
          )
        ) {
          return window.toastr.warning(`The selected sequence${sIfPlural(
            selectedRecords
          )}
        contain${sIfPlural(selectedRecords)} no features.`);
        }
        showDialog({
          modalType: "DELETE_FEATURES_FROM_SEQUENCES",
          modalProps: {
            sequenceIds: selectedRecords.map(r => r.id),
            onRefresh: refetchSequences
          }
        });
      }, "Error creating features from sequences.")}
    />,
    ...(selectedRecords.length > 1
      ? [
          <MenuItem
            key="alignSequences"
            text="Align Sequences"
            onClick={e => {
              pushHelper(
                e,
                `/alignments/new?seqId=${selectedRecords
                  .map(r => r.id)
                  .join(",")}`
              );
            }}
          />
        ]
      : []),
    <MenuDivider key="mdManual" />,
    <AutoAnnotateMenu
      selectedRecords={selectedRecords}
      refetch={refetchSequences}
      key="AutoAnnotateMenu"
    ></AutoAnnotateMenu>,
    ...(window.frontEndConfig.legacyAutoAnnotate
      ? [
          <MenuItem
            icon="add-row-top"
            key="manuallyAnnotate"
            text="Annotate with Existing"
          >
            <MenuItem
              key="manuallyAnnotateFeatures"
              text={
                <Tooltip
                  content={
                    <span>{TooltipMessages.ManuallyAnnotateFeatures}</span>
                  }
                  position={Position.TOP}
                >
                  Features
                </Tooltip>
              }
              onClick={() => {
                return autoAnnotateFeatures({
                  sequenceIds,
                  refetchSequences
                });
              }}
            />
            <MenuItem
              key="manuallyAnnotateParts"
              text={
                <Tooltip
                  content={<span>{TooltipMessages.ManuallyAnnotateParts}</span>}
                  position={Position.TOP}
                >
                  Parts
                </Tooltip>
              }
              onClick={() => {
                showDialog({
                  modalType: "PART_LIBRARY",
                  modalProps: {
                    withCheckboxes: true,
                    onSubmit: async selectedParts => {
                      const selectedPartIds = selectedParts.map(f => f.id);
                      try {
                        const seqMap = keyBy(selectedRecords, "id");

                        const {
                          data: { success, numberOfAddedPartsMap, err }
                        } = await window.serverApi.request({
                          method: "POST",
                          url: "/autoAnnotateParts",

                          data: {
                            sequenceIds: Object.keys(seqMap),
                            partIds: selectedPartIds,
                            userId: localStorage.getItem("userId")
                          }
                        });
                        if (!success) throw new Error(err);

                        const msg = Object.keys(numberOfAddedPartsMap)
                          .map(
                            seqId =>
                              `${seqMap[seqId].name}: ${numberOfAddedPartsMap[seqId]} parts added`
                          )
                          .join(",\n");
                        await refetchSequences();
                        window.toastr.success(msg);
                      } catch (e) {
                        console.error(e);
                        window.toastr.error("Error auto annotating.");
                      }
                    }
                  }
                });
              }}
            />

            <MenuItem
              key="manuallyAnnotateOligos"
              text={
                <Tooltip
                  content={
                    <span>{TooltipMessages.ManuallyAnnotateOligos}</span>
                  }
                  position={Position.TOP}
                >
                  Oligos
                </Tooltip>
              }
              onClick={() => {
                return autoAnnotateOligos({
                  sequenceIds,
                  refetchSequences
                });
              }}
            />
          </MenuItem>
        ]
      : []),
    <MenuDivider key="md2" />,
    <MenuItem
      key="export"
      icon="export"
      text="Export Sequences"
      onClick={() => {
        showDialog({
          ModalComponent: ExportSequencesDialog,
          modalProps: { sequenceIds }
        });
      }}
    />
  ].concat(additionalMenuItems);
};

export const autoAnnotateFeatures = ({
  resolve,
  reject,
  autoAnnotateParts,
  sequenceIds,
  refetchSequences
}) => {
  showDialog({
    shouldNotHideModal: autoAnnotateParts,
    ModalComponent: FeatureLibraryDialog,
    resolve,
    reject,
    modalProps: {
      title: "Select Features From Existing Sequences",
      additionalHeaderDiv: (
        <div>
          Note, features of length less than{" "}
          {window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_FEATURE_LENGTH}{" "}
          will not appear here. This can be configured by an admin in App
          Settings
        </div>
      ),
      onSubmit: async selectedFeatures => {
        const selectedFeatureIds = selectedFeatures.map(f => f.id);
        try {
          const sequences = await safeQuery(["sequence", "id name"], {
            isPlural: true,
            variables: { filter: { id: sequenceIds } }
          });
          const seqMap = keyBy(sequences, "id");

          const {
            data: {
              success,
              numberOfAddedFeaturesMap,
              tooSmallAnnotations,
              err
            }
          } = await window.serverApi.request({
            method: "POST",
            url: "/autoAnnotateFeatures",
            data: {
              sequenceIds: Object.keys(seqMap),
              featureIds: selectedFeatureIds,
              userId: appGlobals.currentUser.id
            }
          });
          if (!success) throw new Error(err);

          //TNR this is a terrible user experience. this should happen at the beginning
          if (tooSmallAnnotations.length > 0) {
            window.toastr.warning(
              `These features: "${tooSmallAnnotations
                .map(a => a.name)
                .join(", ")}" were too small to be auto-annotated`
            );
          }

          const msg = Object.keys(numberOfAddedFeaturesMap)
            .map(
              seqId =>
                `${seqMap[seqId].name}: ${numberOfAddedFeaturesMap[seqId]} features added`
            )
            .join(",\n");

          if (!msg) return;
          refetchSequences && (await refetchSequences());
          window.toastr.success(msg);
          resolve && resolve(true);
        } catch (e) {
          reject && reject(e);
          console.error(e);
          window.toastr.error("Error auto annotating features.");
        }
      }
    }
  });
};

export const autoAnnotateOligos = ({ sequenceIds, refetchSequences }) => {
  showDialog({
    ModalComponent: AutoAnnotateOligosDialog,
    modalProps: {
      title: "Choose Oligos",
      modalProps: {
        sequenceIds,
        refetchSequences
      }
    }
  });
};
