/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * NOTE: This file should be redesigned in the future for better accomodating the different
 * vendors scoring responses and behaviours. One change could be to put everything related to a specific vendor under
 * the same folder. For example, each of the vendors params could go in a file under the specific
 * vendor folder.
 **/
import Promise from "bluebird";
import { SubmissionError } from "redux-form";
import { splitPlasmidInsertAndBackbone } from "./plasmidUtils";
import api from "../../../../src-shared/api";
import { get, pick, range, isEmpty } from "lodash";

const MAX_SEQ_LENGTH_ACCEPTED = 5000;
const TWIST_API_SEQ_LENGTH_ERROR_TEXT =
  '["NON_CLONED_GENE length should be between 300 and 1800"]';
export const VENDOR_LENGTH_CONSTRAINT_ERROR = {
  name: "VENDOR_LENGTH_CONSTRAINT_ERROR",
  message: "One or more material(s) do not fit the vendor's length constraint."
};

export const getVendorParams = name => {
  switch (name) {
    case "TWIST":
      return TwistParams();
    case "IDT":
      return IDTParams();
    case "GENSCRIPT":
      return GenscriptParams();
    default:
      if (process.env.DEBUG_DNA_SCORING) {
        const err = `Error in fetching params: Vendor with name: '${name}' is not supported yet.`;
        console.error(err);
      }
      throw new SubmissionError({
        _error: `Error in fetching params for vendor ${name}`
      });
  }
};

export const GenscriptParams = () => {
  const self = {
    vendorTitle: "GenScript",
    vendorCode: "GENSCRIPT",
    apiUrl: "/orderingVendors/genscript/",
    buildableScore: "BUILDABLE",
    unbuildableScore: "UNBUILDABLE",
    minAcceptedMaterialLengthPlasmid: 300,
    maxAcceptedMaterialLengthPlasmid: 3200,
    minAcceptedMaterialLengthLinear: 150,
    maxAcceptedMaterialLengthLinear: 3200,
    supportsOligos: false,
    minAcceptedMaterialLengthOligo: null,
    maxAcceptedMaterialLengthOligo: null,
    dataTableTypeCode: "GENSCRIPT_PLATE_ORDER_FORM",
    plasmidDataTableTypeCode: "GENSCRIPT_PLASMID_ORDER_FORM",
    productCodes: ["SC1010"]
  };
  return Object.assign(self, canValidateMaterialLength(self));
};

export const TwistParams = () => {
  const self = {
    vendorTitle: "Twist Bioscience",
    vendorCode: "TWIST",
    apiUrl: "/orderingVendors/twist/",
    buildableScore: "BUILDABLE",
    unbuildableScore: "UNBUILDABLE",
    minAcceptedMaterialLengthPlasmid: 300,
    maxAcceptedMaterialLengthPlasmid: 3200,
    minAcceptedMaterialLengthLinear: 300,
    maxAcceptedMaterialLengthLinear: 1800,
    supportsOligos: false,
    minAcceptedMaterialLengthOligo: null,
    maxAcceptedMaterialLengthOligo: null,
    dataTableTypeCode: "TWIST_PLATE_ORDER_FORM",
    plasmidDataTableTypeCode: "TWIST_PLASMID_ORDER_FORM",
    productCodes: ["GEN_FRG_BP_0BP_1.8KB"]
  };
  return Object.assign(self, canValidateMaterialLength(self));
};

export const IDTParams = () => {
  const self = {
    vendorTitle: "Integrated DNA Technologies",
    vendorCode: "IDT",
    apiUrl: "/orderingVendors/idt/",
    buildableScore: "BUILDABLE",
    unbuildableScore: "UNBUILDABLE",
    supportsOligos: true,
    minAcceptedMaterialLengthPlasmid: 25,
    maxAcceptedMaterialLengthPlasmid: 5000,
    minAcceptedMaterialLengthLinear: 125,
    maxAcceptedMaterialLengthLinear: 3000,
    minAcceptedMaterialLengthOligo: 5,
    maxAcceptedMaterialLengthOligo: 100,
    maxOrderingPlateRowCount: 8,
    dataTableTypeCode: "IDT_PLATE_ORDER_FORM",
    plasmidDataTableTypeCode: null
  };
  return Object.assign(self, canValidateMaterialLength(self));
};

const canValidateMaterialLength = self => ({
  checkMaterialLength: (
    polynucleotideMaterialSequenceSize,
    sequenceTypeCode,
    useMaxLinear
  ) => {
    let minLength = null;
    let maxLength = null;

    switch (sequenceTypeCode) {
      case "CIRCULAR_DNA":
        minLength = self.minAcceptedMaterialLengthPlasmid;
        maxLength = self.maxAcceptedMaterialLengthPlasmid;
        if (polynucleotideMaterialSequenceSize === "NO_INSERT") {
          return {
            isLengthAccepted: false,
            message: `Plasmid insert could not be identified, ensure sequence feature is present to allow scoring.`,
            value: [polynucleotideMaterialSequenceSize, sequenceTypeCode]
          };
        }
        break;
      case "LINEAR_DNA":
        minLength = self.minAcceptedMaterialLengthLinear;
        maxLength = useMaxLinear
          ? self.maxAcceptedMaterialLengthLinear
          : MAX_SEQ_LENGTH_ACCEPTED;
        break;
      case "OLIGO":
        minLength = self.minAcceptedMaterialLengthOligo;
        maxLength = self.maxAcceptedMaterialLengthOligo;
        if (!self.supportsOligos) {
          return {
            isLengthAccepted: false,
            message: `Vendor ${self.vendorTitle} does not support Oligos at this time.`,
            value: [polynucleotideMaterialSequenceSize, sequenceTypeCode]
          };
        }
        break;
      default:
        return {
          isLengthAccepted: false,
          message: `DNA is of unrecognized/unsupported type ${sequenceTypeCode}`,
          value: [polynucleotideMaterialSequenceSize, sequenceTypeCode]
        };
    }

    const linearOrCircular =
      sequenceTypeCode === "CIRCULAR_DNA" ? "circular" : "linear";
    if (polynucleotideMaterialSequenceSize < minLength) {
      return {
        isLengthAccepted: false,
        message: `Length of ${linearOrCircular} sequence must be at least ${minLength} for ${self.vendorTitle}`,
        value: [polynucleotideMaterialSequenceSize, sequenceTypeCode]
      };
    } else if (polynucleotideMaterialSequenceSize > maxLength) {
      return {
        isLengthAccepted: false,
        message: `Length of ${linearOrCircular} sequence can be at most ${maxLength} for ${self.vendorTitle}`,
        value: [polynucleotideMaterialSequenceSize, sequenceTypeCode]
      };
    } else {
      return {
        isLengthAccepted: true,
        message: `Material length is within ${self.vendorTitle}'s parameters for type ${sequenceTypeCode}`,
        value: [polynucleotideMaterialSequenceSize, sequenceTypeCode]
      };
    }
  }
});

export const getVendorId = async vendorCode => {
  const codeToId = {
    TWIST: "&twist_cid",
    IDT: "&idt_cid"
  };
  return codeToId[vendorCode];
};

/**
 * NOTE: RIGHT NOW THIS IS JUST HANDLING ON A NICE WAY CASES WHERE THERE ARE
 * SEQUENCES LONGER THAN ACCEPTED WITHOUT VECTORS WITH TWIST. CASES WHEN THE
 * SEQUENCES CONTAIN LETTERS, LIKE "N", SHOULD BE BETTER HANDLED.
 *
 * Scores the given materials with a given vendor (posting the materials to their API)
 * and when it finishes it pass the results to a handleScoredMaterial callback.
 * If there is a material that doesn't fit the given vendor's length constraints it
 * will throw a "One or more material(s) don't fit the vendor's length constraint." error.
 *
 * @param {String} vendorCode The code of the vendor that will be used for scoring the
 *                            material.
 * @param {Array} materials   An array containing the materials that will be scored.
 * @param {Function} handleScoredMaterial The callback function that will be used for
 *                                        handling the scored materials.
 * @throws Will throw the following error "One or more material(s) don't fit the vendor's
 *  length constraint." if the described condition is met.
 */
export const scoreMaterials = async (
  vendorCode,
  materials,
  handleScoredMaterials
) => {
  const vendorParams = getVendorParams(vendorCode);

  materials.forEach(material => {
    const lengthInfo = vendorParams.checkMaterialLength(
      material.total_bps,
      get(material, "type")
    );

    if (!lengthInfo.isLengthAccepted) {
      throw new Error(
        "One or more material(s) do not fit the vendor's length constraint."
      );
    }
  });

  let args = {};

  if (vendorParams.productCodes) {
    args = { productCode: vendorParams.productCodes[0] };
  }

  return api
    .request({
      url: "/orderingVendors/scoring",
      method: "POST",
      withCredentials: true,
      data: {
        vendorCode: vendorCode,
        materials: materials,
        args
      }
    })
    .catch(err => {
      throw new SubmissionError({
        _error: `Error in scoreMaterials with vendor ${vendorCode}: ${err}`
      });
    })
    .then(response => {
      const scoredSequences = response.data.result;
      let seqsLongerThanWhatVendorExpects = false;
      if (scoredSequences === {} || scoredSequences === "") {
        if (process.env.DEBUG_DNA_SCORING) {
          console.error(
            "Empty results returned from vendor API:",
            scoredSequences
          );
        }
        const errMsg = `Scoring Materials Unsuccessful with vendor ${vendorCode}: No data returned from API call. Check server console for errors.`;
        window.toastr.error(errMsg);
        return handleScoredMaterials("", seqsLongerThanWhatVendorExpects);
      }
      if (scoredSequences.error) {
        if (process.env.DEBUG_DNA_SCORING) {
          console.error(
            `Uncaught error in scoreMaterials with vendor ${vendorCode}`
          );
        }
        return Promise.reject(scoredSequences.error);
      } else if (scoredSequences.status !== 200) {
        if (scoredSequences.response.text === TWIST_API_SEQ_LENGTH_ERROR_TEXT) {
          seqsLongerThanWhatVendorExpects = true;
          return handleScoredMaterials(
            scoredSequences,
            seqsLongerThanWhatVendorExpects
          );
        } else {
          const vendorErrorMsg = `${vendorCode}: ${scoredSequences.response.text}`;
          window.toastr.error(vendorErrorMsg);
          return handleScoredMaterials("", seqsLongerThanWhatVendorExpects);
        }
      } else {
        return handleScoredMaterials(
          scoredSequences,
          seqsLongerThanWhatVendorExpects
        );
      }
    });
};

export const postSequencesForScoring = async (
  vendorCode,
  materials,
  handlePostedScoringTasksIds
) => {
  const vendorParams = getVendorParams(vendorCode);

  materials.forEach(material => {
    const lengthInfo = vendorParams.checkMaterialLength(
      material.total_bps,
      get(material, "type")
    );

    if (!lengthInfo.isLengthAccepted) {
      throw VENDOR_LENGTH_CONSTRAINT_ERROR;
    }
  });

  let args = {};

  if (vendorParams.productCodes) {
    args = { productCode: vendorParams.productCodes[0] };
  }

  return api
    .request({
      url: "/orderingVendors/scoring",
      method: "POST",
      withCredentials: true,
      data: {
        vendorCode: vendorCode,
        materials: materials,
        args
      }
    })
    .catch(err => {
      throw new SubmissionError({
        _error: `Error in scoreMaterials with vendor ${vendorCode}: ${err}`
      });
    })
    .then(response => {
      if (!isEmpty(response.data) && response.status === 200) {
        handlePostedScoringTasksIds(response);
      } else {
        console.error(
          `There was an error posting the sequences for ${vendorCode}`
        );
      }
    });
};

export const getVendorVectorList = vendorCode => {
  return api
    .request({
      url: "/orderingVendors/vectors",
      method: "post",
      withCredentials: true,
      data: {
        vendorCode: vendorCode
      }
    })
    .catch(err => {
      throw new SubmissionError({
        _error: `Error in fetching vector list from vendor ${vendorCode}: ${err}`
      });
    })
    .then(({ data }) => {
      if (data.status && data.status !== 200) {
        throw new SubmissionError({
          _error: `Error in fetching vector list from vendor ${vendorCode} [Status: ${data.status}]`
        });
      }
      return data;
    });
};

export const formatSequencesForExporting = (
  vendorCode,
  orderName,
  sequences
) => {
  switch (vendorCode) {
    case "TWIST":
      //TODO: this function assumes it is only ever passed an array of
      // ALL THE **SAME TYPE** OF DNA MATERIAL!!
      const sequencesFormattedForExportTwist = sequences.map(sequence => {
        const auxSequence = pick(sequence, "name", "sequence");
        if (get(sequence, "sequenceTypeCode") === "PLASMID") {
          return Object.assign(
            auxSequence,
            { constructId: sequence.id },
            ...splitPlasmidInsertAndBackbone(sequence.queriedMaterial)
          );
        }
        return auxSequence;
      });
      return sequencesFormattedForExportTwist;
    case "IDT":
      const wellNamesArray = generateWellNamesArrays(sequences.length); //returns [ 1, "A1" ] per well
      const sequencesFormattedForExportIDT = sequences.map(
        (sequence, index) => {
          const strippedSequence = pick(sequence, "name", "sequence");
          const auxSequence = Object.assign(
            {},
            { wellPosition: wellNamesArray[index][1] },
            strippedSequence,
            { plateIndex: wellNamesArray[index][0] },
            {
              plateName: `TeselaGen Order ${
                orderName || ""
              } ${new Date().toDateString()}`
            },
            { productCode: sequence.pricing.product_code }
          );
          // Not currently utilized, but here for future implementation
          // if (get(material, "queriedMaterial.polynucleotideMaterialSequence.sequenceTypeCode") === "CIRCULAR_DNA") {
          //   return Object.assign(
          //     auxSequence,
          //     { constructId: material.queriedMaterial.id },
          //     ...splitPlasmidInsertAndBackbone(material.queriedMaterial)
          //    )
          // }
          return auxSequence;
        }
      );
      return sequencesFormattedForExportIDT;
    case "GENSCRIPT":
      return sequences.map(sequence => {
        const strippedSequence = pick(
          sequence,
          "name",
          "sequence",
          "total_bps"
        );
        const auxSequence = Object.assign(
          {},
          {
            DNA_SEQUENCE_NAME: strippedSequence["name"],
            SYNTHON_SEQUENCE: strippedSequence["sequence"],
            SYNTHON_LENGTH: strippedSequence["total_bps"],
            VENDOR_CUSTOM_VECTOR_ID: ""
          }
        );
        return auxSequence;
      });
    default:
      if (process.env.DEBUG_DNA_SCORING) {
        const err = `Error in Formatting sequences for Exporting: Vendor with name: '${vendorCode}' is not supported yet.`;
        console.error(err);
      }
      throw new SubmissionError({
        _error: `Error in formatting sequences for exporting with vendor ${vendorCode}`
      });
  }
};

const generateWellNamesArrays = function (
  wellCount,
  plateRows = 8,
  plateCols = 12,
  isColMajor = true
) {
  const maxWellsPerPlate = plateRows * plateCols;
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = range(1, 100);
  let plateCount = 1;
  const wellNames = [];
  for (let i = 0; i < wellCount; i++) {
    plateCount = i > maxWellsPerPlate ? plateCount + 1 : plateCount;
    if (isColMajor) {
      const l = letters.charAt(i % plateRows);
      const n = numbers[Math.floor(i / plateCols)];
      wellNames.push([plateCount, String(l) + String(n)]);
    } else {
      //row-major
      const l = letters.charAt(Math.floor(i / plateRows));
      const n = numbers[i % plateCols];
      wellNames.push([plateCount, String(l) + String(n)]);
    }
  }
  return wellNames;
};
