/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { Dialog, Classes, Intent, Button } from "@blueprintjs/core";
import { DataTable, DialogFooter, withSelectedEntities } from "@teselagen/ui";
import { uniqBy, keyBy, map, reduce } from "lodash";
import "./style.css";

class DuplicateSequenceDialog extends React.Component {
  state = {
    loading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

    const { sequences = [], duplicateInfo } = this.props;

    this.sequenceEntities = sequences
      .filter(seq => duplicateInfo[seq.id] || duplicateInfo[seq.hash])
      .map((seq, i) => {
        const info = duplicateInfo[seq.id] || duplicateInfo[seq.hash];

        const matchesOnDb = info.dbSequence.length || info.dbName.length;
        const matchesOnImport =
          info.importSequence.length || info.importName.length;
        const matchesOnName = info.importName.length || info.dbName.length;
        const matchesOnSequence =
          info.dbSequence.length || info.importSequence.length;

        let matchType;
        if (matchesOnDb && matchesOnImport) matchType = "Record in Import & DB";
        else if (matchesOnImport) matchType = "Record in Import";
        else if (matchesOnDb) matchType = "Record in DB";

        let identicalOn;
        if (matchesOnName && matchesOnSequence) identicalOn = "Bps & Name";
        else if (matchesOnSequence) identicalOn = "Bps";
        else if (matchesOnName) identicalOn = "Name";
        return {
          id: i + 1,
          sequenceId: seq.id,
          hash: seq.hash,
          name: seq.name,
          matches: uniqBy(
            [
              ...info.importSequence,
              ...info.importName,
              ...info.dbSequence,
              ...info.dbName
            ],
            "id"
          )
            .map(s => s.name)
            .join(", "),
          matchType,
          identicalOn
        };
      });

    // reduce duplicated keys by hash (or id) found on db
    const entKeys = reduce(
      this.sequenceEntities,
      (res, val) => {
        if (!res[val.hash || val.sequenceId])
          res[val.hash || val.sequenceId] = val;
        return res;
      },
      {}
    );
    this.sequenceEntities = map(entKeys);
  }

  handleCancel = () => {
    const { hideModal, resolve } = this.props;
    hideModal();
    if (resolve) resolve();
  };

  getMatchTypesForSequence = seq => {
    const { duplicateInfo } = this.props;
    const info = duplicateInfo[seq.id || seq.hash];
    return {
      matchesOnDb: info.dbSequence.length || info.dbName.length,
      matchesOnImport: info.importSequence.length || info.importName.length,
      matchesOnName: info.importName.length || info.dbName.length,
      matchesOnSequence: info.dbSequence.length || info.importSequence.length
    };
  };

  onContinue = selectedEntities => async () => {
    const {
      sequences,
      onSubmit,
      hideModal,
      shouldNotHideModal,
      duplicateInfo
    } = this.props;
    const selectedEntitiesMap = keyBy(selectedEntities, "sequenceId");
    const selectedEntitiesByHash = keyBy(selectedEntities, "hash");

    const selectedById = sequences.filter(
      s => !duplicateInfo[s.id] || selectedEntitiesMap[s.id]
    );

    const unselectedById = sequences.filter(
      s => duplicateInfo[s.id] && !selectedEntitiesMap[s.id]
    );

    const selectedByHash = sequences.filter(
      s => !duplicateInfo[s.hash] || selectedEntitiesByHash[s.hash]
    );

    const unselectedByHash = sequences.filter(
      s => duplicateInfo[s.hash] && !selectedEntitiesByHash[s.hash]
    );

    let selected = [];
    let unselected = [];

    if (selectedById.length === selectedEntities.length) {
      selected = selectedById;
      unselected = unselectedById;
    } else {
      selected = selectedByHash;
      unselected = unselectedByHash;
    }

    const items = { selected, unselected };
    this.setState({
      loading: true
    });
    await onSubmit(items);
    this.setState({
      loading: false
    });

    if (!shouldNotHideModal || !selected.length) hideModal();
  };

  render() {
    const { duplicateSequenceDialogTableSelectedEntities } = this.props;

    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={this.handleCancel}
        title="Duplicate Sequence Found"
        style={{ width: 950 }}
        className="dup-seq-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          Select duplicates for import
          <DataTable
            formName="duplicateSequenceDialogTable"
            schema={schema}
            withCheckboxes
            entities={this.sequenceEntities}
            doNotShowEmptyRows
          />
        </div>
        <DialogFooter
          hideModal={this.handleCancel}
          additionalButtons={
            <Button
              intent={Intent.PRIMARY}
              minimal
              loading={this.state.loading}
              text="Continue with importing duplicates"
              onClick={this.onContinue(
                duplicateSequenceDialogTableSelectedEntities
              )}
              disabled={!duplicateSequenceDialogTableSelectedEntities.length}
            />
          }
          text="Continue without importing duplicates"
          loading={this.state.loading}
          onClick={this.onContinue([])}
        />
      </Dialog>
    );
  }
}

const schema = {
  model: "sequence",
  fields: [
    { path: "name", type: "string", displayName: "Sequence Name" },
    { path: "matches", type: "string", displayName: "Matches" },
    { path: "matchType", type: "string", displayName: "Match Type" },
    { path: "identicalOn", type: "string", displayName: "Identical On" }
  ]
};

export default compose(withSelectedEntities("duplicateSequenceDialogTable"))(
  DuplicateSequenceDialog
);
