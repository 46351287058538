/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
// import { guessIfSequenceIsDnaAndNotProtein } from "@teselagen/sequence-utils";
import { isoContext } from "@teselagen/utils";
import { keyBy, get } from "lodash";
import { aaSequenceJSONtoGraphQLInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import {
  checkBarcodesAndFormat,
  maxWellVolumeError
} from "../utils/plateUtils";
import { taggedItems, extendedPropertyUploadFragment } from "./helperFragments";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import { checkDuplicateSequences } from "../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import { showAminoAcidStrippedCharacterWarningDialog } from "../../../tg-iso-shared/src/sequence-import-utils/showAminoAcidStrippedCharacterWarningDialog";
import upsertUniqueAliases from "../../../tg-iso-shared/src/sequence-import-utils/upsertUniqueAliases";
// import { getSequence } from "../../../tg-iso-shared/src/utils/getSequence";
import unitGlobals from "../unitGlobals";

export const requiredHeaders = [
  "PLATE_NAME",
  "WELL_LOCATION",
  "FPU_ID",
  "AA_ID",
  "AA_SEQUENCE",
  "TOTAL_VOLUME",
  "TOTAL_VOLUMETRIC_UNIT"
];

export default async function handleProteinPlateImport(
  values,
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;
  const { generateTubeBarcodes } = values;
  const {
    finishPlateCreate,
    aliquotContainerType,
    containerArrayType,
    filename,
    csvData,
    getCsvRowExtProps
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      nameHeader: "PLATE_NAME",
      barcodeHeader: "PLATE_BARCODE",
      hasReagents: true,
      hasExtendedProperties: true,
      requiredFields: requiredHeaders
    },
    ctx
  );

  const isRack = !containerArrayType.isPlate;

  const mapPlates = {};
  const proteinSubUnitsToCreate = [];
  const sequenceAliases = [];
  const proteinsToCreate = [];
  const sequenceFpusToCreate = [];

  const aminoAcidSequenceUpdates = [];
  const updatedAa = {};
  const proteinMaterialsToCreate = [];
  const alreadyManagedExistingProtein = {};
  const addedPropsForAminoAcid = {};
  const seenFpuMap = {};
  const fpuMWExtMap = {};

  const continueUpload = await checkBarcodesAndFormat({
    data: csvData,
    generateTubeBarcodes,
    filename,
    containerArrayType,
    tubeBarcodeKey: "TUBE_BARCODE",
    barcodeKey: "PLATE_BARCODE",
    wellPositionHeader: "WELL_LOCATION"
  });
  if (!continueUpload) return;

  const aaHashes = [];
  const relevantPlasmidNames = [];
  const fpuNames = [];

  const keyedAaSeqsToCreate = {};

  const strippedAATracker = [];
  for (const [index, row] of csvData.entries()) {
    const { AA_SEQUENCE, RELEVANT_PLASMID, FPU_ID } = row;

    const aaSequence = aaSequenceJSONtoGraphQLInput(
      {
        sequence: AA_SEQUENCE
      },
      {
        strippedAATracker
      }
    );
    if (!aaSequence.size) {
      throw new Error(
        `Row ${index + 1} did not provide a valid amino acid sequence.`
      );
    }
    // if (guessIfSequenceIsDnaAndNotProtein(getSequence(aaSequence))) {
    //   throw new Error(
    //     `Row ${index + 1} did not provide a valid amino acid sequence.`
    //   );
    // }

    if (!keyedAaSeqsToCreate[aaSequence.hash]) {
      aaSequence.cid = shortid();
      keyedAaSeqsToCreate[aaSequence.hash] = aaSequence;
    }
    row.aminAcidSequenceId = `&${keyedAaSeqsToCreate[aaSequence.hash].cid}`;
    row.newAmino = keyedAaSeqsToCreate[aaSequence.hash];
    row.aaSequenceHash = aaSequence.hash;
    aaHashes.push(aaSequence.hash);
    if (RELEVANT_PLASMID) {
      relevantPlasmidNames.push(RELEVANT_PLASMID);
    }
    fpuNames.push(FPU_ID);
  }

  if (strippedAATracker.length) {
    const continueUpload =
      await showAminoAcidStrippedCharacterWarningDialog(strippedAATracker);
    if (!continueUpload) {
      return;
    }
  }

  const existingAAs = await checkDuplicateSequences(
    aaHashes,
    {
      fragment: `
        id
        name
        molecularWeight
        extinctionCoefficient
        hash
        inventoryItems {
          id
        }
        codingDnaSequences {
          id
          name
          inventoryItems {
            id
          }
          codingDnaSequenceSequenceCodingSequences {
            id
            sequenceId
          }
        }
        ${taggedItems}
        aliases {
          id
          name
        }
        ${extendedPropertyUploadFragment}
        `,
      isProtein: true
    },
    ctx
  );

  const existingPlasmids = relevantPlasmidNames.length
    ? await safeQuery(
        ["sequence", "id name sequenceFpus { id functionalProteinUnitId }"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "sequence",
              "name",
              relevantPlasmidNames
            )
          }
        }
      )
    : [];

  const existingFpus = fpuNames.length
    ? await safeQuery(
        [
          "functionalProteinUnit",
          `id
        name
        sequenceFpus {
          id
          sequenceId
        }
        proteinMaterial {
          id
          ${taggedItems}
        }
        ${taggedItems}
        ${extendedPropertyUploadFragment}
        `
        ],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "functionalProteinUnit",
              "name",
              fpuNames
            )
          }
        }
      )
    : [];

  const keyedExistingFpus = keyBy(existingFpus, fpu => fpu.name.toLowerCase());
  const keyedExistingAAs = keyBy(existingAAs, "hash");
  const keyedExistingPlasmids = keyBy(existingPlasmids, plasmid =>
    plasmid.name.toLowerCase()
  );

  for (const [index, row] of csvData.entries()) {
    const {
      PLATE_NAME: plateName,
      PLATE_BARCODE: plateBarcode,
      TUBE_BARCODE: tubeBarcode,
      FPU_ID: fpuId,
      AA_ID: aaId,
      SAMPLE_NAME: sampleName,
      PROTEIN_SUBUNIT_NAME: proteinSubunitName,
      RELEVANT_PLASMID: relevantPlasmid = "",
      TOTAL_VOLUME: volume,
      TOTAL_VOLUMETRIC_UNIT: volumeUnit,
      PDB_ID: pdbId,
      DESIGN_ID: designId,
      UNIPROT_ID: uniprotId,
      HIS_TAG_LOCATION: hisTagLoc,
      NER: ner,
      GER: ger,
      "AA_PI_(ISOELECTRIC_POINT)": isoPoint,
      // AA_MW,
      // "AA_EXT_COEFF_(SS_FORMED)": AAExtinctionCoefficient,
      COFACTOR: cofactor,
      aaSequenceHash,
      aminAcidSequenceId: aminoAcidSequenceCidToId,
      newAmino,
      additives,
      rowPosition,
      columnPosition,
      rowKey
    } = row;

    // this is incase they have repeated rows with different values, only take one
    keyedAaSeqsToCreate[aaSequenceHash] = {
      ...keyedAaSeqsToCreate[aaSequenceHash],
      commonId: aaId,
      name: aaId,
      hisTagLoc,
      isoPoint,
      uniprotId
    };
    const aminoAcidSequence = keyedAaSeqsToCreate[aaSequenceHash];
    const existingAASequence = keyedExistingAAs[aaSequenceHash];

    const existingPlasmid =
      keyedExistingPlasmids[relevantPlasmid.trim().toLowerCase()];

    if (relevantPlasmid && !existingPlasmid) {
      throw new Error(
        `Row ${
          index + 1
        } specifies the plasmid ${relevantPlasmid} which was not found.`
      );
    } else if (relevantPlasmid && !existingAASequence) {
      throw new Error(
        `${aaId} was not found. When providing a relevant plasmid, its relevant amino acid sequence must exist.`
      );
    }

    let validPlasmid = false;
    const codingDnaSequencePlasmidIds = [];
    if (existingAASequence) {
      // eslint-disable-next-line no-loop-func
      existingAASequence.codingDnaSequences.forEach(cds => {
        if (cds.codingDnaSequenceSequenceCodingSequences.length > 0) {
          cds.codingDnaSequenceSequenceCodingSequences.forEach(sequenceCds => {
            codingDnaSequencePlasmidIds.push(sequenceCds.sequenceId);
          });
        }
      });
    }
    if (
      existingPlasmid &&
      codingDnaSequencePlasmidIds.includes(existingPlasmid.id)
    ) {
      validPlasmid = true;
    }

    if (existingPlasmid && !validPlasmid) {
      throw new Error(
        `Plasmid (${existingPlasmid.name}) does not match coding DNA sequences of corresponding amino acid sequence.`
      );
    }

    const aminoAcidSequenceId = existingAASequence
      ? existingAASequence.id
      : aminoAcidSequenceCidToId;

    if (existingAASequence) {
      let updated = false;
      if (!updatedAa[existingAASequence.id]) {
        updatedAa[existingAASequence.id] = true;
        updated = true;
        const aaSequenceUpdate = {
          id: existingAASequence.id,
          commonId: aaId,
          name: aaId,
          hisTagLoc,
          isoPoint,
          uniprotId
        };
        aminoAcidSequenceUpdates.push(aaSequenceUpdate);
      }

      if (
        existingAASequence &&
        existingAASequence.name !== aminoAcidSequence.name
      ) {
        // they could have a weird mix of names
        sequenceAliases.push({
          name: updated ? existingAASequence.name : aminoAcidSequence.name,
          aminoAcidSequenceId: existingAASequence.id
        });
      }
    }

    if (!addedPropsForAminoAcid[aaSequenceHash]) {
      addedPropsForAminoAcid[aaSequenceHash] = true;

      getCsvRowExtProps({
        row,
        modelTypeCode: "AMINO_ACID_SEQUENCE",
        recordId: aminoAcidSequenceId,
        record: existingAASequence || aminoAcidSequence,
        typeFilter: "amino acid sequence"
      });
    }

    const fpuCid = shortid();
    const fpu = {
      cid: fpuCid,
      commonId: fpuId,
      name: fpuId,
      designId,
      ner,
      ger,
      pdbId,
      cofactor
    };
    const materialCid = shortid();
    const existingProtein = keyedExistingFpus[fpuId.trim().toLowerCase()];
    let createFPU = !existingProtein;
    if (!seenFpuMap[fpuId]) {
      seenFpuMap[fpuId] = {
        count: 1,
        cid: fpuCid,
        materialCid
      };
    } else {
      createFPU = false;
    }

    if (!fpuMWExtMap[fpuId]) {
      fpuMWExtMap[fpuId] = {
        molecularWeight: 0,
        extinctionCoefficient: 0
      };
    }

    const aaToUse = existingAASequence || newAmino;

    fpuMWExtMap[fpuId].molecularWeight += aaToUse.molecularWeight || 0;
    fpuMWExtMap[fpuId].extinctionCoefficient +=
      aaToUse.extinctionCoefficient || 0;

    const functionalProteinUnitId = existingProtein
      ? existingProtein.id
      : `&${seenFpuMap[fpuId].cid}`;

    proteinSubUnitsToCreate.push({
      name: proteinSubunitName || fpuId,
      index: seenFpuMap[fpuId].count++,
      functionalProteinUnitId,
      aminoAcidSequenceId
    });

    const existingProteinNoMaterial =
      existingProtein &&
      !existingProtein.proteinMaterial &&
      !alreadyManagedExistingProtein[existingProtein.id];
    const materialId =
      get(existingProtein, "proteinMaterial.id") ||
      `&${seenFpuMap[fpuId].materialCid}`;
    if (createFPU || existingProteinNoMaterial) {
      proteinMaterialsToCreate.push({
        cid: seenFpuMap[fpuId].materialCid,
        name: fpuId,
        functionalProteinUnitId,
        materialTypeCode: "PROTEIN"
      });
    }

    if (createFPU) {
      proteinsToCreate.push(fpu);
      if (existingPlasmid) {
        sequenceFpusToCreate.push({
          functionalProteinUnitId: `&${fpu.cid}`,
          sequenceId: existingPlasmid.id
        });
      }
    } else if (
      existingProtein &&
      !alreadyManagedExistingProtein[existingProtein.id]
    ) {
      const linkedToPlasmid =
        existingPlasmid &&
        existingProtein.sequenceFpus.find(
          sequenceFpu => sequenceFpu.sequenceId === existingPlasmid.id
        );
      if (!linkedToPlasmid && existingProtein && existingPlasmid) {
        sequenceFpusToCreate.push({
          functionalProteinUnitId: existingProtein.id,
          sequenceId: existingPlasmid.id
        });
      }
    }

    // this is used to make sure we don't repeat logic for existing proteins
    if (existingProtein) {
      alreadyManagedExistingProtein[existingProtein.id] = true;
    }

    if (!mapPlates[rowKey]) {
      const plateCid = shortid();
      getCsvRowExtProps({
        row,
        modelTypeCode: "CONTAINER_ARRAY",
        typeFilter: ["plate", "rack"],
        recordId: `&${plateCid}`
      });

      mapPlates[rowKey] = {
        cid: plateCid,
        name: plateName,
        containerArrayTypeId: containerArrayType.id,
        ...(plateBarcode && {
          barcode: {
            barcodeString: plateBarcode
          }
        }),
        aliquotContainers: []
      };
    }

    const totalVolumetricUnit = unitGlobals.volumetricUnits[volumeUnit];
    if (!totalVolumetricUnit) {
      throw new Error(
        `Row ${
          index + 1
        } specifies the total volumetric unit ${volumeUnit} which doesn't exist.`
      );
    }

    const maxVolumeError = maxWellVolumeError({
      volume,
      unit: totalVolumetricUnit,
      containerArrayType,
      aliquotContainerType,
      index
    });

    if (maxVolumeError) {
      throw new Error(maxVolumeError);
    }
    const barcodeField = {};
    if (!generateTubeBarcodes) {
      barcodeField.barcode = {
        barcodeString: tubeBarcode
      };
    }

    const aliquotCid = shortid();
    const sampleCid = shortid();
    const aliquot = {
      cid: aliquotCid,
      aliquotType: "sample-aliquot",
      isDry: false,
      volume,
      volumetricUnitCode: totalVolumetricUnit.code,
      additives,
      sample: {
        cid: sampleCid,
        name: sampleName || fpuId,
        materialId,
        sampleTypeCode: "REGISTERED_SAMPLE"
      }
    };
    getCsvRowExtProps({
      row,
      modelTypeCode: "ALIQUOT",
      typeFilter: "aliquot",
      recordId: `&${aliquotCid}`
    });
    getCsvRowExtProps({
      row,
      modelTypeCode: "SAMPLE",
      typeFilter: "sample",
      recordId: `&${sampleCid}`
    });

    const tubeCid = shortid();
    if (isRack) {
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT_CONTAINER",
        typeFilter: "tube",
        recordId: `&${tubeCid}`
      });
    }
    mapPlates[rowKey].aliquotContainers.push({
      cid: tubeCid,
      aliquotContainerTypeCode: isRack
        ? aliquotContainerType.code
        : containerArrayType.aliquotContainerType.code,
      rowPosition,
      columnPosition,
      ...barcodeField,
      aliquot
    });
  }
  const platesToCreate = Object.values(mapPlates);

  // clear out the existing ones to filter for the ones we need to create
  existingAAs.forEach(aa => delete keyedAaSeqsToCreate[aa.hash]);
  const aminoAcidSequencesToCreate = Object.values(keyedAaSeqsToCreate);

  // needs to happen after amino acids get created
  const extraFn = async () => {
    await upsertUniqueAliases(sequenceAliases, ctx);
    await safeUpsert("proteinSubUnit", proteinSubUnitsToCreate);
    await safeUpsert("sequenceFpu", sequenceFpusToCreate);
  };
  proteinsToCreate.forEach(protein => {
    const { molecularWeight, extinctionCoefficient } =
      fpuMWExtMap[protein.commonId] || {};
    protein.molecularWeight = molecularWeight;
    protein.extinctionCoefficient = extinctionCoefficient;
  });
  await safeUpsert("functionalProteinUnit", proteinsToCreate);
  const createdPlates = await finishPlateCreate({
    newPlates: platesToCreate,
    newMaterials: proteinMaterialsToCreate,
    aminoAcidSequenceUpdates,
    newAminoAcidSequences: aminoAcidSequencesToCreate,
    existingAminoAcidSequences: existingAAs,
    existingFunctionalProteinUnits: existingFpus,
    extraFn
  });

  return createdPlates;
}
