/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Classes } from "@blueprintjs/core";
import { TgSelect } from "@teselagen/ui";
import React from "react";
import appGlobals from "../appGlobals";
import isMobile from "is-mobile";

function NavJump({ navSuggestItems, onSelect }) {
  return (
    <TgSelect
      autoFocus={!isMobile()}
      popoverProps={{
        usePortal: false
      }}
      mustHaveQueryToOpen
      placeholder="Jump to..."
      className="tg-nav-jump-search"
      onChange={value => {
        if (value) {
          appGlobals.history.push(value.value);
          onSelect(value);
        }
      }}
      options={navSuggestItems.map(item => {
        return {
          label: (
            <div>
              {item.label}
              <div style={{ fontSize: 9 }} className={Classes.TEXT_MUTED}>
                {item.path}
              </div>
            </div>
          ),
          value: item.navTo
        };
      })}
    />
  );
}

export default NavJump;
