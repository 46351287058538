/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ContextMenu, Icon, Position, Tooltip } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { updateEditor } from "@teselagen/ove";
import classnames from "classnames";

import actions from "../../../../src-shared/redux/actions";
import store from "../../../../src-shared/redux/store";
import ClassicGridContextMenuContainer from "../../../containers/ClassicGridContextMenuContainer";
import { designPartFragment } from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import handleInsertPart from "../ContextMenuHandlers/handleInsertPart";
import {
  getFirstNeighborPartId,
  getNeighborArfPartId
} from "../../../utils/stateUtils";
import "./style.css";
import {
  getRootCardId,
  getItemOfType,
  getFasOfElement,
  getEugeneRulesOfElement
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { isCellSelected } from "../../../../src-shared/selectors/designViewSelectors";
import {
  prettifyFasName,
  fasColors
} from "../../../../../tg-iso-design/constants/forcedAssemblyStrategies";
import { getInputCardByIndex } from "../../../../src-shared/selectors/classicViewSelectors";
import { withEditorContext } from "../DesignEditorContext";
import {
  COLUMN_WIDTH,
  CELL_HEIGHT,
  MAX_CELL_NAME_LENGTH_WITH_ICON
} from "../../../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import handleInsertCompatiblePart from "../ContextMenuHandlers/handleInsertCompatiblePart";
import { sortBy } from "lodash";
import { pushHelper } from "../../../../src-shared/utils/pushHelper";
import { partRecordFragment } from "../../../../src-shared/PartRecordView";
import gql from "graphql-tag";
import { QuickEditPartDialog } from "../../Dialogs/QuickEditPartDialog";
import { isDesignLocked } from "../../../../src-shared/utils/designUtils/isDesignLocked";

/**
 * Tooltips appears to treat new lines like spaces. This function
 * enables us to render new lines as actual new lines.
 * @param {string} error
 */
function processInvalidityMessage(msg) {
  return !msg ? null : (
    <div>
      {msg.split("\n").map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </div>
  );
}

const mapStateToProps = (state, { binIndex, binId, elementId, cellIndex }) => {
  const cardId = getRootCardId(state);
  const inputCard = getInputCardByIndex(state, binIndex);

  // ARF stuff, so we can query for these parts and their partSeqContextView
  const previousArfPartId = getNeighborArfPartId(state, binId, cardId, true);
  const nextArfPartId = getNeighborArfPartId(state, binId, cardId, false);

  const firstPreviousPartId = previousArfPartId
    ? null
    : getFirstNeighborPartId(state, binId, cardId, true);
  const firstNextPartId = nextArfPartId
    ? null
    : getFirstNeighborPartId(state, binId, cardId, false);
  // end ARF stuff

  const injection = {
    state,
    binId,
    cardId,
    inputCard,
    isSelected: isCellSelected(state, cardId, binId, cellIndex),
    fas: getFasOfElement(state, inputCard.id, elementId),
    isLocked: isDesignLocked(state),
    hasEugeneRules: !!getEugeneRulesOfElement(state, inputCard.id, elementId)
      .length,
    previousArfPartId,
    nextArfPartId,
    firstPreviousPartId,
    firstNextPartId
  };

  if (elementId) {
    const element = getItemOfType(state, "element", elementId);
    const part = getItemOfType(state, "part", element.partId);
    const partset = getItemOfType(state, "partset", element.partsetId);
    const aminoAcidPart =
      element.aminoAcidPartId &&
      getItemOfType(state, "aminoAcidPart", element.aminoAcidPartId);

    if (part) {
      Object.assign(injection, {
        element,
        partSequenceId: part.sequenceId,
        partStart: part.start,
        partEnd: part.end
      });
    }
    if (partset) {
      if (partset.numParts === 1) {
        element.warningMessage = "Only 1 part in this partset";
      }
      Object.assign(injection, {
        element,
        id: element.partsetId,
        name: element.name
      });
    }
    //unmapped part
    if (!element.partsetId && !element.partId) {
      Object.assign(injection, {
        element,
        id: element.id,
        name: element.name
      });
    }

    if (aminoAcidPart) {
      Object.assign(injection, {
        element,
        partSequenceId: aminoAcidPart.aminoAcidSequenceId,
        partStart: aminoAcidPart.start,
        partEnd: aminoAcidPart.end
      });
    }
  }
  return injection;
};

const mapDispatchToProps = {
  selectCell: actions.ui.designEditor.general.selectCell,
  mapElementToPart: actions.design.mapElementToPart,
  updateBin: actions.design.updateBin,
  updateElement: actions.design.updateElement,
  updatePart: actions.design.updatePart,
  setActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  openInspector: actions.ui.designEditor.inspector.open,
  createElements: actions.design.createElements
};

/**
 * A cell in the classic design layout.
 */
class Cell extends React.Component {
  static propTypes = {
    /**
     * If this cell contains an element, this is its id.
     */
    elementId: PropTypes.string,

    /**
     * The id of the bin this cell belongs to.
     */
    binId: PropTypes.string.isRequired,

    /**
     * The index of the bin this cell belongs to.
     */
    binIndex: PropTypes.number.isRequired,

    /**
     * The row index of this cell.
     */
    cellIndex: PropTypes.number.isRequired,

    /**
     * The id of the root card.
     */
    cardId: PropTypes.string.isRequired,
    /**
     * Is the cell selected?
     */
    isSelected: PropTypes.bool,
    /**
     * If the element of the cell has a fas, then this is the fas object. If present, only the `name` key
     * is required and should be a valid fas name.
     */
    fas: PropTypes.object,

    /**
     * If the element of the cell has any eugene rules associated with it, then
     * setting this flag to `true` will render an indicator.
     */
    hasEugeneRules: PropTypes.bool,
    /**
     * If this cell contains an element, this is it. Only the fields directly
     * on the element are needed (i.e., the `element` object should have no nested subobjects or arrays.)
     */
    element: PropTypes.object,

    /**
     * If this cell contains an element that corresponds to a part, then this is the id of its sequence.
     */
    partSequenceId: PropTypes.string,

    /**
     * If this cell contains an element that corresponds to a part, then this is its start (0-based and inclusive).
     */
    partStart: PropTypes.number,

    /**
     * If this cell contains an element that corresponds to a part, then this is its end basepair index (0-based and inclusive).
     */
    partEnd: PropTypes.number
  };

  calcCellTransform = () =>
    `translate(0, ${this.props.cellIndex * CELL_HEIGHT})`;

  getCellText = hasIcon => {
    const { element } = this.props;
    if (!element) return "";
    const name = element.name || "";
    const maxLength = hasIcon ? MAX_CELL_NAME_LENGTH_WITH_ICON : 15;
    return name.length > maxLength ? name.substring(0, maxLength) + ".." : name;
  };

  handleCellClick = e => {
    const {
      selectCell,
      cardId,
      binId,
      cellIndex,
      elementId,
      element,
      setActivePanel,
      openInspector
    } = this.props;
    selectCell({
      cardId,
      binId,
      index: cellIndex,
      elementId,
      shift: e.shiftKey,
      ctrl: e.ctrlKey || e.metaKey
    });
    if (element) {
      setActivePanel({ panel: "element" });
      openInspector();
    }
  };

  handleDoubleClick = async e => {
    const {
      isSelected,
      selectCell,
      cardId,
      binId,
      cellIndex,
      elementId,
      element,
      createElements,
      partStart,
      partEnd,
      mapElementToPart,
      updateElement,
      updatePart,
      setActivePanel,
      openInspector,
      isLocked,
      state,
      inputCard,
      designId,
      updateBin,
      changeFas,
      previousArfPartId,
      nextArfPartId,
      firstPreviousPartId,
      firstNextPartId
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!isSelected) {
      selectCell({
        cardId,
        binId,
        index: cellIndex,
        elementId,
        shift: e.shiftKey,
        ctrl: e.ctrlKey || e.metaKey
      });
    }

    const selectedCellPaths = [{ index: cellIndex, binId }];
    if (!isLocked) {
      if (!element) {
        const arfPartData = await safeQuery(
          ["part", "id name bpsUpStream bpsDownStream bps5Prime bps3Prime"],
          {
            isPlural: true,
            variables: {
              filter: {
                id: [
                  previousArfPartId,
                  nextArfPartId,
                  firstPreviousPartId,
                  firstNextPartId
                ].filter(id => !!id)
              }
            }
          }
        );

        showDialog({
          modalType: "PART_LIBRARY",
          modalProps: {
            basePairLiteralOption: true,
            firstPreviousPart: arfPartData.find(
              data => data.id === firstPreviousPartId
            ),
            firstNextPart: arfPartData.find(
              data => data.id === firstNextPartId
            ),
            previousArfPart: arfPartData.find(
              data => data.id === previousArfPartId
            ),
            nextArfPart: arfPartData.find(data => data.id === nextArfPartId),
            binId,
            cardId,
            onSubmit: async (
              parts,
              { isBpl, validateCompatibleParts, adjacentBinHasArf }
            ) => {
              await handleInsertPart({
                parts,
                selectedCellPaths,
                createElements,
                cardId,
                isBpl,
                validateCompatibleParts
              });

              if (validateCompatibleParts && !adjacentBinHasArf) {
                // adjust the FRO and extraCPECBps of this bin and previous bin
                await handleInsertCompatiblePart(
                  state,
                  binId,
                  inputCard,
                  updateBin
                );

                // apply FAS of Assembly Ready Fragment to the newly-created element
                const elements = await safeQuery(["element", "id name"], {
                  variables: {
                    filter: {
                      name: parts[0].name,
                      designId: Object.keys(state.design.design)[0]
                    }
                  }
                });
                changeFas({
                  fas: "Assembly Ready Fragment",
                  cardId: inputCard.id,
                  elementId: sortBy(elements, "id")[elements.length - 1].id
                });
              }
            }
          }
        });
      } else if (element.partId) {
        showDialog({
          ModalComponent: QuickEditPartDialog,
          modalProps: {
            updatePart,
            elementId: element.id,
            updateElement,
            insertNewPart: async part => {
              await handleInsertPart({
                parts: [part],
                selectedCellPaths,
                createElements,
                cardId,
                isBpl: false,
                validateCompatibleParts: true
              });
            },
            activeDesignId: designId,
            editorName: "SequenceEditor",
            partId: element.partId
          }
        });
      } else if (element.aminoAcidPartId) {
        updateEditor(store, "SequenceEditor", {
          selectionLayer: {
            start: partStart,
            end: partEnd
          }
        });
        pushHelper(e, `/amino-acid-parts/${element.aminoAcidPartId}`);
      } else if (element.bps) {
        showDialog({
          modalType: "INSERT_BP_LITERAL",
          modalProps: {
            initialValues: {
              name: element.name,
              bps: element.bps
            },
            onSubmit: values => {
              updateElement({
                element: {
                  id: element.id,
                  name: values.name,
                  bps: values.bps
                },
                binId
              });
            }
          }
        });
      } else if (element.partsetId) {
        setActivePanel({ panel: "element" });
        openInspector();
      } else {
        const arfPartData = await safeQuery(
          ["part", "id name bpsUpStream bpsDownStream bps5Prime bps3Prime"],
          {
            isPlural: true,
            variables: {
              filter: {
                id: [
                  previousArfPartId,
                  nextArfPartId,
                  firstPreviousPartId,
                  firstNextPartId
                ].filter(id => !!id)
              }
            }
          }
        );

        showDialog({
          modalType: "PART_LIBRARY",
          modalProps: {
            firstPreviousPart: arfPartData.find(
              data => data.id === firstPreviousPartId
            ),
            firstNextPart: arfPartData.find(
              data => data.id === firstNextPartId
            ),
            previousArfPart: arfPartData.find(
              data => data.id === previousArfPartId
            ),
            nextArfPart: arfPartData.find(data => data.id === nextArfPartId),
            binId,
            cardId,
            isSingleSelect: true,
            onSubmit: async (
              parts,
              { validateCompatibleParts, adjacentBinHasArf }
            ) => {
              if (validateCompatibleParts && !adjacentBinHasArf) {
                handleInsertCompatiblePart(state, binId, inputCard, updateBin);
              }
              try {
                if (parts.length !== 1)
                  throw new Error("Only one part should be selected.");

                const part = await safeQuery(designPartFragment, {
                  variables: { id: parts[0].id }
                });

                mapElementToPart({ binId, elementId, part });
              } catch (e) {
                console.error(e);
                window.toastr.error("Error mapping element to part.");
              }
            }
          }
        });
      }
    }
  };

  handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      selectCell,
      isSelected,
      cardId,
      binId,
      cellIndex,
      elementId,
      editorContext,
      isLocked
    } = this.props;
    if (!isSelected) {
      selectCell({
        cardId,
        binId,
        index: cellIndex,
        elementId,
        shift: e.shiftKey,
        ctrl: e.ctrlKey || e.metaKey
      });
    }

    const menu = (
      <ClassicGridContextMenuContainer {...{ store, editorContext }} />
    );

    if (!isLocked) ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };

  renderFas = () => {
    const { fas } = this.props;
    if (!fas) return null;
    return (
      <Tooltip
        wrapperTagName="g"
        targetTagName="g"
        content={
          <div>
            <div>Forced Assembly Strategy:</div>
            <div>{prettifyFasName(fas.name)}</div>
          </div>
        }
      >
        <rect
          className={`fas-${fas.name}`}
          {...{ width: 13, height: 7, x: 3, y: 3, fill: fasColors[fas.name] }}
        />
      </Tooltip>
    );
  };

  renderEugeneRuleIndicator = () => {
    const { hasEugeneRules } = this.props;
    if (!hasEugeneRules) return null;
    return (
      <circle
        {...{
          fill: "orange",
          r: 4.5,
          cx: COLUMN_WIDTH - 3 - 5,
          cy: CELL_HEIGHT - 3 - 5
        }}
      />
    );
  };

  renderUnmappedIfShould() {
    const { element = {} } = this.props;
    if (
      element.id &&
      !(
        element.part ||
        element.partId ||
        element.partsetId ||
        element.aminoAcidPartId
      ) &&
      !element.bps
    )
      return (
        <Icon
          tagName="g"
          icon="path-search"
          style={{
            color: "#A82A2A",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "unset"
          }}
        />
      );
  }

  renderEmptyIconIfShould() {
    const { element = {} } = this.props;
    if (element.isEmpty)
      return (
        <Icon
          tagName="g"
          icon="cube-remove"
          style={{
            color: "black",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "unset"
          }}
        />
      );
  }

  renderPartSetIconIfShould() {
    const { element = {} } = this.props;
    if (element.partsetId) {
      return (
        <Icon
          tagName="g"
          icon="menu"
          style={{
            color: element.invalidityMessage ? "firebrick" : "#4a4b4c",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "text-top"
          }}
        />
      );
    }
  }

  renderAminoAcidPartIconIfShould() {
    const { element = {} } = this.props;
    if (element.aminoAcidPartId) {
      return (
        <React.Fragment>
          <Icon
            tagName="g"
            icon="font"
            style={{
              color: "orange",
              verticalAlign: "text-top"
            }}
          />
          <Icon
            tagName="g"
            icon="font"
            style={{
              color: "orange",
              marginRight: 5,
              verticalAlign: "text-top"
            }}
          />
        </React.Fragment>
      );
    }
  }

  renderCellOutline({ icon }) {
    const { element = {}, isSelected } = this.props;
    const outline = (
      <g>
        <rect
          className={
            "cell-outline" +
            (element && element.warningMessage ? " warning-classic-cell" : "") +
            (element && element.invalidityMessage
              ? " invalid-classic-cell"
              : "") +
            (isSelected ? " selected-cell-outline" : "")
          }
          {...{
            x: 0,
            y: 0,
            width: COLUMN_WIDTH - 1,
            height: CELL_HEIGHT - 1,
            shapeRendering: "crispEdges"
          }}
        />
        {this.renderElementText(icon)}
      </g>
    );
    if (element.invalidityMessage || element.warningMessage) {
      return (
        <Tooltip
          wrapperTagName="g"
          targetTagName="g"
          position={Position.BOTTOM_LEFT}
          content={
            element ? (
              <div>
                {element.invalidityMessage && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 5,
                      marginBottom: 5
                    }}
                  >
                    <Icon icon="full-circle" size={12} intent="danger"></Icon>{" "}
                    &nbsp;
                    {processInvalidityMessage(element.invalidityMessage)}
                  </div>
                )}
                {element.warningMessage && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 5,
                      marginBottom: 5
                    }}
                  >
                    <Icon icon="full-circle" size={12} intent="warning"></Icon>{" "}
                    &nbsp;
                    {processInvalidityMessage(element.warningMessage)}
                  </div>
                )}
              </div>
            ) : null
          }
        >
          {outline}
        </Tooltip>
      );
    }

    return outline;
  }

  renderElementText(icon) {
    const { element } = this.props;

    if (!element) return null;
    const cellText = this.getCellText(icon);

    return (
      <React.Fragment>
        {icon && <g transform="translate(5, 12.5)">{icon}</g>}
        <Tooltip
          disabled={!cellText.endsWith("..")}
          wrapperTagName="g"
          targetTagName="g"
          position={Position.RIGHT}
          content={element.name}
        >
          <text
            className={classnames("cell-label", {
              "invalid-cell-label": element.invalidityMessage,
              "warning-cell-label": element.warningMessage
            })}
            {...{
              x: COLUMN_WIDTH / 2,
              y: 25
            }}
          >
            {cellText}
          </text>
        </Tooltip>
      </React.Fragment>
    );
  }

  // TODOs include Eugene Rules and fases.
  render() {
    const icon =
      this.renderEmptyIconIfShould() ||
      this.renderUnmappedIfShould() ||
      this.renderPartSetIconIfShould() ||
      this.renderAminoAcidPartIconIfShould();

    return (
      <g
        data-cell-index={`${this.props.binIndex}-${this.props.cellIndex}`}
        className="simplified-cell-container"
        transform={this.calcCellTransform()}
        onClick={this.handleCellClick}
        onDoubleClick={this.handleDoubleClick}
        onContextMenu={this.handleContextMenu}
      >
        {this.renderCellOutline({ icon })}
        {this.renderFas()}
        {this.renderEugeneRuleIndicator()}
      </g>
    );
  }
}

export const DumbCell = Cell;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withEditorContext
)(Cell);

export const partWithDesignsFrag = gql`
  fragment partWithDesignsFrag on part {
    ...partRecordFragment
    elements {
      id
      designId
    }
  }
  ${partRecordFragment}
`;
