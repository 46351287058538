/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import sequenceFragment from "./sequenceFragment";

export const growthConditionFragment = gql`
  fragment growthConditionFragment on growthCondition {
    id
    name
    description
    humidity
    temperature
    shakerSpeed
    shakerThrow
    lengthUnitCode
    additiveMaterialId
    growthMediaId # keep this
    gasCompositionId # keep this
    growthMedia {
      id
      name
    }
    gasComposition {
      id
      name
    }
  }
`;

export const strainSelectionMethodFragment = gql`
  fragment strainSelectionMethodFragment on selectionMethod {
    id
    name
    cofactor
    selectionMethodTargetOrganismClasses {
      id
      targetOrganismClass {
        id
        name
        description
      }
    }
    description
    createdAt
    updatedAt
  }
`;

export default gql`
  fragment strainRecordFragment on strain {
    id
    name
    description
    strainTypeCode
    biosafetyLevel {
      code
      name
    }
    sourceMicrobialMaterial {
      id
      name
      strain {
        id
        name
      }
    }
    sourceCellCulture {
      id
      name
      strain {
        id
        name
      }
    }
    targetOrganismClass {
      id
      name
    }
    specie {
      id
      name
      abbreviatedName
      genus {
        id
        name
      }
    }
    inductionMethodStrains {
      id
      inductionMethod {
        id
        name
      }
    }
    strainPlasmids {
      id
      plasmidId
      strainId
      plasmid {
        ...sequenceFragment
      }
    }
    genome {
      id
      name
      genomeGenomicRegions {
        id
        genomicRegion {
          ...sequenceFragment
        }
      }
    }
    genotype
    growthConditionId
    growthCondition {
      ...growthConditionFragment
    }
    strainFiles {
      id
      encoding
      mimetype
      originalname
      path
      size
    }
    strainSelectionMethods {
      id
      selectionMethod {
        ...strainSelectionMethodFragment
      }
    }
    lab {
      id
      name
    }
    createdAt
    updatedAt
    user {
      id
      username
    }
  }
  ${sequenceFragment}
  ${growthConditionFragment}
  ${strainSelectionMethodFragment}
`;
