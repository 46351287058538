/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isEmpty } from "lodash-es";
import withDelete from "./withDelete";
import withUpsert from "./withUpsert";
import withDeleteQuery from "./withDeleteQuery";
import updateWithQuery from "./updateWithQuery";
import fragmentToQuery from "./utils/fragmentToQuery";

export default function getApolloMethods(client, apolloMethodOptions = {}) {
  /**
    These options are used to override some options when calling the getApolloMethods for a particular client
    However this can be overridden by options passed directly to the specific apollo method.
    Originally implemented so that the apolloMethods of the server process apollo client bypass Apollo Cache.

    An example for this would be to pass the following apolloMethodOptions:

    apolloMethodOptions {
      options: { fetchPolicy: "no-cache" }
    }
   */

  const apolloUpsert = (fragment, valueOrValues, options) => {
    return withUpsert(fragment, {
      client,
      ...apolloMethodOptions,
      ...options
    })(valueOrValues, options);
  };

  const bindUpdateWithQuery = (...args) => {
    return updateWithQuery(args[0], args[1], {
      client,
      ...apolloMethodOptions,
      ...args[3]
    });
  };

  const apolloQuery = async (fragment, options) => {
    const {
      variables,
      options: queryOptions,
      useHasura,
      context
    } = {
      ...apolloMethodOptions,
      ...options
    };
    if (useHasura && !options.isSingular && options.isPlural !== false) {
      options.isPlural = true;
    }

    const { gqlQuery, nameToUse, queryNameToUse, isPlural } = fragmentToQuery(
      fragment,
      options
    );

    if (!client)
      return console.error(
        "You need to pass the apollo client to withQuery if using as a function"
      );

    const variablesToUse =
      variables || (queryOptions && queryOptions.variables) || undefined;
    const _res = await client.query({
      query: gqlQuery,
      name: queryNameToUse,
      ssr: false,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      ...queryOptions,
      // This will refetch queries whose data has been messed up by other cache updates. https://github.com/apollographql/react-apollo/pull/2003
      partialRefetch: true,
      variables: variablesToUse,
      context: {
        ...context,
        hasura: {
          ...context?.hasura,
          ...(useHasura ? { gqlRewriter: false } : { gqlRewriter: true })
        }
      }
    });

    const res = (_res && _res.body) || _res;
    let toReturn;
    if (isPlural) {
      if (useHasura) {
        if (res.data[nameToUse].hasOwnProperty("nodes")) {
          toReturn = res.data[nameToUse].nodes;
        } else {
          toReturn = res.data[nameToUse];
        }
      } else {
        toReturn = res.data[nameToUse].results;
      }
    } else {
      toReturn = res.data[nameToUse];
    }

    if (isPlural && toReturn) {
      toReturn = [...toReturn];
      toReturn.totalResults = res.data[nameToUse].totalResults;
    }
    return toReturn;
  };

  /**
   * If `query` tries to query more than 50 items this function
   * will group them into queries of 50 at a time and then
   * return them together
   */
  async function safeQuery(fragment, passedOptions = {}) {
    const {
      unsafe,
      nameOverride,
      pageSize = apolloMethodOptions.safeQueryDefaultPageSize || 500,
      showToast,
      modelNameToReadableName = () => {},
      canCancel,
      ...maybeOptions
    } = passedOptions;

    // hasura native
    if (passedOptions.useHasura) {
      return await apolloQuery(fragment, passedOptions);
    }
    // not plural
    if (
      unsafe ||
      get(maybeOptions, "variables.id") ||
      get(maybeOptions, "variables.code")
    ) {
      return await apolloQuery(fragment, maybeOptions);
    }

    if (maybeOptions.filter) {
      throw new Error("Use variables.filter instead of filter in options.");
    }
    if (get(maybeOptions, "variables.filter")) {
      const filter = maybeOptions.variables.filter;
      if (
        isEmpty(filter) ||
        Object.keys(filter).every(key => {
          const val = filter[key];
          const isEmptyArray = Array.isArray(val) && !val.length;
          // val maye be purposely 'false' or 'null'.
          return val === undefined || isEmptyArray;
        })
      ) {
        console.warn("query with empty filter");
        return [];
      }
    }

    // if specifying page size we don't want to do the special paging
    if (get(maybeOptions, "variables.pageSize")) {
      return await apolloQuery(fragment, {
        isPlural: true,
        ...maybeOptions
      });
    }

    const pageVariables = {
      pageSize
    };

    let options = {
      isPlural: true,
      variables: pageVariables
    };

    if (maybeOptions) {
      options = {
        isPlural: true,
        ...maybeOptions,
        variables: {
          ...pageVariables,
          ...maybeOptions.variables
        }
      };
    }

    let page = 1;
    let items = [];
    let totalResults = 1;
    let clearToast;
    let cancelled = false;
    const cancelIt = () => {
      cancelled = true;
      clearToast && clearToast();
    };
    while (items.length < totalResults && !cancelled) {
      options.variables.pageNumber = page++;
      const pageOfItems = await apolloQuery(fragment, options);
      if (cancelled) break;
      items = items.concat(pageOfItems);
      totalResults = pageOfItems.totalResults;
      if (showToast && totalResults > 100) {
        const readableName =
          nameOverride ||
          modelNameToReadableName(get(items, "[0].__typename"), {
            plural: true
          });
        const toastMessage = `Loading ${readableName}:`;
        clearToast = showToast(
          nameOverride ? `Loading ${nameOverride}` : toastMessage,
          items.length / totalResults,
          clearToast && clearToast.key,
          canCancel
            ? {
                action: {
                  text: "Cancel",
                  onClick: cancelIt
                },
                className: "no-close-button"
              }
            : {}
        );
      }
    }
    if (cancelled) return [];
    items.totalResults = totalResults;
    if (clearToast) {
      setTimeout(() => {
        clearToast();
      }, 1500);
    }
    return items;
  }

  function makeSafeQueryWithToast(showToast, modelNameToReadableName) {
    return (fragment, passedOptions = {}) => {
      return safeQuery(fragment, {
        ...passedOptions,
        showToast,
        modelNameToReadableName
      });
    };
  }

  const deleteFn = (fragment, idOrIdsToDelete, options) => {
    return withDelete(fragment, {
      client,
      ...apolloMethodOptions,
      ...options
    })(idOrIdsToDelete, options);
  };

  const deleteWithQuery = (fragment, input, options) => {
    return withDeleteQuery(fragment, {
      client,
      ...apolloMethodOptions
    })(input, options);
  };

  async function safeDelete(...args) {
    const values = args[args.length - 1];
    if (Array.isArray(values) && !values.length) return [];
    else return await deleteFn(...args);
  }

  return {
    upsert: apolloUpsert,
    updateWithQuery: bindUpdateWithQuery,
    safeUpsert: apolloUpsert,
    query: safeQuery,
    safeQuery,
    makeSafeQueryWithToast,
    delete: safeDelete,
    deleteWithQuery,
    safeDelete
  };
}
