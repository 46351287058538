/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { userRoleCodes } from "@teselagen/auth-utils";
import { SESSION_VARIABLE_NAMES } from "./HasuraSessionVars.js";
import { PERMISSION_FILTERS } from "./HasuraPermissionFilters.js";

const {
  LAB_MODEL,
  LAB_ROLE_MODEL,
  LAB_PERMISSION,
  USER_LOGIN_MODEL,
  USER_PERMISSION
} = PERMISSION_FILTERS;

const addFavoritePermissions = (rolePermission, modelName) => {
  if (modelName === "favoriteItem") {
    rolePermission.permission.filter._and.push(USER_PERMISSION);
  }
};

const generateRolePermissionsConfig = (tableDefinition, excludedModels) => {
  const { excludedLabModels, labConfidentialModels } = excludedModels;

  const modelName = tableDefinition.modelName;
  const columnNames = Object.keys(tableDefinition.columnNames);
  // NOTE: we could use the "*" wildcard to include all existing columns
  // but it might be a good idea to be explicit about this for versioning and diff purposes.
  // Although for mutations we need a way of detecting which columns are computed columns because we can't
  // list computed columns such as the 'circular' column in the sequence model.
  const commonSelectPermissions = {
    columns: columnNames,
    allow_aggregations: true
  };

  // TODO: we'll leave this for later once we enable hasura mutations.
  // const commonInsertPermissions = {
  //   columns: "*",
  //   allow_aggregations: true
  // };

  const select_permissions = [];
  // const insert_permissions = [];

  const addMemberPermissions = () => {
    const rolePermission = {
      role: userRoleCodes.MEMBER_ROLE,
      permission: {
        ...commonSelectPermissions,
        filter: {
          _and: []
        }
      }
    };
    if (modelName === USER_LOGIN_MODEL) {
      rolePermission.permission.filter._and.push(USER_PERMISSION);
    }
    addFavoritePermissions(rolePermission, modelName);

    if (labConfidentialModels.includes(modelName)) {
      if (modelName === LAB_MODEL) {
        rolePermission.permission.filter._and.push({
          id: {
            _in: SESSION_VARIABLE_NAMES.LAB_IDS
          }
        });
      } else if (modelName === LAB_ROLE_MODEL) {
        rolePermission.permission.filter._and.push({
          labId: {
            _in: SESSION_VARIABLE_NAMES.LAB_IDS
          }
        });
      } else if (modelName === "userRole") {
        rolePermission.permission.filter._and.push({
          user: {
            labRoles: {
              labId: {
                _in: SESSION_VARIABLE_NAMES.LAB_IDS
              }
            }
          }
        });
      } else if (modelName === "user") {
        rolePermission.permission.filter._and.push({
          labRoles: {
            labId: {
              _in: SESSION_VARIABLE_NAMES.LAB_IDS
            }
          }
        });
      } else {
        throw new Error(
          `[AUTH LAB CONFIDENTIAL] The model ${modelName} is marked as lab confidential but not supported`
        );
      }
    } else if (!excludedLabModels.includes(modelName)) {
      rolePermission.permission.filter._and.push(LAB_PERMISSION);
    }

    select_permissions.push(rolePermission);
  };

  const addAdminPermissions = () => {
    const rolePermission = {
      role: userRoleCodes.ADMIN_ROLE,
      permission: {
        ...commonSelectPermissions,
        filter: {
          _and: []
        }
      }
    };
    addFavoritePermissions(rolePermission, modelName);

    if (
      !labConfidentialModels.includes(modelName) &&
      !excludedLabModels.includes(modelName)
    ) {
      rolePermission.permission.filter._and.push(LAB_PERMISSION);
    }

    select_permissions.push(rolePermission);
  };

  const addSystemPermissions = () => {
    const rolePermission = {
      role: userRoleCodes.SYSTEM_ROLE,
      permission: {
        ...commonSelectPermissions,
        filter: {}
      }
    };
    select_permissions.push(rolePermission);
  };

  addMemberPermissions();
  addAdminPermissions();
  addSystemPermissions();

  return {
    select_permissions
    // insert_permissions
  };
};

export { generateRolePermissionsConfig };
