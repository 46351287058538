/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  every,
  uniq,
  pick,
  isString,
  union,
  max,
  min,
  keyBy,
  isUndefined
} from "lodash";
import actions from "../../actions";
import {
  createObjectValuedTypeMap,
  flattenTree,
  transformWithNewIds,
  patchDesignState
} from "../../../../../tg-iso-design/utils/designStateUtils";
import {
  ChangeSetsHelper,
  removeItemsAndShallowReferences
} from "../../../../../tg-iso-design/utils/designEditUtils";
import {
  getInputReactionIdOfCard,
  getItemOfType,
  getElementsInBin,
  getReferencedValue,
  getJ5OutputNamingTemplatesMap,
  isDesignTemplate,
  getEugeneRulesOfElement,
  getDesign,
  getAvailableElementCombosOfCard,
  getElementsInCombo,
  getRuleSetsOfBin,
  getInputCardsOfReaction,
  isDisabledSameBinDigestValidation,
  getAllOfType,
  getPartsInPartset
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import basicHandleActionsWithFullState from "../basicHandleActionsWithFullState";
import { PRESERVED_TYPES } from "../../../../../tg-iso-design/constants/designStateConstants";
import { DIGEST_ASSEMBLY_METHODS } from "../../../../../tg-iso-design/constants/assemblyMethods";
import {
  getSelectedBinIds,
  getSelectedCellPaths
} from "../../../selectors/designViewSelectors";
import { cannotChangeIcon } from "../../../utils/cannotChangeIcon";
import { getDeletedItems } from "../../../selectors/miscDesignSelectors";
import addOrChangeReaction from "./addOrChangeReaction";
import convertToListLayout from "./convertToListLayout";
import applyDesignTemplate from "./applyDesignTemplate";
import changeAssemblyMethodToDigest from "./changeAssemblyMethodToDigest";
import getFlatDefaultDesign from "./getFlatDefaultDesign";
import getFlatDefaultDesignTemplate from "./getFlatDefaultDesignTemplate";
import autofillOverhangsIfPossible from "./autofillOverhangsIfPossible";
import changeInternalizationPreferences from "./changeInternalizationPreferences";
import eliminateCombinations from "./eliminateCombinations";
import pasteCells from "./pasteCells";
import insertBin from "./insertBin";
import removeBins from "./removeBins";
import alphabetizeParts from "./alphabetizeParts";
import changeDesignLayoutType from "./changeDesignLayoutType";
import { getJunctionOnCard } from "../../../../../tg-iso-design/selectors/junctionSelectors";
import removeAutoDigestElementCombo from "./removeAutoDigestElementCombo";
import removeReaction from "./removeReaction";
import removeElements from "./removeElements";
import { v4 as uuid } from "uuid";
import {
  getDeleteRowsIndexHelper,
  getInsertRowIndexHelper
} from "./designEditReduxUtils";
import { getParamsForRestrictionEnzyme } from "../../../../../tg-iso-shared/utils/enzymeUtils";
import { getParamsAsCustomJ5ParamName } from "../../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";

const initialState = createObjectValuedTypeMap();

const flipBin = (state, changeSetsHelper, binId) => {
  const bin = state.bin[binId];
  changeSetsHelper.updatePure("bin", {
    id: binId,
    direction: !bin.direction
  });
  return changeSetsHelper;
};

export default basicHandleActionsWithFullState(
  {
    [actions.designIo.setDesign]: (state, { payload: item }) => {
      // Keep the icons as we need to load some icons (in another step) that
      // may not be in the design.
      // const changeSetsHelper = new ChangeSetsHelper(flattenTree(item))
      const changeSetsHelper = new ChangeSetsHelper(item);

      changeSetsHelper.updateViaFlatObject(pick(state, PRESERVED_TYPES));
      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        recomputeDigestFasValidation: true,
        recomputeBinValidation: true
      });
    },

    [actions.designIo.addItems]: (state, { payload: typeToItems }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      Object.entries(typeToItems).forEach(([type, items]) =>
        changeSetsHelper.updatePure(type, items)
      );
      return changeSetsHelper.execute();
    },

    [actions.designIo.saveCompleted]: (
      state,
      { payload: { oldIdToNewIdMap, validationDiff } }
    ) => {
      let newState = transformWithNewIds(state, oldIdToNewIdMap);
      newState = patchDesignState(newState, validationDiff);
      return newState;
    },

    [actions.ui.designEditor.undo.setDesignFromUndoRedoStack]: (
      state,
      { payload: newState },
      fullState
    ) => {
      const deletedItems = getDeletedItems(fullState);
      const deletedIdToNewIdMap = Object.entries(deletedItems).reduce(
        (acc, [type, deletedItems]) => {
          acc[type] = Object.keys(deletedItems).reduce((acc2, deletedId) => {
            acc2[deletedId] = uuid();
            return acc2;
          }, {});
          return acc;
        },
        {}
      );

      return transformWithNewIds(newState, deletedIdToNewIdMap);
    },

    [actions.designEdit.setDsf]: (
      state,
      { payload: { cardId, cardIds = [], dsf } }
    ) => {
      cardIds = [cardId, ...cardIds].filter(x => x);

      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure(
        "card",
        cardIds.map(id => ({ id, dsf }))
      );
      return changeSetsHelper.execute();
    },

    [actions.designEdit.insertBin]: insertBin,

    [actions.designEdit.removeBins]: removeBins,

    [actions.designEdit.flipBin]: (state, { payload: binId }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      flipBin(state, changeSetsHelper, binId);

      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        recomputeBinValidation: true,
        recomputeDigestFasValidation: true,
        updateInvalidMaterialAvailabilities: true
      });
    },

    [actions.designEdit.removeReaction]: (state, { payload: operationId }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      removeReaction(state, changeSetsHelper, operationId);
      return changeSetsHelper.execute({
        removeInaccessibleItems: true,
        recomputeElementValidation: true,
        removeNonsensicalExtraSequences: true,
        recomputeBinValidation: true,
        recomputeDigestFasValidation: true,
        updateInvalidMaterialAvailabilities: true
      });
    },

    [actions.designEdit.changeFas]: (
      state,
      { payload: { fas, cardId, elementId, cardIdToElementIdsMap = {} } },
      fullState
    ) => {
      cardIdToElementIdsMap = { ...cardIdToElementIdsMap };
      const changeSetsHelper = new ChangeSetsHelper(state);

      if (cardId && elementId) {
        if (!cardIdToElementIdsMap[cardId]) cardIdToElementIdsMap[cardId] = [];
        cardIdToElementIdsMap[cardId] = [
          ...cardIdToElementIdsMap[cardId],
          elementId
        ];
      }

      for (const [cardId, elementIds] of Object.entries(
        cardIdToElementIdsMap
      )) {
        for (const elementId of uniq(elementIds)) {
          const oldFas = Object.values(state.fas).find(
            f => f.elementId === elementId
          );

          /**
           * NOTE: This code block prevents non-digest parts from being set to FAS=DIGEST
           * however, until we find a way to automatically migrate non-digest parts to digest parts,
           * we are opting to allow it.
           */
          const isDigestFasConstrained = false;
          if (fas === "DIGEST" && isDigestFasConstrained) {
            let validFas = true;
            const element = getItemOfType(fullState, "element", elementId);
            if (element.partSetId) {
              const elementParts = getPartsInPartset(
                fullState,
                element.partSetId
              );
              validFas = every(elementParts, p => p.isDigestPart);
            } else if (element.partId) {
              const elementPart = getItemOfType(
                fullState,
                "part",
                element.partId
              );
              validFas = elementPart?.isDigestPart;
            }

            if (!validFas) {
              const msg = "Cannot set FAS=DIGEST to non-digest part";
              console.error(msg);
              window.toastr.warning(msg);
              continue;
            }
          }

          if (oldFas) changeSetsHelper.deletePure("fas", oldFas.id);

          if (fas !== "None") {
            const reactionId = getInputReactionIdOfCard(fullState, cardId);
            changeSetsHelper.createPure("fas", {
              name: fas,
              elementId,
              reactionId
            });
          }
        }
      }

      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        recomputeDigestFasValidation: true,
        recomputeBinValidation: true,
        removeInvalidEugeneRules: true
      });
    },

    [actions.designEdit.updateDesign]: (state, { payload: newValues }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      const design = Object.values(state.design)[0];
      const designId = design.id;
      changeSetsHelper.updatePure("design", {
        ...newValues,
        id: designId
      });

      const changingLayout =
        newValues.layoutType && design.layoutType !== newValues.layoutType;
      if (changingLayout) {
        changeDesignLayoutType(changeSetsHelper, state, newValues.layoutType);
      }

      return changeSetsHelper.execute({
        recomputeElementValidation: changingLayout
      });
    },

    [actions.designEdit.updateCard]: (state, { payload: newValues }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      let data = newValues;

      if (!Array.isArray(newValues)) {
        data = [newValues];
      }

      const removeCardInvalidityMessages = !data.some(
        val => !!val.invalidityMessage
      );

      changeSetsHelper.updatePure("card", data);

      return changeSetsHelper.execute({
        removeCardInvalidityMessages,
        ...(!isUndefined(newValues.circular) && {
          recomputeElementValidation: true,
          recomputeDigestFasValidation: true,
          recomputeBinValidation: true
        })
      });
    },

    [actions.designEdit.updateBin]: (state, { payload: newValues }) => {
      newValues = { ...newValues };
      const { id, icon } = newValues;
      delete newValues.id;
      delete newValues.icon;

      const oldBin = state.bin[id];

      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("bin", {
        ...newValues,
        id
      });

      let isFlippingSet = false;
      if (
        Object.keys(newValues).includes("direction") &&
        newValues.direction !== oldBin.direction
      ) {
        isFlippingSet = true;
        flipBin(state, changeSetsHelper, id);
      }

      if (icon) {
        changeSetsHelper.updatePure("bin", {
          id,
          iconId: icon.id
        });
        changeSetsHelper.updatePure("icon", { ...icon });
      }

      return changeSetsHelper.execute({
        recomputeElementValidation: isFlippingSet,
        recomputeBinValidation: isFlippingSet,
        recomputeDigestFasValidation: isFlippingSet,
        updateInvalidMaterialAvailabilities: isFlippingSet
      });
    },

    [actions.designEdit.updateBinCard]: (state, { payload: newValues }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("binCard", {
        ...newValues
      });

      return changeSetsHelper.execute({
        ...(!isUndefined(newValues.hasSpecifiedJunction) && {
          recomputeElementValidation: true,
          recomputeDigestFasValidation: true,
          recomputeBinValidation: true
        })
      });
    },

    [actions.designEdit.updateJunction]: (state, { payload: newValues }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("junction", {
        ...newValues
      });
      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        recomputeBinValidation: true
      });
    },

    [actions.designEdit.setSelectedBinsIcon]: (
      state,
      { payload: { icon } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("icon", { ...icon });

      const selectedBinIds = getSelectedBinIds(fullState);
      changeSetsHelper.updatePure(
        "bin",
        selectedBinIds
          .filter(id => !cannotChangeIcon(fullState, id))
          .map(id => ({ id, iconId: icon.id }))
      );
      return changeSetsHelper.execute();
    },

    [actions.designEdit.setBinIcon]: (
      state,
      { payload: { icon, binId } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      const canChange = !cannotChangeIcon(fullState, binId);
      if (canChange) {
        changeSetsHelper.updatePure("icon", { ...icon });
        changeSetsHelper.updatePure("bin", [{ id: binId, iconId: icon.id }]);
        return changeSetsHelper.execute();
      }
    },

    [actions.designEdit.updatePart]: (state, { payload: { part } }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("part", part);
      const canChangeElementSequenceString = false;

      return changeSetsHelper.execute({
        recomputeElementValidation: canChangeElementSequenceString,
        recomputeBinValidation: canChangeElementSequenceString,
        recomputeDigestFasValidation: canChangeElementSequenceString,
        updateInvalidMaterialAvailabilities:
          canChangeElementSequenceString && state
      });
    },
    [actions.designEdit.createPart]: (state, { payload: { part } }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.createPure("part", part);
      const canChangeElementSequenceString = false;

      return changeSetsHelper.execute({
        recomputeElementValidation: canChangeElementSequenceString,
        recomputeBinValidation: canChangeElementSequenceString,
        recomputeDigestFasValidation: canChangeElementSequenceString,
        updateInvalidMaterialAvailabilities:
          canChangeElementSequenceString && state
      });
    },
    [actions.designEdit.updateElement]: (
      state,
      { payload: { element, binId } }
    ) => {
      let changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("element", element);
      const canChangeElementSequenceString =
        element.bps ||
        element.partId ||
        element.extraStartSequence ||
        element.extraEndSequence;

      if (binId) {
        changeSetsHelper = autofillOverhangsIfPossible({
          changeSetsHelper,
          binId,
          elementId: element.id
        });
      }

      return changeSetsHelper.execute({
        recomputeElementValidation: canChangeElementSequenceString,
        recomputeBinValidation: canChangeElementSequenceString,
        recomputeDigestFasValidation: canChangeElementSequenceString,
        updateInvalidMaterialAvailabilities:
          canChangeElementSequenceString && state
      });
    },

    [actions.designEdit.updateElements]: (state, { payload: { elements } }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("element", elements);
      const canChangeElementSequenceString = true;

      return changeSetsHelper.execute({
        recomputeElementValidation: canChangeElementSequenceString,
        recomputeBinValidation: canChangeElementSequenceString,
        recomputeDigestFasValidation: canChangeElementSequenceString,
        updateInvalidMaterialAvailabilities:
          canChangeElementSequenceString && state
      });
    },

    [actions.designEdit.updateReactionRestrictionEnzyme]: (
      state,
      { payload: newValues },
      fullState
    ) => {
      newValues = { ...newValues };
      const { id, restrictionEnzyme } = newValues;

      const changeSetsHelper = new ChangeSetsHelper(state);

      changeSetsHelper.updatePure("reaction", {
        id,
        restrictionEnzymeId: restrictionEnzyme?.id || null
      });
      if (restrictionEnzyme) {
        const reaction = getItemOfType(fullState, "reaction", id);

        // If this is a restrictionEnzyme that's new to the design
        // it will not be in the design state so we need to add it with
        // 'createPure' because there's logic that looks for used enzymes in the state.
        // instead of querying them from the db.
        const restrictionEnzyme_fromState = getItemOfType(
          fullState,
          "restrictionEnzyme",
          restrictionEnzyme.id
        );
        if (!restrictionEnzyme_fromState) {
          changeSetsHelper.createPure("restrictionEnzyme", restrictionEnzyme);
        }

        const newParams = getParamsAsCustomJ5ParamName(
          getParamsForRestrictionEnzyme(restrictionEnzyme)
        );

        changeSetsHelper.updatePure("customJ5Parameter", {
          id: reaction.customJ5ParameterId,
          ...newParams
        });
      }
      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        recomputeDigestFasValidation: true,
        recomputeBinValidation: true
      });
    },

    [actions.designEdit.updateReaction]: (
      state,
      { payload: newValues },
      fullState
    ) => {
      newValues = { ...newValues };
      const {
        id,
        assemblyMethod,
        customJ5Parameter,
        outputNamingTemplates,
        restrictionEnzyme
      } = newValues;
      delete newValues.assemblyMethod;
      delete newValues.customJ5Parameter;
      delete newValues.outputNamingTemplates;
      delete newValues.restrictionEnzyme;

      const { id: designId } = getDesign(fullState);
      const oldReaction = state.reaction[id];
      const oldAssemblyMethod =
        state.assemblyMethod[oldReaction.assemblyMethodId];
      const oldRestrictionEnzyme =
        state.restrictionEnzyme[oldReaction.restrictionEnzymeId];
      const oldCustomJ5Parameter =
        state.customJ5Parameter[oldReaction.customJ5ParameterId];
      const assemblyMethodToUse = assemblyMethod || oldAssemblyMethod;
      const restrictionEnzymeToUse = restrictionEnzyme || oldRestrictionEnzyme;
      const customJ5ParameterToUse = customJ5Parameter || oldCustomJ5Parameter;
      let changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.updatePure("reaction", {
        ...newValues
      });

      let changingToDigest = false;

      if (assemblyMethodToUse) {
        changingToDigest = DIGEST_ASSEMBLY_METHODS.includes(
          assemblyMethodToUse.name
        );
        if (changingToDigest && restrictionEnzymeToUse) {
          const errorMessageOrChangeSetsHelper = changeAssemblyMethodToDigest({
            changeSetsHelper,
            state: fullState,
            reactionId: id,
            restrictionEnzyme: restrictionEnzymeToUse,
            assemblyMethod: assemblyMethodToUse
          });

          if (isString(errorMessageOrChangeSetsHelper)) {
            window.toastr.warning(errorMessageOrChangeSetsHelper);
            return state;
          } else {
            changeSetsHelper = errorMessageOrChangeSetsHelper;
          }
        } else {
          changeSetsHelper.updatePure("assemblyMethod", assemblyMethodToUse);
          changeSetsHelper.updatePure("reaction", {
            id,
            assemblyMethodId: assemblyMethodToUse.id,
            ...(assemblyMethodToUse.name && { name: assemblyMethodToUse.name }),
            // If changing to a non-digest based assembly method,
            // remove the restrictionEnzyme from the reaction record.
            restrictionEnzymeId: null
          });
        }
      }

      if (customJ5ParameterToUse) {
        changeSetsHelper.updatePure("customJ5Parameter", {
          ...oldCustomJ5Parameter,
          ...customJ5ParameterToUse
        });
      }

      if (outputNamingTemplates) {
        const oldNamingTemplates = getJ5OutputNamingTemplatesMap(fullState, id);
        for (const [outputTarget, templateValues] of Object.entries(
          outputNamingTemplates
        )) {
          const oldNamingTemplate = oldNamingTemplates[outputTarget];
          if (oldNamingTemplate) {
            changeSetsHelper.updatePure("j5OutputNamingTemplate", {
              id: oldNamingTemplate.id,
              outputTarget,
              ...templateValues
            });
          } else {
            const j5OutputNamingTemplateId = uuid();
            changeSetsHelper.createPure("j5OutputNamingTemplate", {
              designId,
              outputTarget,
              ...templateValues,
              id: j5OutputNamingTemplateId
            });

            changeSetsHelper.createPure("reactionJ5OutputNamingTemplate", {
              designId,
              j5OutputNamingTemplateId,
              reactionId: id
            });
          }
        }
      }

      return changeSetsHelper.execute({
        recomputeElementValidation: changingToDigest,
        removeInaccessibleItems: changingToDigest,
        removeNonsensicalExtraSequences: changingToDigest,
        recomputeDigestFasValidation: changingToDigest,
        recomputeBinValidation: changingToDigest,
        updateInvalidMaterialAvailabilities: changingToDigest
      });
    },

    [actions.designEdit.addOrChangeReaction]: addOrChangeReaction,

    [actions.designEdit.removeElements]: (state, { payload: elementIds }) =>
      removeElements(state, elementIds),

    [actions.designEdit.convertToListLayout]: convertToListLayout,

    [actions.designEdit.toggleDisableSameBinDigestValidation]: (
      state,
      _,
      fullState
    ) => {
      const isDisabled = isDisabledSameBinDigestValidation(fullState);
      const { id: designId } = getDesign(fullState);
      const changeSetsHelper = new ChangeSetsHelper(state);

      changeSetsHelper.updatePure("design", {
        id: designId,
        disableSameBinDigestValidation: !isDisabled
      });
      return changeSetsHelper.execute({
        // removeInaccessibleItems: true,
        recomputeDigestFasValidation: true
        // recomputeBinValidation: true,
        // updateInvalidMaterialAvailabilities: true
      });
    },

    [actions.designEdit.createElements]: (
      state,
      {
        payload: {
          binId,
          cardId,
          cellIndex = 0,
          values,
          elementIdsToDelete = [],
          isAssemblyPiece = null,
          elementGuids = []
        }
      },
      fullState
    ) => {
      if (!Array.isArray(values)) values = [values];
      let changeSetsHelper = new ChangeSetsHelper(state);

      const overwrittenElementIds = getElementsInBin(fullState, binId)
        .filter(
          el => cellIndex <= el.index && el.index < cellIndex + values.length
        )
        .map(el => el.id);

      removeItemsAndShallowReferences(
        state,
        "element",
        union(elementIdsToDelete, overwrittenElementIds),
        changeSetsHelper,
        [
          "eugeneRuleElement",
          "elementElementCombo",
          "elementGroupElementGroupCombo"
        ]
      );

      const elementValues = values.map(value => {
        value = { ...value };
        if (value.part) {
          const flattened = flattenTree(value.part);
          changeSetsHelper.updateViaFlatObject(flattened);
          value.partId = value.part.id;
          value = {
            name: value.part.name,
            ...value,
            isAssemblyPiece,
            apLeftDominant: true,
            partId: value.part.id
          };
          delete value.part;
        } else if (value.partset) {
          const flattened = flattenTree(value.partset);
          changeSetsHelper.updateViaFlatObject(flattened);
          value.partsetId = value.partset.id;
          value = {
            name: value.partset.name,
            ...value,
            partsetId: value.partset.id
          };
          delete value.partset;
        } else if (value.aminoAcidPart) {
          const flattened = flattenTree(value.aminoAcidPart);
          changeSetsHelper.updateViaFlatObject(flattened);
          value.aminoAcidPartId = value.aminoAcidPart.id;
          value = {
            name: value.aminoAcidPart.name,
            ...value,
            aminoAcidPartId: value.aminoAcidPart.id
          };
          delete value.aminoAcidPart;
        }
        return value;
      });

      let firstElementId = null;
      elementValues.forEach((value, i) => {
        value.id = elementGuids[i] || uuid();
        firstElementId = firstElementId || value.id;
        const fas = value.fas;
        delete value.fas;
        changeSetsHelper.createPure("element", {
          ...value,
          binId,
          index: cellIndex + i
        });
        if (fas) {
          const reactionId = getInputReactionIdOfCard(fullState, cardId);
          const newFas = {
            ...fas,
            reactionId: fas.reactionId || reactionId,
            elementId: value.id
          };
          changeSetsHelper.createPure("fas", newFas);
        }
      });

      changeSetsHelper = autofillOverhangsIfPossible({
        changeSetsHelper,
        binId,
        elementId: firstElementId
      });

      const design = getDesign(fullState);
      if (cellIndex + values.length > design.numRows) {
        changeSetsHelper.updatePure("design", {
          id: design.id,
          numRows: cellIndex + values.length
        });
      }

      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        removeInaccessibleItems: true,
        recomputeBinValidation: true,
        recomputeDigestFasValidation: true,
        updateInvalidMaterialAvailabilities: state
      });
    },

    [actions.designEdit.mapElementToPart]: (
      state,
      { payload: { binId, elementId, part } }
    ) => {
      let changeSetsHelper = new ChangeSetsHelper(state);

      changeSetsHelper.updateViaFlatObject(flattenTree(part));
      changeSetsHelper.updatePure("element", {
        id: elementId,
        partId: part.id
      });

      changeSetsHelper = autofillOverhangsIfPossible({
        changeSetsHelper,
        binId,
        elementId
      });

      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        recomputeBinValidation: true,
        recomputeDigestFasValidation: true,
        updateInvalidMaterialAvailabilities: state
      });
    },

    [actions.designEdit.changeElementIndex]: (
      state,
      { payload: { elementsWithNewIndex } }
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      for (let el = 0; el < elementsWithNewIndex.length; el += 1) {
        changeSetsHelper.updatePure("element", {
          id: elementsWithNewIndex[el].id,
          index: elementsWithNewIndex[el].index
        });
      }

      return changeSetsHelper.execute();
    },

    [actions.designEdit.changePartsetPart]: (
      state,
      { payload: { isDeactivated, newName, id, partId } }
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      if (newName) {
        changeSetsHelper.updatePure("part", {
          id: partId,
          name: newName
        });
      }
      if (isDeactivated !== undefined && isDeactivated !== null) {
        changeSetsHelper.updatePure("partsetPart", {
          id,
          isDeactivated
        });
      }

      return changeSetsHelper.execute();
    },

    [actions.designEdit.lockBins]: (
      state,
      { payload: { binIds, isLocked = 1 } }
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      for (const binId of binIds) {
        changeSetsHelper.updatePure("bin", {
          id: binId,
          isLocked
        });
      }

      return changeSetsHelper.execute();
    },

    [actions.designEdit.convertBinsToPlaceholders]: (
      state,
      { payload: { binIds, isPlaceholder = 1 } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      const elementIdsToDelete = [];

      for (const binId of binIds) {
        changeSetsHelper.updatePure("bin", {
          id: binId,
          isPlaceholder
        });
        elementIdsToDelete.push(
          ...getElementsInBin(fullState, binId).map(el => el.id)
        );
      }

      if (isPlaceholder) {
        removeItemsAndShallowReferences(
          state,
          "element",
          uniq(elementIdsToDelete),
          changeSetsHelper,
          [
            "eugeneRuleElement",
            "elementElementCombo",
            "elementGroupElementGroupCombo"
          ]
        );
      }

      return changeSetsHelper.execute({
        removeInaccessibleItems: true,
        recomputeBinValidation: true,
        recomputeDigestFasValidation: true,
        updateInvalidMaterialAvailabilities: true
      });
    },

    [actions.designEdit.applyDesignTemplate]: applyDesignTemplate,

    [actions.designEdit.clearDesign]: (state, __, fullState) => {
      const changeSetsHelper = new ChangeSetsHelper(
        isDesignTemplate(fullState)
          ? getFlatDefaultDesignTemplate(fullState)
          : getFlatDefaultDesign(fullState)
      );
      changeSetsHelper.updateViaFlatObject(
        pick(state, [...PRESERVED_TYPES, "design"])
      );
      changeSetsHelper.updatePure("design", {
        id: getDesign(fullState).id,
        layoutType: "combinatorial"
      });
      return changeSetsHelper.execute();
    },

    [actions.designEdit.applyRuleSet]: (
      state,
      { payload: { binIds, ruleSets } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      const flattened = flattenTree(ruleSets);
      changeSetsHelper.updateViaFlatObject(flattened);

      for (const binId of binIds) {
        const bin = getItemOfType(fullState, "bin", binId);

        // We cannot edit locked bins.
        if (bin.isLocked) continue;

        // Do not create duplicate rule sets validating the same bin.
        const existingRuleSets = getRuleSetsOfBin(fullState, binId);
        const ruleSetsToAdd = ruleSets.filter(rs =>
          existingRuleSets.every(ers => ers.id !== rs.id)
        );

        changeSetsHelper.createPure(
          "binRuleSet",
          ruleSetsToAdd.map(rs => ({
            id: uuid(),
            binId,
            ruleSetId: rs.id
          }))
        );
      }

      return changeSetsHelper.execute({ recomputeBinValidation: true });
    },

    [actions.designEdit.removeRuleSet]: (
      state,
      { payload: { binIds, ruleSetIds } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      for (const binId of binIds) {
        const binRuleSets = getReferencedValue(
          fullState,
          "bin",
          binId,
          "binRuleSets"
        );
        for (const brs of binRuleSets) {
          if (ruleSetIds.includes(brs.ruleSetId)) {
            changeSetsHelper.deletePure("binRuleSet", brs.id);
          }
        }
      }
      return changeSetsHelper.execute({
        removeInaccessibleItems: true,
        recomputeBinValidation: true
      });
    },

    [actions.designEdit.upsertEugeneRule]: (
      state,
      { payload: values },
      fullState
    ) => {
      values = { ...values };
      const isUpdate = !!values.id;
      values.id = values.id || uuid();

      const { operand1ElementIds, operand2ElementIds } = values;

      delete values.operand1ElementIds;
      delete values.operand2ElementIds;

      const changeSetsHelper = new ChangeSetsHelper(state);

      changeSetsHelper.updatePure("eugeneRule", values);

      if (isUpdate) {
        changeSetsHelper.deletePure(
          "eugeneRuleElement",
          getReferencedValue(
            fullState,
            "eugeneRule",
            values.id,
            "operand1EugeneRuleElements"
          ).map(ere => ere.id)
        );
        changeSetsHelper.deletePure(
          "eugeneRuleElement",
          getReferencedValue(
            fullState,
            "eugeneRule",
            values.id,
            "operand2EugeneRuleElements"
          ).map(ere => ere.id)
        );
      }

      changeSetsHelper.createPure(
        "eugeneRuleElement",
        operand1ElementIds.map((elementId, index) => ({
          elementId,
          index,
          eugeneRule1Id: values.id
        }))
      );

      changeSetsHelper.createPure(
        "eugeneRuleElement",
        operand2ElementIds.map((elementId, index) => ({
          elementId,
          index,
          eugeneRule2Id: values.id
        }))
      );

      return changeSetsHelper.execute();
    },

    [actions.designEdit.removeElementEugeneRules]: (
      state,
      { payload: { cardId, elementId } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      const rules = getEugeneRulesOfElement(fullState, cardId, elementId);
      changeSetsHelper.deletePure(
        "eugeneRule",
        rules.map(r => r.id)
      );
      return changeSetsHelper.execute({
        removeInaccessibleItems: true,
        removeInvalidEugeneRules: true
      });
    },

    [actions.designEdit.removeEugeneRule]: (state, { payload: ruleId }) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.deletePure("eugeneRule", ruleId);
      return changeSetsHelper.execute({
        removeInvalidEugeneRules: true
      });
    },

    [actions.designEdit.upsertConstructAnnotation]: (
      state,
      { payload: data }
    ) => {
      /**
       TODO: Create a dna part when creating a construct annotation (Next Level Annotation). To do this we will need
       to figure out a way of computing the final bps of the next level part based on the input parts being concatenated.
       This can be sometimes trivial but sometimes parts come with necessary assembly over/undehangs
       which will have to be taken into account.

       Theoretically, we do not need to run j5 to create a part out of a construct annotation.
       In fact, sooner than later we might want to create a DNA Part every time a construct annotation is created.
       This can be particularly useful when building DAG (directed acyclic graph) designs, so that an upstream
       construct annotation can immediately be used as a part in a downstream design. In fact, eventually we might
       enable the ability to run j5 in parallel, which is something we can NOT do right now with the current hierarchical tree designs.
      */
      const changeSetsHelper = new ChangeSetsHelper(state);

      if (data.id) {
        changeSetsHelper.updatePure("constructAnnotation", data);
      } else {
        changeSetsHelper.createPure("constructAnnotation", data);
      }

      return changeSetsHelper.execute();
    },

    [actions.designEdit.removeConstructAnnotation]: (
      state,
      { payload: id }
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.deletePure("constructAnnotation", id);
      return changeSetsHelper.execute({
        removeInaccessibleItems: true
      });
    },

    [actions.designEdit.swapParts]: (
      state,
      {
        payload: {
          elementId,
          part,
          updateInvalidMaterialAvailabilities = false
        }
      }
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      const flattened = flattenTree(part);
      changeSetsHelper.updateViaFlatObject(flattened);

      changeSetsHelper.updatePure("element", {
        id: elementId,
        bps: "",
        extraStartSequence: "",
        extraEndSequence: "",
        partId: part.id,
        name: part.name
      });

      return changeSetsHelper.execute({
        recomputeElementValidation: true,
        removeInaccessibleItems: true,
        recomputeDigestFasValidation: true,
        recomputeBinValidation: true,
        updateInvalidMaterialAvailabilities
      });
    },

    [actions.designEdit.insertRow]: (
      state,
      { payload: { isAbove, atEnd = false } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      const selectedCellPaths = getSelectedCellPaths(fullState);
      const RowsIndicesSelected = uniq(selectedCellPaths.map(p => p.index));
      // const firstIndexSelected = min(RowsIndicesSelected);
      // const lastIndexSelected = max(RowsIndicesSelected);

      const design = getDesign(fullState);
      const disabledRows = Object.values(
        getAllOfType(fullState, "disabledDesignRow")
      );

      if (RowsIndicesSelected.length <= 1) {
        let newRowIndex;
        if (atEnd) {
          newRowIndex = design.numRows;
        } else {
          const boundaryIndex = (isAbove ? min : max)(
            selectedCellPaths.map(p => p.index)
          );
          newRowIndex = Math.max(0, boundaryIndex + +!isAbove);
        }

        changeSetsHelper.updatePure("design", {
          id: design.id,
          numRows: design.numRows + 1
        });

        for (const element of Object.values(state.element)) {
          if (element.index < newRowIndex) continue;
          changeSetsHelper.updatePure("element", {
            id: element.id,
            index: element.index + 1
          });
        }

        for (const disabledRow of disabledRows) {
          if (disabledRow.rowIndex < newRowIndex) continue;
          changeSetsHelper.updatePure("disabledDesignRow", {
            id: disabledRow.id,
            rowIndex: disabledRow.rowIndex + 1
          });
        }

        return changeSetsHelper.execute();
      } else {
        changeSetsHelper.updatePure("design", {
          id: design.id,
          numRows: design.numRows + RowsIndicesSelected.length
        });
        const getNewIndex = getInsertRowIndexHelper({
          isAbove,
          selectedIndices: RowsIndicesSelected,
          numRows: design.numRows
        });
        for (const element of Object.values(state.element)) {
          const newIndex = getNewIndex(element.index);
          if (newIndex !== element.index) {
            changeSetsHelper.updatePure("element", {
              id: element.id,
              index: newIndex
            });
          }
        }
        for (const disabledRow of disabledRows) {
          const newIndex = getNewIndex(disabledRow.rowIndex);
          if (newIndex !== disabledRow.rowIndex) {
            changeSetsHelper.updatePure("disabledDesignRow", {
              id: disabledRow.id,
              rowIndex: newIndex
            });
          }
        }
        return changeSetsHelper.execute();
      }
    },

    [actions.designEdit.removeRows]: (state, __, fullState) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      const selectedCellPaths = getSelectedCellPaths(fullState);
      const rowsIndicesToDelete = uniq(selectedCellPaths.map(p => p.index));

      const design = getDesign(fullState);
      changeSetsHelper.updatePure("design", {
        id: design.id,
        numRows: Math.max(design.numRows - rowsIndicesToDelete.length, 1)
      });

      const getNewIndex = getDeleteRowsIndexHelper({
        numRows: design.numRows,
        rowsIndicesToDelete
      });

      const elementIdsToDelete = [];
      for (const element of Object.values(state.element)) {
        const bin = getItemOfType(fullState, "bin", element.binId);

        // All elements should be attached to bins, so this shouldn't be happening.
        if (!bin) continue;

        if (rowsIndicesToDelete.includes(element.index)) {
          elementIdsToDelete.push(element.id);
        } else {
          changeSetsHelper.updatePure("element", {
            id: element.id,
            index: getNewIndex(element.index)
          });
        }
      }

      const disabledRowsToDelete = [];
      const disabledRows = getAllOfType(fullState, "disabledDesignRow");
      for (const disabledRow of Object.values(disabledRows)) {
        if (rowsIndicesToDelete.includes(disabledRow.rowIndex)) {
          disabledRowsToDelete.push(disabledRow.id);
        } else {
          changeSetsHelper.updatePure("disabledDesignRow", {
            id: disabledRow.id,
            rowIndex: getNewIndex(disabledRow.rowIndex)
          });
        }
      }
      changeSetsHelper.deletePure("disabledDesignRow", disabledRowsToDelete);

      removeItemsAndShallowReferences(
        state,
        "element",
        elementIdsToDelete,
        changeSetsHelper,
        [
          "eugeneRuleElement",
          "elementElementCombo",
          "elementGroupElementGroupCombo"
        ]
      );
      return changeSetsHelper.execute({
        removeInaccessibleItems: true,
        recomputeDigestFasValidation: true,
        recomputeBinValidation: true,
        updateInvalidMaterialAvailabilities: true
      });
    },
    [actions.designEdit.toggleRowDisabled]: (
      state,
      { payload: { rowIndex } },
      fullState
    ) => {
      const { id: designId } = getDesign(fullState);
      const changeSetsHelper = new ChangeSetsHelper(state);
      //get row and see if it is disabled
      const disabledDesignRowsByIndex = keyBy(
        getAllOfType(fullState, "disabledDesignRow"),
        "rowIndex"
      );
      const isCurrentlyDisabled = disabledDesignRowsByIndex[rowIndex];

      if (isCurrentlyDisabled) {
        changeSetsHelper.deletePure(
          "disabledDesignRow",
          isCurrentlyDisabled.id
        );
      } else {
        changeSetsHelper.createPure("disabledDesignRow", {
          rowIndex,
          designId
        });
      }

      return changeSetsHelper.execute({
        // removeInaccessibleItems: true,
        // recomputeDigestFasValidation: true,
        // recomputeBinValidation: true,
        // updateInvalidMaterialAvailabilities: true
      });
    },

    [actions.designEdit
      .changeInternalizationPreferences]: changeInternalizationPreferences,

    [actions.designEdit.swapConstruct]: (
      state,
      { payload: { cardId, elementsInCombination, part } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);

      function areElementsTheSame(elements) {
        if (elements.length !== elementsInCombination.length) return false;
        return elements.every((el, i) => el.id === elementsInCombination[i].id);
      }

      // Remove any existing available element combos containing the same elements.
      const existingElementCombos = getAvailableElementCombosOfCard(
        fullState,
        cardId
      );
      for (const combo of existingElementCombos) {
        const elements = getElementsInCombo(fullState, combo.id);
        if (areElementsTheSame(elements)) {
          changeSetsHelper.deletePure("elementCombo", combo.id);
          changeSetsHelper.deletePure(
            "elementElementCombo",
            getReferencedValue(
              fullState,
              "elementCombo",
              combo.id,
              "elementElementCombos"
            ).map(eec => eec.id)
          );
        }
      }

      const flattened = flattenTree({
        id: uuid(),
        __typename: "elementCombo",
        cardId,
        isDeleted: false,
        availablePartId: part.id,
        elementElementCombos: elementsInCombination.map((el, index) => ({
          id: uuid(),
          __typename: "elementElementCombo",
          index,
          elementId: el.id
        }))
      });
      changeSetsHelper.updateViaFlatObject(flattened);

      const flatPart = flattenTree(part);
      changeSetsHelper.updateViaFlatObject(flatPart);

      return changeSetsHelper.execute({
        removeInaccessibleItems: true
      });
    },

    [actions.designEdit
      .removeAutoDigestElementCombo]: removeAutoDigestElementCombo,

    [actions.designEdit.eliminateCombinations]: eliminateCombinations,

    [actions.designEdit.removeEliminatedCombination]: (
      state,
      { payload: { id } },
      fullState
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(state);
      changeSetsHelper.deletePure("elementGroupCombo", id);
      changeSetsHelper.deletePure(
        "elementGroupElementGroupCombos",
        getReferencedValue(
          fullState,
          "elementGroupCombo",
          id,
          "elementGroupElementGroupCombos"
        ).map(g => g.id)
      );
      return changeSetsHelper.execute();
    },

    [actions.designEdit.cutCells]: (state, __, fullState) => {
      const canEditBin = binId => {
        const bin = getItemOfType(fullState, "bin", binId);
        return !bin.isLocked && !bin.isPlaceholder;
      };
      const selectedCellPaths = getSelectedCellPaths(fullState);
      const elementIds = selectedCellPaths
        .filter(c => c.elementId && canEditBin(c.binId))
        .map(c => c.elementId);
      return removeElements(state, elementIds);
    },

    [actions.designEdit.pasteCells]: pasteCells,

    [actions.designEdit.alphabetizeParts]: alphabetizeParts,

    [actions.designEdit.assignOverhangs]: (
      designState,
      { payload: { inputIndexToThreePrimeOverhang, reactionId } },
      state
    ) => {
      const changeSetsHelper = new ChangeSetsHelper(designState);

      const inputCards = getInputCardsOfReaction(state, reactionId);
      inputCards.forEach((c, i) => {
        const junction = getJunctionOnCard(state, c.id, false);
        if (junction) {
          const bps = inputIndexToThreePrimeOverhang[i];
          changeSetsHelper.updatePure("junction", {
            id: junction.id,
            bps
          });
        }
      });

      return changeSetsHelper.execute({
        recomputeBinValidation: true,
        recomputeElementValidation: true
      });
    }
  },

  initialState
);
