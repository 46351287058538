/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import withQuery from "../withQuery";

import { camelCase, get } from "lodash";
import extendedPropertyFragment from "../fragments/extendedPropertyFragment";
import { modelTypeMap } from "../../../tg-iso-shared/src/utils/modelTypeMap";
import modelNameToLink from "../utils/modelNameToLink";
import { Link } from "react-router-dom";
import { formatDate } from "../utils/dateUtils";

const withLibraryExtendedPropertyColumns = ({
  schema: _schema,
  model: _model,
  updateableModel,
  recordPath,
  modelTypeCode,
  showLoading = true,
  inDialog,
  isLocalTable
}) => Component => {
  const model = updateableModel || _model;
  const isView = updateableModel && updateableModel !== _model;
  return withQuery(extendedPropertyFragment, {
    isPlural: true,
    showLoading,
    inDialog,
    options: () => {
      return {
        variables: {
          filter: {
            modelTypeCode: modelTypeCode || modelTypeMap[model]
          }
        }
      };
    },
    props: ({ data }, ownProps) => {
      const { loading, extendedProperties } = data;
      // only calculate the table fields if needed
      if (!loading) {
        const displayNameHelper = {};
        const schema = _schema || ownProps.schema;
        if (schema) {
          schema.fields.forEach(field => {
            if (field.displayName) {
              displayNameHelper[camelCase(field.displayName)] = 1;
            }
          });
        }
        // these fields are to be used in a table schema
        const additionalFields = extendedProperties.results.map(prop => {
          const getExtendedPropValue = (_record, withLinks) => {
            const record = recordPath ? get(_record, recordPath, {}) : _record;
            if (!record) return null;

            const { extendedStringValueViews = [] } = record;
            const valueForProp = extendedStringValueViews.find(
              v => v.extendedPropertyId === prop.id
            );
            if (valueForProp) {
              let display = valueForProp.value;
              if (withLinks && valueForProp.targetModel) {
                const names = (valueForProp.value || "").split(", ");
                const ids = (valueForProp.linkIds || "").split(", ");
                if (ids.length === names.length) {
                  display = names.map((name, index) => {
                    const id = ids[index];
                    if (id) {
                      return (
                        <React.Fragment key={id}>
                          <Link
                            to={modelNameToLink(valueForProp.targetModel, id)}
                          >
                            {name}
                          </Link>
                          {index !== names.length - 1 && ", "}
                        </React.Fragment>
                      );
                    } else return null;
                  });
                }
              }
              if (prop.extendedType?.code === "timestamp" && display) {
                display = formatDate(display);
              }
              return display;
            }
          };

          let displayName = prop.name;
          const camelD = camelCase(displayName);
          if (displayNameHelper[camelD]) {
            displayName = `${displayName} ${displayNameHelper[camelD]++}`;
          } else {
            displayNameHelper[camelD] = 1;
          }

          const withVal = (v, r) => getExtendedPropValue(r);

          let pathToView = "extendedStringValueViews";
          if (isView) {
            pathToView = updateableModel + "." + pathToView;
          } else if (recordPath) {
            pathToView = recordPath + "." + pathToView;
          }
          // this will also be used for the j5 table card because it is nested in the source part field
          let viewUpdateableModel;
          if (pathToView.includes(".")) {
            viewUpdateableModel = pathToView.split(".")[0];
          }
          return {
            displayName,
            path: pathToView + ".value",
            placementPath: `extProp${prop.id}`,
            additionalColumnFilter: (qb, subFilter) => {
              const inner = qb
                .related(`extendedStringValueView.${model}Id`)
                .whereAll({
                  value: subFilter,
                  extendedPropertyId: prop.id
                });
              if (viewUpdateableModel) {
                return {
                  id: qb.related(`${viewUpdateableModel}.id`, false).whereAll({
                    id: inner
                  })
                };
              } else {
                return {
                  id: inner
                };
              }
            },
            fieldGroup: "Extended Properties",
            type: "string",
            getClipboardData: withVal,
            ...(isLocalTable && {
              getValueToFilterOn: r => getExtendedPropValue(r)
            }),
            render: (v, r) => getExtendedPropValue(r, true)
          };
        });
        const toReturn = {
          hasExtendedProperties: !!extendedProperties.results.length
        };
        // if a schema was passed then add the fields onto it
        // otherwise just return them flat
        if (schema) {
          toReturn.schema = {
            ...schema,
            fields: [...schema.fields, ...additionalFields]
          };
        } else {
          toReturn.extendedPropertyFields = additionalFields;
        }
        return toReturn;
      }
    }
  })(Component);
};

export default withLibraryExtendedPropertyColumns;
