/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { mapKeys, map } from "lodash";

/**
 * Returns id or cid where exists
 * @param {*} sequence
 */
const getIdOrCid = ({ id, cid }) => id || cid;

/**
 * recursive deletion of keys (sanitize sequences)
 * @param {array} array
 * @param {array} keys (to delete)
 */
const deleteKeys = (array, keys) => {
  for (const a of array) {
    for (const k of keys) {
      delete a[k];
    }
    for (const o in a) {
      if (typeof a[o] === "object" && a[o]) {
        Array.isArray(a[o]) ? deleteKeys(a[o], keys) : deleteKeys([a[o]], keys);
      }
    }
  }
};

/**
 *
 * @param {*} sequences
 * @param {*} data
 * @param {string} dataName
 */
const pushDataToSequences = (sequences, data, dataName) => {
  for (const d in data) {
    let seqId = data[d].sequenceId;
    seqId = seqId.replace("&", "");
    if (sequences[seqId]) sequences[seqId][dataName].push(data[d]);
  }
  return sequences;
};

/**
 * Get array of formatted sequences from design
 * @param {*} design
 */
const getSequencesFromDesign = design => {
  let seq = mapKeys(design.sequence, getIdOrCid);
  const seqParts = mapKeys(design.part, getIdOrCid);
  const seqFeatures = mapKeys(design.sequenceFeature, getIdOrCid);
  const seqFragments = mapKeys(design.sequenceFragment, getIdOrCid);

  const keys = Object.keys(seq);
  if (!seq || !keys.length || keys[0] === "undefined") return [];

  // initialize key set
  const keyNames = ["parts", "sequenceFeatures", "sequenceFragments"];

  for (const k in seq) {
    seq[k][keyNames[0]] = [];
    seq[k][keyNames[1]] = [];
    seq[k][keyNames[2]] = [];
  }

  // add parts to sequence
  seq = pushDataToSequences(seq, seqParts, keyNames[0]);

  // add features to sequence
  seq = pushDataToSequences(seq, seqFeatures, keyNames[1]);

  // add fragments to sequence
  seq = pushDataToSequences(seq, seqFragments, keyNames[2]);

  const mapSeq = map(seq, s => {
    s.sequenceTypeCode =
      s.sequenceTypeCode || (s.circular ? "CIRCULAR_DNA" : "LINEAR_DNA");
    return s;
  });

  deleteKeys(mapSeq, ["id", "__typename", "userId"]);

  return mapSeq;
};

/**
 * Get array of formatted sequences from mutation
 * @param {*} mutations
 */
const getSequencesFromOldMutation = mutations => {
  let sequences = mutations
    .filter(item => item.entity === "sequence")
    .map(({ inputs }) => inputs)[0];

  if (!sequences) return [];

  sequences = deleteKeys(sequences, ["cid", "userId"]);

  return sequences;
};

export { getSequencesFromDesign, getSequencesFromOldMutation };
