/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  DialogFooter,
  InputField,
  TextareaField,
  wrapDialog
} from "@teselagen/ui";
import { normalizeBasePairs } from "../../../../src-shared/utils/normalizeValues";
import { compose } from "redux";

class InsertBasePairLiteralDialog extends React.Component {
  onSubmit = async (...args) => {
    try {
      const { onSubmit, hideModal } = this.props;
      await onSubmit(...args);
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error inserting base-pair literal.");
    }
  };

  parseBasePairs = bpText => {
    let parsedBp = "";
    if (bpText !== undefined) {
      parsedBp = normalizeBasePairs(bpText);
    }
    return parsedBp;
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField isRequired autoFocus name="name" label="Name" />
          <TextareaField
            isRequired
            className="tg-allow-dialog-form-enter"
            name="bps"
            label="Base pairs"
            normalize={this.parseBasePairs}
          />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" loading={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Insert Base Pairs",
    style: {
      width: 550
    }
  }),
  reduxForm({
    form: "InsertBasePairLiteralDialogForm"
  })
)(InsertBasePairLiteralDialog);
