/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { withRouter } from "react-router-dom";
import DesignPanel from "../components/HierarchicalDesign/DesignInspector/DesignPanel";
import { compose } from "redux";
import { connect } from "react-redux";
import { isCurrentUserAdmin } from "../../src-shared/selectors/miscDesignSelectors";
import {
  getDesign,
  isDesignVisualReport,
  doesDesignHavePartsets
} from "../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../src-shared/redux/actions";

const mapStateToProps = state => ({
  design: getDesign(state),
  initialValues: {
    ...getDesign(state),
    removeInterruptedFeatures:
      state.ui.designEditor.inspector.removeInterruptedFeatures
  },
  isVisualReport: isDesignVisualReport(state),
  isAdmin: isCurrentUserAdmin(state),
  hasPartsets: doesDesignHavePartsets(state)
});

const mapDispatchToProps = {
  updateDesign: actions.design.updateDesign,
  toggleRemoveInterruptedFeatures:
    actions.ui.designEditor.inspector.toggleRemoveInterruptedFeatures,
  exportToJson: actions.designIo.exportToJson
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DesignPanel);
