/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { get, pick, isEqual } from "lodash";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import {
  Tooltip,
  Colors,
  Icon,
  MenuItem,
  Button,
  Popover,
  Menu
} from "@blueprintjs/core";
import { showConfirmationDialog } from "@teselagen/ui";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import { getSequencesToUse, pluralIfNeeded } from "../../../utils";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import materialRecordFragment from "../../../graphql/fragments/materialRecordFragment";
import RegisteredSamplesCard from "./RegisteredSamplesCard";
import VeCard from "./VeCard";
import CdsCard from "./CDSCard";
import LineageCard from "./LineageCard";
import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import DisplayGrowthConditions from "../../DisplayGrowthConditions";
import { SelectionMethodsCard } from "../StrainRecordView/SelectionMethodsCard";
import StrainRecordInfo, {
  strainLink
} from "../StrainRecordView/StrainRecordInfo";
import AliquotsTableCard from "../../AliquotsTableCard";
import { molecularWeightRender } from "../../../../src-shared/utils/unitUtils";
import {
  safeUpsert,
  safeDelete,
  deleteWithQuery
} from "../../../../src-shared/apolloMethods";
import { getMaterialMolecularWeight } from "../../../../../tg-iso-lims/src/utils/aliquotUtils";
import SequenceJ5ItemTableCard from "../../SequenceJ5ItemTableCard";
import {
  updateSequenceEditor,
  updateSequenceFromProps
} from "../../../../src-shared/utils/sequenceUtils";
import { popoverOverflowModifiers } from "../../../../src-shared/utils/generalUtils";
import CreateAliquotFromMaterialButton from "./CreateAliquotFromMaterialButton";
import { externalReferenceKeys } from "../../../../../tg-iso-shared/constants";

const strainGrowthConditionOverwriteFields = [
  "name",
  "description",
  "shakerSpeed",
  "shakerThrow",
  "temperature",
  "humidity",
  "growthMedia.name",
  "gasComposition.name"
];

const otherStrainOverwriteFields = [
  "biosafetyLevelOverwrite.name",
  "targetOrganismClassOverwrite.name"
];

function hasOverwrittenStrainFields(material) {
  const growthConditionOverwritten = strainGrowthConditionOverwriteFields.some(
    field => get(material, `growthConditionOverwrite.${field}`)
  );
  const otherFieldOverwritten = otherStrainOverwriteFields.some(field =>
    get(material, field)
  );
  return (
    growthConditionOverwritten ||
    otherFieldOverwritten ||
    !!material.microbialMaterialMicrobialMaterialSelectionMethods.length ||
    !!material.microbialMaterialMicrobialMaterialInductionMethods.length ||
    !!material.cellCultureCellCultureSelectionMethods.length ||
    !!material.cellCultureCellCultureInductionMethods.length
  );
}

async function resetMaterialOverwrites(material) {
  try {
    const shouldReset = await showConfirmationDialog({
      text: "Are you sure you would like to reset the material overwrites?",
      intent: "danger",
      icon: "warning-sign"
    });
    if (!shouldReset) return;
    const growthConditionOverwriteId = get(
      material,
      "growthConditionOverwrite.id"
    );
    if (growthConditionOverwriteId) {
      await safeDelete("growthCondition", [growthConditionOverwriteId]);
    }
    await deleteWithQuery("microbialMaterialSelectionMethod", {
      microbialMaterialId: material.id
    });
    await deleteWithQuery("cellCultureSelectionMethod", {
      cellCultureId: material.id
    });
    await deleteWithQuery("microbialMaterialInductionMethod", {
      microbialMaterialId: material.id
    });
    await deleteWithQuery("cellCultureInductionMethod", {
      cellCultureId: material.id
    });

    const materialUpdates = {
      id: material.id,
      biosafetyLevelOverwriteCode: null,
      targetOrganismClassOverwriteId: null
    };
    await safeUpsert(materialRecordFragment, materialUpdates);
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error resetting fields.");
  }
}

export function MaterialInformation({
  material,
  readOnly,
  sequences: _sequences = []
}) {
  let sequences = _sequences;
  if (sequences && !Array.isArray(sequences)) {
    sequences = [sequences];
  }
  const materialTypeCode = material.materialTypeCode;
  const protein = get(
    material,
    "polynucleotideMaterialSequence.sequenceFpus[0].functionalProteinUnit"
  );
  const dnaMaterialType = get(
    material,
    "polynucleotideMaterialSequence.sequenceType.name"
  );
  const molecularWeight = getMaterialMolecularWeight(material);

  const recordInfo = [
    ["Name", material.name],
    ["Type", get(material, "materialType.name")],
    [
      "External Availability",
      material.externalAvailability ? (
        <Icon intent="success" icon="tick" />
      ) : (
        <Icon intent="danger" icon="cross" />
      )
    ]
  ];

  const isRNA = materialTypeCode === "RNA";
  if (materialTypeCode === "DNA" || isRNA) {
    recordInfo.push([
      "Length (bp)",
      get(material, "polynucleotideMaterialSequence.size")
    ]);
    if (molecularWeight) {
      recordInfo.push([
        "Molecular Weight",
        molecularWeightRender(molecularWeight)
      ]);
    }
  }

  protein &&
    recordInfo.push([
      "Relevant Protein Unit",
      <Link
        key={protein.id}
        to={modelNameToLink(protein.__typename, protein.id)}
      >
        {protein.name}
      </Link>
    ]);
  !isRNA && dnaMaterialType && recordInfo.push(["DNA Type", dnaMaterialType]);
  if (materialTypeCode === "MICROBIAL") {
    recordInfo.push(["Vendor Catalog Number", material.vendorCatalogNumber]);
    if (material.microbialMaterialDerivedStrainLine) {
      recordInfo.push([
        "Derived Strain",
        <Link
          key={material.microbialMaterialDerivedStrainLine.id}
          to={modelNameToLink(
            "strain",
            material.microbialMaterialDerivedStrainLine.id
          )}
        >
          {material.microbialMaterialDerivedStrainLine.name}
        </Link>
      ]);
    }
  }

  materialTypeCode === "CELL_CULTURE" &&
    material.cellCultureDerivedStrainLine &&
    recordInfo.push([
      "Derived Cell Line",
      <Link
        key={material.cellCultureDerivedStrainLine.id}
        to={modelNameToLink(
          "cellLine",
          material.cellCultureDerivedStrainLine.id
        )}
      >
        {material.cellCultureDerivedStrainLine.name}
      </Link>
    ]);

  if (material.genome) {
    recordInfo.push([
      "Genome",
      <Link key="genome" to={modelNameToLink(material.genome)}>
        {material.genome.name}
      </Link>
    ]);
  }
  if (sequences.length) {
    recordInfo.push([
      pluralIfNeeded("Sequence", sequences),
      sequences.map((seq, i) => (
        <React.Fragment key={seq.id + "-" + i}>
          <Link
            to={modelNameToLink(isRNA ? "rnaSequence" : seq.__typename, seq.id)}
          >
            {seq.name}
          </Link>
          {i !== sequences.length - 1 && ", "}
        </React.Fragment>
      ))
    ]);
  }
  let selectionMethods;
  if (material.microbialMaterialMicrobialMaterialSelectionMethods.length) {
    selectionMethods =
      material.microbialMaterialMicrobialMaterialSelectionMethods.map(
        mmsm => mmsm.selectionMethod
      );
  } else if (material.cellCultureCellCultureSelectionMethods.length) {
    selectionMethods = material.cellCultureCellCultureSelectionMethods.map(
      ccsm => ccsm.selectionMethod
    );
  }

  return (
    <div>
      <div
        className="tg-double-record-info-container-wrapper"
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <div>
          <h6>Material Information</h6>
          <RecordInfoDisplay
            recordInfo={recordInfo}
            readOnly={readOnly}
            record={material}
          />
        </div>
        {material.strain && (
          <React.Fragment>
            <div>
              <div className="tg-flex align-center">
                <h6>
                  {material.materialTypeCode === "MICROBIAL"
                    ? "Originating Strain Information"
                    : "Originating Cell Line Info"}
                </h6>
                <div className="tg-flex-separator" />
                {strainLink(material.strain)}
              </div>
              <StrainRecordInfo
                readOnly={readOnly}
                strain={material.strain}
                material={material}
              />
            </div>
            <div>
              <DisplayGrowthConditions material={material} />
            </div>
          </React.Fragment>
        )}
      </div>
      {material.strain && (
        <SelectionMethodsCard
          openTitleElements={
            selectionMethods && (
              <Tooltip content="Selection methods overwritten by material settings.">
                <Icon icon="info-sign" color={Colors.ORANGE2} />
              </Tooltip>
            )
          }
          selectionMethods={selectionMethods}
          strain={material.strain}
        />
      )}
    </div>
  );
}

class MaterialRecordView extends Component {
  state = {};

  renderContent(material) {
    if (this.props.data.loading) return;
    if (material) {
      return (
        <MaterialInformation
          material={material}
          readOnly={this.props.readOnly}
          sequences={getSequencesToUse(this.props)}
        />
      );
    }
  }

  renderPromoteToStrain() {
    const { material = {}, refetchMaterial, history } = this.props;
    const materialTypeCode = get(material, "materialTypeCode");
    if (
      (materialTypeCode === "MICROBIAL" ||
        materialTypeCode === "CELL_CULTURE") &&
      (!material.microbialMaterialDerivedStrainLine ||
        !material.cellCultureDerivedStrainLine)
    ) {
      return (
        <Button
          key="promoteToStrain"
          text={
            materialTypeCode === "MICROBIAL"
              ? "Promote to Strain"
              : "Promote to Cell Line"
          }
          intent="success"
          icon="double-chevron-up"
          onClick={() => {
            showDialog({
              modalType: "PROMOTE_TO_STRAIN",
              modalProps: {
                refetch: refetchMaterial,
                history,
                strainTypeCode:
                  materialTypeCode === "MICROBIAL"
                    ? "MICROBIAL_STRAIN"
                    : "CELL_LINE",
                selectedMaterials: [material]
              }
            });
          }}
        />
      );
    }
  }

  renderReactions() {
    const { material = {}, refetchMaterial, history } = this.props;
    const typeCode = material.materialTypeCode;
    let isPlasmid = false;
    if (typeCode === "DNA") {
      if (
        material.polynucleotideMaterialSequence &&
        material.polynucleotideMaterialSequence.sequenceTypeCode ===
          "CIRCULAR_DNA"
      ) {
        isPlasmid = true;
      }
    }
    const menuItems = [];
    if (isPlasmid || typeCode === "MICROBIAL") {
      menuItems.push(
        <MenuItem
          key="microbialTransformation"
          text="Microbial Transformation"
          onClick={() => {
            showDialog({
              modalType: "MATERIAL_MICROBIAL_TRANSFORMATION",
              modalProps: {
                refetch: refetchMaterial,
                sourceMaterials: [material],
                typeCode,
                history,
                dialogProps: {
                  title: `Transform 1 ${
                    typeCode === "DNA" ? "DNA" : "Microbial"
                  } Material`
                }
              }
            });
          }}
        />
      );
    }
    if (menuItems.length > 0 && window.frontEndConfig.reactions) {
      return [
        <Popover
          key="reactions"
          modifiers={popoverOverflowModifiers}
          position="bottom-right"
          content={<Menu>{menuItems}</Menu>}
        >
          <Button key="reactions" text="Reactions" icon="resolve" />
        </Popover>
      ];
    }
    return [];
  }

  componentDidMountOrUpdate() {
    const {
      location,
      history,
      material: { id, materialTypeCode } = {}
    } = this.props;
    if (materialTypeCode === "DNA" && !location.pathname.includes("dna")) {
      return history.replace(`/dna-materials/${id}`);
    } else if (
      materialTypeCode === "RNA" &&
      !location.pathname.includes("rna")
    ) {
      return history.replace(`/rna-materials/${id}`);
    } else if (
      materialTypeCode === "MICROBIAL" &&
      !location.pathname.includes("microbial")
    ) {
      return history.replace(`/microbial-materials/${id}`);
    } else if (
      materialTypeCode === "PROTEIN" &&
      !location.pathname.includes("protein")
    ) {
      return history.replace(`/protein-materials/${id}`);
    } else if (
      materialTypeCode === "CELL_CULTURE" &&
      !location.pathname.includes("cell")
    ) {
      return history.replace(`/cell-cultures/${id}`);
    }
  }

  componentDidMount() {
    this.componentDidMountOrUpdate();
  }

  componentDidUpdate() {
    this.componentDidMountOrUpdate();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newSequences = getSequencesToUse(nextProps);
    const newGenomicRegions = getSequencesToUse({
      ...nextProps,
      isGenomicRegion: true
    });
    const { oldSequences, oldGenomicRegions } = prevState;
    const newState = {};
    if (newSequences.length > 0 && !isEqual(newSequences, oldSequences)) {
      let seq = newSequences[0];
      if (seq.__typename === "material") {
        seq = seq.polynucleotideMaterialSequence;
      }
      if (seq?.__typename === "sequence") {
        updateSequenceFromProps([seq]);
      } else {
        updateSequenceEditor({
          name: "No sequence"
        });
      }
      newState.oldSequences = newSequences;
    }
    if (!isEqual(newGenomicRegions, oldGenomicRegions)) {
      updateSequenceFromProps(newGenomicRegions, {
        editorName: "GenomicRegionEditor"
      });
      newState.oldGenomicRegions = newGenomicRegions;
    }
    return newState;
  }

  afterMaterialUpdate = async () => {
    const { refetchMaterial } = this.props;
    await refetchMaterial();
  };

  render() {
    const {
      material = {},
      history,
      readOnly,
      showLeftSlideOutDrawer
    } = this.props;
    const isMicrobialMaterial =
      material.materialTypeCode === "MICROBIAL" ||
      material.materialTypeCode === "CELL_CULTURE";
    const sequencesToUse = getSequencesToUse(this.props);
    const genomicRegionsToUse = getSequencesToUse({
      ...this.props,
      isGenomicRegion: true
    });
    let additionalCards = [];
    const veCard = (
      <VeCard
        key="ve"
        {...{
          showLeftSlideOutDrawer,
          isMicrobialMaterial,
          hasSequences: sequencesToUse.length,
          sequences: sequencesToUse,
          material,
          readOnly,
          afterMaterialUpdate: this.afterMaterialUpdate
        }}
      />
    );
    if (material.materialTypeCode !== "GENOMIC") {
      additionalCards.push(veCard);
    }

    additionalCards = [
      ...additionalCards,
      material.materialTypeCode !== "CELL_CULTURE" &&
        genomicRegionsToUse.length > 0 && (
          <VeCard
            key="genomicRegions"
            {...{
              isMicrobialMaterial,
              isGenomicRegionsCard: true,
              hasSequences: genomicRegionsToUse.length,
              sequences: genomicRegionsToUse,
              material,
              readOnly,
              afterMaterialUpdate: this.afterMaterialUpdate
            }}
          />
        ),
      (material.materialLineages.length > 0 ||
        material.childrenMaterials.length > 0) && (
        <LineageCard
          key="MaterialLineageCard"
          material={material}
          history={history}
        />
      ),
      <RegisteredSamplesCard
        materialId={material.id}
        key="RegisteredSamplesCard"
      />,
      <AliquotsTableCard
        key="MaterialAliquotsTableCard"
        additionalFilter={(props, qb) => {
          qb.whereAll({
            "sample.materialId": material.id
          });
        }}
      />
    ];
    if (get(material, "materialTypeCode") === "DNA") {
      additionalCards.push(
        <SequenceJ5ItemTableCard
          key="sequenceJ5Items"
          sequenceIds={sequencesToUse.map(s => s.id)}
        />
      );
      additionalCards.push(
        <CdsCard key="MaterialCDSCard" material={material} history={history} />
      );
    }
    const extraMenuItems = [
      <MenuItem
        key="resetMaterialOverwrites"
        text="Reset Material Overwrites"
        onClick={() => resetMaterialOverwrites(material)}
      />
    ];
    return (
      <AbstractRecord
        {...this.props}
        data={this.props.materialQuery}
        additionalUpdateMenuItems={
          hasOverwrittenStrainFields(material) && extraMenuItems
        }
        additionalButtons={[
          <CreateAliquotFromMaterialButton
            key="createAliquot"
            material={material}
          />,
          ...this.renderReactions(),
          this.renderPromoteToStrain()
        ]}
        updateShowFunction={() => {
          const initialValues = pick(material, [
            "id",
            "name",
            "strain",
            `vendorCatalogNumber`,
            "externalAvailability",
            ...externalReferenceKeys,
            "materialTypeCode",
            "genome"
          ]);

          const matGrowth = material.growthConditionOverwrite || {};
          const strainGrowth = get(material, "strain.growthCondition") || {};

          initialValues.growthCondition = {};
          let overwriteStrainFields = false;
          const growthFields = [
            "name",
            "description",
            "shakerSpeed",
            "shakerThrow",
            "humidity",
            "lengthUnitCode",
            "temperature"
          ];
          growthFields.forEach(field => {
            if (matGrowth[field]) overwriteStrainFields = true;
            initialValues.growthCondition[field] =
              matGrowth[field] || strainGrowth[field];
          });

          if (matGrowth.growthMedia) overwriteStrainFields = true;
          initialValues.growthCondition.growthMedia =
            matGrowth.growthMedia || strainGrowth.growthMedia;

          if (matGrowth.gasComposition) overwriteStrainFields = true;
          initialValues.growthCondition.gasComposition =
            matGrowth.gasComposition || strainGrowth.gasComposition;

          if (material.biosafetyLevelOverwrite) {
            overwriteStrainFields = true;
            initialValues.biosafetyLevelCode =
              material.biosafetyLevelOverwrite.code;
          } else {
            initialValues.biosafetyLevelCode = get(
              material,
              "strain.biosafetyLevel.code"
            );
          }

          if (material.targetOrganismClassOverwrite) {
            overwriteStrainFields = true;

            initialValues.targetOrganismClassId =
              material.targetOrganismClassOverwrite.id;
          } else {
            initialValues.targetOrganismClassId = get(
              material,
              "strain.targetOrganismClass.id"
            );
          }

          const overwrittenSelectionMethods = material
            .microbialMaterialMicrobialMaterialSelectionMethods.length
            ? material.microbialMaterialMicrobialMaterialSelectionMethods
            : material.cellCultureCellCultureSelectionMethods;
          if (overwrittenSelectionMethods.length) {
            overwriteStrainFields = true;

            initialValues.selectionMethodIds = overwrittenSelectionMethods.map(
              mmsm => mmsm.selectionMethod.id
            );
          } else {
            initialValues.selectionMethodIds = get(
              material,
              "strain.strainSelectionMethods",
              []
            ).map(ssm => ssm.selectionMethod.id);
          }

          const overwrittenInductionMethods = material
            .microbialMaterialMicrobialMaterialInductionMethods.length
            ? material.microbialMaterialMicrobialMaterialInductionMethods
            : material.cellCultureCellCultureInductionMethods;

          if (overwrittenInductionMethods.length) {
            overwriteStrainFields = true;

            initialValues.inductionMethodIds = overwrittenInductionMethods.map(
              mmsm => mmsm.inductionMethod.id
            );
          } else {
            initialValues.inductionMethodIds = get(
              material,
              "strain.inductionMethodStrains",
              []
            ).map(ssm => ssm.inductionMethod.id);
          }

          initialValues.overwriteStrainFields = overwriteStrainFields;

          return showDialog({
            modalType: "UPDATE_MATERIAL",
            modalProps: {
              refetch: this.afterMaterialUpdate,
              initialValues,
              material
            }
          });
        }}
        recordName="material"
        additionalCards={additionalCards}
      >
        {this.renderContent(material)}
      </AbstractRecord>
    );
  }
}

export default compose(
  recordViewEnhancer(materialRecordFragment, {}),
  reduxForm({
    form: "materialRecordView",
    enableReinitialize: true
  })
)(MaterialRecordView);
