/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import shortid from "shortid";
import {
  calculateAliquotMolarityAndConcentration,
  getMaterialMolecularWeight
} from "../../../tg-iso-lims/src/utils/aliquotUtils";
import { safeUpsert, safeQuery } from "../../src-shared/apolloMethods";
import { getMaterialFields } from "../../../tg-iso-shared/src/sequence-import-utils/getMaterialFields";
import getAliquotNumberOfCells from "../../../tg-iso-lims/src/utils/unitUtils/getAliquotNumberOfCells";
import queryBuilder from "tg-client-query-builder";

export async function filterDuplicateMicrobialMaterials(materials, reactions) {
  const filteredMaterials = [];
  const cidKeyedMaterialMap = keyBy(materials, m => `&${m.cid}`);
  const duplicateMap = await checkForDuplicateMicrobialMaterials(materials);
  reactions.forEach(reaction => {
    const outputMaterial =
      cidKeyedMaterialMap[reaction.reactionOutputs[0].outputMaterialId];
    const existingMaterial = duplicateMap.get(outputMaterial);
    if (existingMaterial) {
      reaction.reactionOutputs[0].outputMaterialId = existingMaterial.id;
    } else {
      filteredMaterials.push(outputMaterial);
    }
  });
  return filteredMaterials;
}

export async function checkForDuplicateMicrobialMaterials(materials) {
  const strainIds = materials.map(m => m.strainId);
  const dnaMatIds = [];

  // collect a single plasmid id from each material
  // we can use this to search for plasmids that are already linked to materials
  // if those plasmids are linked to materials we can see if they are a full match
  materials.forEach(mat => {
    let materialDNAMatKey;
    if (mat.materialTypeCode === "MICROBIAL") {
      materialDNAMatKey = "microbialMaterialMicrobialMaterialPlasmids";
    } else if (mat.materialTypeCode === "CELL_CULTURE") {
      materialDNAMatKey = "cellCultureCellCulturePlasmids";
    }
    mat[materialDNAMatKey].some(mmPlasm => {
      const dnaMatId = mmPlasm.polynucleotideMaterialId;
      if (dnaMatId) {
        if (!dnaMatIds.includes(dnaMatId)) {
          dnaMatIds.push(dnaMatId);
        }
        return true;
      } else {
        return false;
      }
    });
  });

  const dnaMaterials = await safeQuery(
    [
      "material",
      `
    id
    polynucleotideMaterialMicrobialMaterialPlasmids {
      id
      copyNumber
      microbialMaterial {
        id
        strainId
        microbialMaterialMicrobialMaterialPlasmids {
          id
          polynucleotideMaterialId
          copyNumber
        }
      }
    }
    `
    ],
    {
      variables: {
        filter: {
          "polynucleotideMaterialMicrobialMaterialPlasmids.microbialMaterial.strainId":
            strainIds,
          id: dnaMatIds
        }
      }
    }
  );

  const groupedExistingMaterials = {};
  const addedMatIds = [];
  dnaMaterials.forEach(dnaMat => {
    dnaMat?.polynucleotideMaterialMicrobialMaterialPlasmids.forEach(pmmp => {
      const microbialMaterial = pmmp.microbialMaterial;
      if (microbialMaterial && !addedMatIds.includes(microbialMaterial.id)) {
        if (!groupedExistingMaterials[microbialMaterial.strainId]) {
          groupedExistingMaterials[microbialMaterial.strainId] = [];
        }
        addedMatIds.push(microbialMaterial.id);
        groupedExistingMaterials[microbialMaterial.strainId].push(
          microbialMaterial
        );
      }
    });
  });

  const materialDuplicateMap = new Map();
  materials.forEach(material => {
    const existingMaterialsWithStrain =
      groupedExistingMaterials[material.strainId] || [];
    const keyedMicrobialPlasmids = keyBy(
      material.microbialMaterialMicrobialMaterialPlasmids,
      "polynucleotideMaterialId"
    );
    const matchingMaterial = existingMaterialsWithStrain.find(mat => {
      return (
        mat.microbialMaterialMicrobialMaterialPlasmids.length ===
          material.microbialMaterialMicrobialMaterialPlasmids.length &&
        mat.microbialMaterialMicrobialMaterialPlasmids.every(mmPlasm => {
          const matchingMMPlasm =
            keyedMicrobialPlasmids[mmPlasm.polynucleotideMaterialId];
          return (
            matchingMMPlasm && matchingMMPlasm.copyNumber === mmPlasm.copyNumber
          );
        })
      );
    });

    if (matchingMaterial) {
      materialDuplicateMap.set(material, matchingMaterial);
    }
  });
  return materialDuplicateMap;
}

export async function getAliquotValuesForMaterial({
  material: _material,
  aliquotType,
  concentration,
  concentrationUnitCode,
  cellConcentration,
  cellConcentrationUnitCode,
  isDry,
  concentrationType,
  molarity,
  molarityUnitCode,
  mass,
  massUnitCode,
  volume,
  volumetricUnitCode
}) {
  let material = _material;
  const canHaveMolarity =
    material.materialTypeCode === "DNA" ||
    material.materialTypeCode === "PROTEIN";
  if (canHaveMolarity) {
    material = await safeQuery(
      [
        "material",
        /* GraphQL */ `
          {
            id
            materialTypeCode
            polynucleotideMaterialSequence {
              id
              molecularWeight
            }
            functionalProteinUnit {
              id
              molecularWeight
            }
          }
        `
      ],
      {
        variables: {
          id: _material.id
        }
      }
    );
  }
  const aliquotValues = {
    isDry,
    aliquotType: aliquotType || "sample-aliquot",
    mass,
    massUnitCode,
    volume,
    volumetricUnitCode,
    cellConcentration,
    cellConcentrationUnitCode
  };
  if (concentrationType === "molarity") {
    aliquotValues.molarity = molarity;
    aliquotValues.molarityUnitCode = molarityUnitCode;
  } else {
    aliquotValues.concentration = concentration;
    aliquotValues.concentrationUnitCode = concentrationUnitCode;
  }
  if (cellConcentration && volume) {
    aliquotValues.cellCount = getAliquotNumberOfCells(aliquotValues);
  }

  if (canHaveMolarity) {
    const molecularWeight = getMaterialMolecularWeight(material);
    // use user selected values for units
    aliquotValues.molarityUnitCode = molarityUnitCode;
    aliquotValues.concentrationUnitCode = concentrationUnitCode;
    if (concentrationType === "molarity") {
      aliquotValues.molarity = molarity;
    } else {
      aliquotValues.concentration = concentration;
    }
    if (molecularWeight) {
      calculateAliquotMolarityAndConcentration({
        aliquotValues,
        molecularWeight,
        concentrationType
      });
    }
  } else {
    delete aliquotValues.concentration;
    delete aliquotValues.molarity;
  }
  return aliquotValues;
}

export async function handleMicrobialPlasmidUpload({
  strainId,
  materialId,
  materialTypeCode,
  strainTypeCode,
  newSequences = [],
  existingSequenceIds = [],
  dnaMaterialIdsToAdd = []
}) {
  let microbialMaterials = [];
  const microbialMaterialPlasmidsToCreate = [];
  const cellCulturePlasmidsToCreate = [];
  const strainPlasmidsToCreate = [];

  const qb = new queryBuilder("sequence");
  qb.whereAll({
    id: existingSequenceIds,
    polynucleotideMaterialId: qb.isNull()
  });
  const sequencesWithoutMaterials = existingSequenceIds.length
    ? await safeQuery(["sequence", "id name polynucleotideMaterialId"], {
        variables: { filter: qb.toJSON() }
      })
    : [];
  // make dna materials if needed for existing sequences
  if (sequencesWithoutMaterials.length) {
    const newMaterials = [];
    const seqUpdates = [];
    sequencesWithoutMaterials.forEach(seq => {
      const cid = shortid();
      newMaterials.push({
        ...getMaterialFields(false),
        cid,
        name: seq.name
      });
      seqUpdates.push({
        id: seq.id,
        polynucleotideMaterialId: `&${cid}`
      });
    });
    await safeUpsert("material", newMaterials, {
      excludeResults: true
    });
    await safeUpsert("sequence", seqUpdates, {
      excludeResults: true
    });
  }

  const seqsWithMaterials = await safeQuery(
    ["sequence", "id polynucleotideMaterialId"],
    { variables: { filter: { id: existingSequenceIds } } }
  );
  const existingDNAMaterialIds = seqsWithMaterials.map(
    s => s.polynucleotideMaterialId
  );

  if (strainId) {
    microbialMaterials = await safeQuery(["material", "id"], {
      variables: {
        filter: {
          strainId
        }
      }
    });
  }
  // handle special logic for existing sequences
  // need to query join tables
  if (materialId) {
    let existingJoins;
    if (materialTypeCode === "MICROBIAL") {
      existingJoins = await safeQuery(
        ["microbialMaterialPlasmid", "id polynucleotideMaterialId"],
        {
          variables: {
            filter: {
              microbialMaterialId: materialId,
              polynucleotideMaterialId: existingDNAMaterialIds
            }
          }
        }
      );
    } else if (materialTypeCode === "CELL_CULTURE") {
      existingJoins = await safeQuery(
        ["cellCulturePlasmid", "id polynucleotideMaterialId"],
        {
          variables: {
            filter: {
              cellCultureId: materialId,
              polynucleotideMaterialId: existingDNAMaterialIds
            }
          }
        }
      );
    }
    const alreadyLinkedDNAMaterials = existingJoins.map(
      j => j.polynucleotideMaterialId
    );
    const dnaMatIdsToLink = existingDNAMaterialIds
      .filter(id => !alreadyLinkedDNAMaterials.includes(id))
      .concat(dnaMaterialIdsToAdd);
    dnaMatIdsToLink.forEach(polynucleotideMaterialId => {
      if (materialTypeCode === "MICROBIAL") {
        microbialMaterialPlasmidsToCreate.push({
          polynucleotideMaterialId,
          microbialMaterialId: materialId
        });
      } else if (materialTypeCode === "CELL_CULTURE") {
        cellCulturePlasmidsToCreate.push({
          polynucleotideMaterialId,
          cellCultureId: materialId
        });
      }
    });
  } else if (strainId) {
    const existingStrainJoins = await safeQuery(
      ["strainPlasmid", "id plasmidId"],
      {
        variables: {
          filter: {
            strainId: strainId,
            plasmidId: existingSequenceIds
          }
        }
      }
    );
    const alreadyLinkedStrainPlasmids = existingStrainJoins.map(
      j => j.plasmidId
    );
    const strainPlasmidIdsToLink = existingSequenceIds.filter(
      id => !alreadyLinkedStrainPlasmids.includes(id)
    );
    strainPlasmidIdsToLink.forEach(plasmidId => {
      strainPlasmidsToCreate.push({
        plasmidId,
        strainId
      });
    });
    const microbialMaterialIds = microbialMaterials.map(m => m.id);
    let existingMaterialJoins;
    if (strainTypeCode === "MICROBIAL_STRAIN") {
      existingMaterialJoins = await safeQuery(
        [
          "microbialMaterialPlasmid",
          "id microbialMaterialId polynucleotideMaterialId polynucleotideMaterial { id polynucleotideMaterialSequence { id } }"
        ],
        {
          variables: {
            filter: {
              microbialMaterialId: microbialMaterialIds,
              "polynucleotideMaterial.polynucleotideMaterialSequence.id":
                existingSequenceIds
            }
          }
        }
      );
    } else if (strainTypeCode === "CELL_LINE") {
      existingMaterialJoins = await safeQuery(
        [
          "cellCulturePlasmid",
          "id cellCultureId polynucleotideMaterialId polynucleotideMaterial { id polynucleotideMaterialSequence { id } }"
        ],
        {
          variables: {
            filter: {
              cellCultureId: microbialMaterialIds,
              "polynucleotideMaterial.polynucleotideMaterialSequence.id":
                existingSequenceIds
            }
          }
        }
      );
    }
    const groupedMaterialJoins = {};
    existingMaterialJoins.forEach(join => {
      const joinKey =
        strainTypeCode === "MICROBIAL_STRAIN"
          ? join.microbialMaterialId
          : join.cellCultureId;
      if (!groupedMaterialJoins[joinKey]) {
        groupedMaterialJoins[joinKey] = [];
      }
      groupedMaterialJoins[joinKey].push(join.polynucleotideMaterialId);
    });
    microbialMaterialIds.forEach(matId => {
      const alreadyLinkedDNAMats = groupedMaterialJoins[matId] || [];
      const dnaMatIdsToLink = existingDNAMaterialIds.filter(
        id => !alreadyLinkedDNAMats.includes(id)
      );
      dnaMatIdsToLink.forEach(polynucleotideMaterialId => {
        if (strainTypeCode === "MICROBIAL_STRAIN") {
          microbialMaterialPlasmidsToCreate.push({
            polynucleotideMaterialId,
            microbialMaterialId: matId
          });
        } else if (strainTypeCode === "CELL_LINE") {
          cellCulturePlasmidsToCreate.push({
            polynucleotideMaterialId,
            cellCultureId: matId
          });
        }
      });
    });
  }
  // for new sequences just need to get all material ids linked to strain if strainId,
  // otherwise just upsert new joins for this materialId

  const sequenceDnaMaterials = [];
  newSequences.forEach(s => {
    const cid = shortid();
    s.polynucleotideMaterialId = `&${cid}`;
    sequenceDnaMaterials.push({
      cid,
      name: s.name,
      ...getMaterialFields(false)
    });
  });

  const getNewMicrobialMaterialPlasmids = materialId => {
    return newSequences.map(s => {
      return {
        microbialMaterialId: materialId,
        polynucleotideMaterialId: s.polynucleotideMaterialId
      };
    });
  };

  const getNewCellCulturePlasmids = materialId => {
    return newSequences.map(s => {
      return {
        cellCultureId: materialId,
        polynucleotideMaterialId: s.polynucleotideMaterialId
      };
    });
  };

  if (materialId) {
    if (materialTypeCode === "MICROBIAL") {
      microbialMaterialPlasmidsToCreate.push(
        ...getNewMicrobialMaterialPlasmids(materialId)
      );
    } else if (materialTypeCode === "CELL_CULTURE") {
      cellCulturePlasmidsToCreate.push(
        ...getNewCellCulturePlasmids(materialId)
      );
    }
  } else if (strainId) {
    strainPlasmidsToCreate.push(
      ...newSequences.map(seq => {
        return {
          strainId,
          plasmidId: `&${seq.cid}`
        };
      })
    );
    if (strainTypeCode === "MICROBIAL_STRAIN") {
      microbialMaterials.forEach(mat => {
        microbialMaterialPlasmidsToCreate.push(
          ...getNewMicrobialMaterialPlasmids(mat.id)
        );
      });
    } else if (strainTypeCode === "CELL_LINE") {
      microbialMaterials.forEach(mat => {
        cellCulturePlasmidsToCreate.push(...getNewCellCulturePlasmids(mat.id));
      });
    }
  }

  await safeUpsert("material", sequenceDnaMaterials, {
    excludeResults: true
  });
  await safeUpsert("sequence", newSequences, {
    excludeResults: true
  });
  await safeUpsert("strainPlasmid", strainPlasmidsToCreate, {
    excludeResults: true
  });
  await safeUpsert(
    "microbialMaterialPlasmid",
    microbialMaterialPlasmidsToCreate,
    {
      excludeResults: true
    }
  );
  await safeUpsert("cellCulturePlasmid", cellCulturePlasmidsToCreate, {
    excludeResults: true
  });
}

export function getMaterialPlasmid(joinTable) {
  return joinTable?.polynucleotideMaterial;
}

export function getMaterialPlasmidSequence(joinTable) {
  return joinTable?.polynucleotideMaterial?.polynucleotideMaterialSequence;
}

export function getTransformationMicrobialMaterial(
  donorMaterial,
  inputMicrobialMaterial
) {
  const allMaterialNames = [];
  const outputMaterialStrainId = inputMicrobialMaterial.strain?.id;

  let donorDnaMaterialId;
  if (donorMaterial.materialTypeCode === "DNA") {
    donorDnaMaterialId = donorMaterial.id;
    const name =
      donorMaterial.name || donorMaterial.polynucleotideMaterialSequence.name;
    if (!allMaterialNames.includes(name)) {
      allMaterialNames.push(name);
    }
  } else if (donorMaterial?.microbialMaterialMicrobialMaterialPlasmids.length) {
    const mat =
      donorMaterial.microbialMaterialMicrobialMaterialPlasmids[0]
        .polynucleotideMaterial;
    donorDnaMaterialId = mat.id;
    allMaterialNames.push(mat.name || mat.polynucleotideMaterialSequence?.name);
  } else if (donorMaterial.cellCultureCellCulturePlasmids.length) {
    const mat =
      donorMaterial.cellCultureCellCulturePlasmids[0].polynucleotideMaterial;
    donorDnaMaterialId = mat.id;
    allMaterialNames.push(mat.name || mat.polynucleotideMaterialSequence?.name);
  }
  const outputMaterialCid = shortid();
  const outputMaterialPlasmids = [];
  let materialRefKey;
  if (inputMicrobialMaterial.materialTypeCode === "MICROBIAL") {
    materialRefKey = "microbialMaterialId";
  } else if (inputMicrobialMaterial.materialTypeCode === "CELL_CULTURE") {
    materialRefKey = "cellCultureId";
  }
  outputMaterialPlasmids.push({
    [materialRefKey]: `&${outputMaterialCid}`,
    copyNumber: 1,
    polynucleotideMaterialId: donorDnaMaterialId
  });
  // if the input microbial material already has plasmids, these need to be transferred in the output
  if (
    inputMicrobialMaterial.microbialMaterialMicrobialMaterialPlasmids &&
    inputMicrobialMaterial.microbialMaterialMicrobialMaterialPlasmids.length > 0
  ) {
    inputMicrobialMaterial.microbialMaterialMicrobialMaterialPlasmids.forEach(
      microbialMaterialPlasmid => {
        const plasmid =
          microbialMaterialPlasmid.polynucleotideMaterial
            ?.polynucleotideMaterialSequence;
        const polynucleotideMaterial =
          microbialMaterialPlasmid.polynucleotideMaterial;
        if (polynucleotideMaterial.id === donorDnaMaterialId) {
          outputMaterialPlasmids[0].copyNumber++;
        } else {
          outputMaterialPlasmids.push({
            microbialMaterialId: `&${outputMaterialCid}`,
            copyNumber: microbialMaterialPlasmid.copyNumber || 1,
            polynucleotideMaterialId: polynucleotideMaterial.id
          });
          allMaterialNames.push(plasmid?.name || polynucleotideMaterial.name);
        }
      }
    );
  } else if (
    inputMicrobialMaterial.cellCultureCellCulturePlasmids &&
    inputMicrobialMaterial.cellCultureCellCulturePlasmids.length > 0
  ) {
    inputMicrobialMaterial.cellCultureCellCulturePlasmids.forEach(
      cellCulturePlasmid => {
        const plasmid =
          cellCulturePlasmid.polynucleotideMaterial
            ?.polynucleotideMaterialSequence;
        const polynucleotideMaterial =
          cellCulturePlasmid.polynucleotideMaterial;
        if (polynucleotideMaterial.id === polynucleotideMaterial) {
          outputMaterialPlasmids[0].copyNumber++;
        } else {
          outputMaterialPlasmids.push({
            cellCultureId: `&${outputMaterialCid}`,
            copyNumber: cellCulturePlasmid.copyNumber || 1,
            polynucleotideMaterialId: polynucleotideMaterial.id
          });
          allMaterialNames.push(plasmid?.name || polynucleotideMaterial.name);
        }
      }
    );
  }
  let outputMaterialTypeCode;
  let plasmidKey;
  if (inputMicrobialMaterial.materialTypeCode === "MICROBIAL") {
    outputMaterialTypeCode = "MICROBIAL";
    plasmidKey = "microbialMaterialMicrobialMaterialPlasmids";
  } else if (inputMicrobialMaterial.materialTypeCode === "CELL_CULTURE") {
    outputMaterialTypeCode = "CELL_CULTURE";
    plasmidKey = "cellCultureCellCulturePlasmids";
  }
  const outputMaterial = {
    cid: outputMaterialCid,
    name: `${
      inputMicrobialMaterial.strain?.name || "No strain"
    } (${allMaterialNames
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .join(", ")})`,
    materialTypeCode: outputMaterialTypeCode,
    strainId: outputMaterialStrainId,
    [plasmidKey]: outputMaterialPlasmids,
    materialLineages: [
      {
        parentMaterialId: inputMicrobialMaterial.id
      },
      {
        parentMaterialId: donorMaterial.id
      }
    ]
  };
  return outputMaterial;
}
