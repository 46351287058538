/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";

import { Dialog, Classes } from "@blueprintjs/core";
import { uniqBy } from "lodash";
import { DataTable, showProgressToast, DialogFooter } from "@teselagen/ui";
import Promise from "bluebird";
import {
  designPartFragment,
  designPartsetFragment
} from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import { getInputCardById } from "../../../../src-shared/selectors/classicViewSelectors";
import shortid from "shortid";
import GeneratePartsFromDigest from "../GeneratePartsFromDigest/GeneratePartsFromDigest";
import { groupBy } from "lodash";

class CreateDigestPartsFromSequenceDialog extends React.Component {
  state = {
    page: 1,
    sequences: [],
    filteringTags: []
  };
  onSubmit = async digestParts => {
    let cancelToast;
    const { selectedCellPaths, selectedBinIds, createElements } = this.props;

    const numPartsCreated = digestParts.length;

    const sequenceIdToPart = {};
    let creationOfElementProgress = 0;
    const progressToastId = shortid();

    //below is inserting part/partset in order chosen, which has 3 cases
    //1. user selected 1 cell, in which case insert part/partsets in bin and replace anything in the way. insertionDirection = down
    //2. user selected 2+ cells and theyre in the same bin, fill downwards and replace anything in the way. insertionDirection = down
    //3. user selected 3+ cells and theyre in different bins, fill to the right, replace anything in the way. insertionDirection = across
    //the position arg, will be where the part/partset will be inserted in the grid

    let currentBinToInsert = selectedBinIds[0];
    let currentCellIndexToInsert = selectedCellPaths[0].index;
    let insertionDirection;
    // [{index: 4, elementId: undefined, binId: "5"}]
    const cellsToChangeToDigest = [];
    //insertionDirection is down/across depending on the conditions noted above
    if (
      selectedCellPaths.length > 1 &&
      selectedBinIds[0] !== selectedBinIds[1]
    ) {
      insertionDirection = "across";
    } else {
      insertionDirection = "down";
    }

    cancelToast = showProgressToast(
      "Creating Elements From Digest",
      creationOfElementProgress,
      progressToastId
    );

    const createElementPayloads = [];

    const digestPartsBySequenceId = groupBy(digestParts, "sequenceId");

    await Promise.mapSeries(
      Object.keys(digestPartsBySequenceId),
      async (sequenceId, i, arrLength) => {
        const digestPartsForSequence =
          digestPartsBySequenceId[sequenceId] || [];
        const firstPart = digestPartsForSequence[0];
        if (digestPartsForSequence.length === 1) {
          sequenceIdToPart[sequenceId] = {
            isPart: true,
            ...firstPart
          };
        } else if (digestPartsForSequence.length > 1) {
          //if multiple parts in array, then its a partset
          //create partset to reference
          const partSetName = `Auto digested partset from ` + firstPart.name;
          const [{ id: partsetId }] = await safeUpsert("partset", {
            name: partSetName,
            description: `Part set generated by auto digest in design for sequence of ID ${sequenceId}`,
            numParts: digestPartsForSequence.length,
            partsetParts: digestPartsForSequence.map(p => ({ partId: p.id }))
          });

          //create map of sequence Ids to parts for later use
          sequenceIdToPart[sequenceId] = {
            id: partsetId,
            name: partSetName,
            partId: firstPart.id,
            isPartset: true
          };
        }

        const partOrPartsetObj = sequenceIdToPart[sequenceId] || null;

        if (partOrPartsetObj) {
          const position = {
            currentBinToInsert,
            currentCellIndexToInsert
          };
          const elementGuid = shortid();
          const payload = await this.getCreateElementPayload({
            [partOrPartsetObj.isPart ? "part" : "partset"]: partOrPartsetObj,
            position,
            elementGuid
          });
          if (payload) {
            createElementPayloads.push(payload);
          }

          cellsToChangeToDigest.push({
            index: currentCellIndexToInsert,
            elementId: elementGuid,
            binId: currentBinToInsert
          });

          if (insertionDirection === "down") {
            currentCellIndexToInsert += 1;
          } else if (insertionDirection === "across") {
            //binId is a string for some reason
            currentBinToInsert = (Number(currentBinToInsert) + 1).toString();
          }
        }
        creationOfElementProgress = (i + 1) / arrLength;
        cancelToast = showProgressToast(
          "Creating Elements From Digest",
          creationOfElementProgress,
          progressToastId
        );
      }
    );

    createElementPayloads.forEach(payload => {
      createElements(payload);
    });

    if (cellsToChangeToDigest.length) {
      this.changeCellToDigestFas(cellsToChangeToDigest);
    }

    if (numPartsCreated > 0) {
      this.wrappedHideModal();
    }

    cancelToast && cancelToast();
  };

  changeCellToDigestFas = cellsToChangeToDigest => {
    const { changeFas, state } = this.props;

    const cardIdToElementIdsMap = cellsToChangeToDigest.reduce((acc, c) => {
      if (!c.elementId) return acc;
      const card = getInputCardById(state, c.binId);
      if (!acc[card.id]) acc[card.id] = [];
      acc[card.id].push(c.elementId);
      return acc;
    }, {});

    changeFas({ fas: "DIGEST", cardIdToElementIdsMap });
  };

  wrappedHideModal = () => {
    const { hideModal, resolve } = this.props;
    hideModal();
    if (resolve) resolve();
  };

  handleSelect = sequences => {
    const oldSequences = this.state.sequences;
    const newSequences = Array.isArray(sequences) ? sequences : [sequences];
    //the sequences will retain the past clicked sequences
    //if you multi select 3, then unselect them and multiselect another 3
    //this below will account for that
    if (newSequences.length <= oldSequences.length) {
      this.setState({
        sequences: newSequences
      });
    } else {
      const sequencesInClickedOrder = uniqBy(
        oldSequences.concat(newSequences),
        "id"
      );
      this.setState({
        sequences: sequencesInClickedOrder
      });
    }
  };

  handleDoubleClick = async sequence => {
    this.setState({
      sequences: [sequence],
      page: 2
    });
  };

  handleTagFilterClick = ({
    filteringTags,
    addTagFilter,
    removeTagFilter,
    tag
  }) => {
    if (filteringTags.some(ft => ft.id === tag.id)) {
      removeTagFilter(tag);
    } else {
      addTagFilter(tag);
    }
  };

  getCreateElementPayload = async ({
    part,
    partset,
    position,
    elementGuid
  }) => {
    try {
      const { selectedCellPaths } = this.props;
      const { currentBinToInsert, currentCellIndexToInsert } = position;

      let fullPart, fullPartset;
      if (part) {
        fullPart = await safeQuery(designPartFragment, {
          variables: { id: part.id }
        });
      } else {
        fullPartset = await safeQuery(designPartsetFragment, {
          variables: { id: partset.id }
        });
      }
      const elementIdsToDelete = selectedCellPaths.reduce((acc, cp) => {
        if (cp.elementId) acc.push(cp.elementId);
        return acc;
      }, []);

      // createElements takes an object or array for values.
      // objects passed into values has to be object pointing to object with values.
      // create elements can only accept 1 binId.
      return {
        binId: currentBinToInsert,
        values: { part: fullPart, partset: fullPartset },
        elementIdsToDelete,
        cellIndex: currentCellIndexToInsert || 0,
        elementGuids: [elementGuid]
      };
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const {
      tableParams,
      sequencesCount,
      loading,
      isSingleSelect,
      withCheckboxes = false,
      submitting
    } = this.props;

    const { sequences, page } = this.state;

    let content;
    if (page === 1) {
      content = (
        <DataTable
          formName="CreateDigestPartsFromSequenceDialog"
          {...tableParams}
          isSingleSelect={isSingleSelect}
          entityCount={sequencesCount}
          isLoading={loading}
          withSearch
          withPaging
          canOutsideClickClose={false}
          isInfinite={false}
          onSingleRowSelect={this.handleSelect}
          onMultiRowSelect={this.handleSelect}
          onDoubleClick={this.handleDoubleClick}
          withCheckboxes={withCheckboxes}
        />
      );
    } else if (page === 2) {
      content = (
        <GeneratePartsFromDigest
          sequenceIds={this.state.sequences.map(seq => seq.id)}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <div>
        <Dialog
          canOutsideClickClose={false}
          isOpen
          onClose={this.wrappedHideModal}
          title={
            page === 1
              ? "Choose Sequence(s) to Digest"
              : `Choose a Digesting Enzyme for ${this.state.sequences
                  .map(seq => seq.name)
                  .join(", ")}`
          }
          style={{ width: 950 }}
          className="compact-lib-dialog"
        >
          <div className={Classes.DIALOG_BODY}>{content}</div>
          {// Page two content comes with its own DialogFooter.
          page === 1 && (
            <DialogFooter
              hideModal={this.wrappedHideModal}
              disabled={!sequences.length}
              submitting={submitting}
              text="Next"
              onClick={() => this.setState({ page: page + 1 })}
            />
          )}
        </Dialog>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.digestingEnzyme || !values.digestingEnzyme.length)
    errors.digestingEnzyme = "Valid Enzyme Required";
  return errors;
};

export default reduxForm({
  form: "CreateDigestPartsFromSequenceDialog",
  enableReinitialize: true,
  validate
})(CreateDigestPartsFromSequenceDialog);
