/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MenuItem, Intent, Button } from "@blueprintjs/core";
import { startsWith } from "lodash";
import b64ToBlob from "b64-to-blob";
import download from "downloadjs";
import { showConfirmationDialog } from "@teselagen/ui";
import sanitizeFilename from "sanitize-filename";
import { isVisualReport } from "../../../../utils/designUtils";
import assemblyBatchLibraryFragment from "../../../../graphql/fragments/assemblyBatchLibraryFragment";
import { j5ReportHasErrors } from "../../../../../../tg-iso-shared/src/j5-report";
import defaultAsyncWrap from "../../../../../src-shared/utils/defaultAsyncWrap";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { safeDelete, safeQuery } from "../../../../../src-shared/apolloMethods";
import withQuery from "../../../../../src-shared/withQuery";
import {
  getDesign,
  getCountOfType
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import FolderExplorer from "./FolderExplorer";
import "./style.css";

const ReportsPanel = ({
  design,
  j5Reports,
  assemblyBatches = [],
  numberOfReactionsInDesign,
  history,
  grandDesign,
  refetchAssemblyBatches
}) => {
  const entities = useMemo(() => {
    const renderVisualReportEntities = () =>
      j5Reports.map(report => ({
        ...report,
        type: "j5-report",
        _feId: "j5-report-" + report.id
      }));

    const renderGrandDesignEntities = () => {
      const getJ5Entity = j5Report => {
        return {
          id: j5Report.id,
          j5Report,
          _feId: "j5-report-" + j5Report.id,
          name: j5Report.name,
          type: "j5-report",
          __typename: j5Report.__typename
        };
      };
      // if its classical/has only 1 reaction don't nest j5report in folders
      if (numberOfReactionsInDesign === 1) {
        const j5ReportArray = [];
        assemblyBatches.forEach(batch =>
          batch.j5Reports.forEach(j5Report => {
            if (
              design.originalDesignId &&
              design.id !== j5Report.originalDesignId
            ) {
              // we only want reports linked to this locked design
              return;
            }
            j5ReportArray.push(getJ5Entity(j5Report));
          })
        );
        return j5ReportArray;
      }
      const runCount = assemblyBatches.length;
      const organizedJ5InFolders = [];
      assemblyBatches.forEach((batch, i) => {
        const folder = {
          id: batch.id,
          name: design.name + " - Run " + (runCount - i),
          _feId: "assembly-batch-" + batch.id,
          children: batch.j5Reports.map(getJ5Entity)

          // {
          //   _feId: 'visual-reports-' + batch.id,
          //   name: 'Visual Reports',
          //   children: batch.visualReportDesigns.map(({ visualReportDesign }) => {
          //     // This will happen if the visual report design was deleted,
          //     if (!visualReportDesign) {
          //       return {
          //         id: shortid(),
          //         name: <i>Deleted</i>
          //       }
          //     }
          //     return {
          //       id: visualReportDesign.id,
          //       _feId: 'visual-report-' + visualReportDesign.id,
          //       name: visualReportDesign.name,
          //       type: 'visual-report'
          //     }
          //   })
          // },
          //   {
          //     _feId: 'j5-reports-' + batch.id,
          //     name: 'J5 Reports',
          //     children: batch.j5Reports.map(j5Report => ({
          //       id: j5Report.id,
          //       j5Report,
          //       _feId: 'j5-report-' + j5Report.id,
          //       name: j5Report.name,
          //       type: 'j5-report'
          //     }))
          //   }
          // ]
        };
        if (
          design.originalDesignId &&
          design.id !== batch.j5Reports[0]?.originalDesignId
        )
          return;
        organizedJ5InFolders.push(folder);
      });
      // filter out j5reports from folders with only 1 child/report in the folder
      return organizedJ5InFolders
        .map(folder => {
          if (folder.children.length === 1) {
            return folder.children[0];
          } else if (folder.children.length === 0) {
            return null;
          } else {
            return folder;
          }
        })
        .filter(item => item !== null);
    };

    if (isVisualReport(design)) return renderVisualReportEntities();
    return renderGrandDesignEntities();
  }, [assemblyBatches, design, j5Reports, numberOfReactionsInDesign]);

  const handleFolderExplorerDoubleClick = async ({ entity }) => {
    const { type, id, j5Report } = entity;
    if (type === "visual-report") {
      history.push(`/designs/${id}`);
    } else if (type === "j5-report") {
      if (j5ReportHasErrors(j5Report)) {
        const j5LogMessages = await safeQuery(
          [
            "j5LogMessage",
            "id message j5LogMessageTypeCode j5LogMessagePriorityCode j5LogMessageJoins { id specificMsg }"
          ],
          {
            variables: {
              filter: {
                j5ReportId: j5Report.id
              }
            }
          }
        );
        showDialog({
          modalType: "J5_LOGS",
          modalProps: {
            j5LogMessages
          }
        });
      } else {
        history.push(`/assembly-reports/${id}`);
      }
    }
  };

  const handleGoToGrandDesignClick = () => {
    history.push(`/designs/${grandDesign.id}`);
  };

  const deleteReportMenuItem = item => {
    return defaultAsyncWrap(async () => {
      const confirm = await showConfirmationDialog({
        text: `Are you sure you want to delete "${item.name}"?
              You cannot undo this action.`,
        intent: Intent.DANGER,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        canEscapeKeyCancel: true
      });

      if (confirm) {
        /*
          select sequenceId from j5RunConstruct where j5ReportId = item.id and isPrebuilt = false;
          select "sequenceId" from "j5Oligo" where "j5Oligo".id in (select "oligoId" from "j5OligoSynthesis" where "j5ReportId" = item.id);
          select "sequenceId" from "j5Oligo" where "j5Oligo".id in (select "topOligoId" from "j5AnnealedOligo" where "j5ReportId" = item.id
                                                                  union select "bottomOligoId" from "j5AnnealedOligo" where "j5ReportId" = item.id);
          select "sequenceId" from "j5DirectSynthesis" where "j5ReportId" = item.id;
          select "pcrProductSequenceId" from "j5PcrReaction" where "j5ReportId" = item.id;
          select "sequenceId" from "j5AssemblyPiece" where "j5ReportId" = item.id;
          for this sequence list, split into two sets:
             isInLibrary is true => set isJ5Sequence = false
             isInLibrary is false => delete

          await safeDelete("sequence", dependentSequences);
          */

        await safeDelete("j5Report", item.id);

        await refetchAssemblyBatches();
        window.toastr.success(`Deleted "${item.name}"`);
      }
    }, "Error deleting assembly report.")();
  };

  const handleFolderExplorerContextMenu = ({
    selectedRecords: [{ entity }]
  }) => {
    const { _feId } = entity;
    let j5ReportIdsToExport;
    if (startsWith(_feId, "j5-reports-")) {
      j5ReportIdsToExport = entity.children.map(({ id }) => id);
    } else if (startsWith(_feId, "assembly-batch-")) {
      j5ReportIdsToExport = entity.children[0].children.map(({ id }) => id);
    } else {
      return [
        <MenuItem
          key="delete"
          text="Delete"
          onClick={() => deleteReportMenuItem(entity)}
        />
      ];
    }

    return [
      <MenuItem
        key="exportJ5Reports"
        text="Export J5 Reports"
        onClick={async () => {
          try {
            const {
              data: { success, err, base64File }
            } = await window.serverApi.request({
              method: "POST",
              url: "/getJ5Json",

              timeout: 1000000000000,
              data: {
                j5ReportIds: j5ReportIdsToExport
              }
            });
            if (!success) throw new Error(err);

            download(
              b64ToBlob(base64File, "application/zip"),
              sanitizeFilename(design.name) + " - J5 Reports.zip",
              "application/zip"
            );
          } catch (e) {
            console.error(e);
            window.toastr.error("Error retrieving j5 report");
          }
        }}
      />
    ];
  };

  return (
    <div className="reports-panel">
      <h5 className="inspector-panel-header">Assembly Reports</h5>
      {isVisualReport(design) && (
        <Button
          minimal
          text="Go to Current Version of the Design"
          onClick={handleGoToGrandDesignClick}
        />
      )}
      <FolderExplorer
        entities={entities}
        onDoubleClick={handleFolderExplorerDoubleClick}
        contextMenu={handleFolderExplorerContextMenu}
        withLinks
      />
    </div>
  );
};

const mapStateToProps = state => ({
  design: getDesign(state),
  numberOfReactionsInDesign: getCountOfType(state, "reaction")
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  withQuery(assemblyBatchLibraryFragment, {
    isPlural: true,
    skip: ({ design }) => !design,
    options: ({ design }) => ({
      variables: {
        sort: ["-updatedAt"],
        filter: {
          designId: design.originalDesignId || design.id
        }
      }
    })
  })
)(ReportsPanel);
